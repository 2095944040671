import styled, { css }  from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const Text = styled.div`
  color: #373737;
  text-align: center;
  margin-bottom: 19px;
  line-height: 27px;
`

const EyebrowH2Styled = styled.h2`
  margin: 0;
  line-height: 0;
  text-align: center;
`

const EyebrowTextStyled = styled.a`
  display: inline-block;
  text-align: left;
  text-transform: uppercase;
  margin-bottom: 7px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1em;
  color: #1D374E;
  text-decoration: none;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  &:focus {
    outline-offset: 2px;
  }

  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    margin-bottom: 15px;
  `)}
  span {
    display: inline-block;
    background-color: #0AC4A9;
    padding: 3px 7px;
    ${query.combine(['extraSmall', 'small'], `
      text-align: left;
      display: inline-block;
      padding: 3px 5px;
    `)}
  }
`

const mainHeadingStyles = css`
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  line-height: 35px;
  letter-spacing: -0.02em;
  color: #1D374E;
  margin-top: 0px;
  margin-bottom: 15px;
  text-align: center;
  ${query.combine(['extraSmall', 'small'], `

    font-size: 30px;
    line-height: 28px;
    margin-bottom: 19px;
  `)}
`

const MainHeadingL2Styled = styled.h2`
  ${mainHeadingStyles}
`

const MainHeadingL3Styled = styled.h3`
  ${mainHeadingStyles}
`

const LearnArticles = styled.div`
  max-width: 664px;
  margin: 0 auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0 2rem;
    ${query.combine(['extraSmall', 'small', 'medium'], `
      padding: 0;
    `)}     
    li {
      border-top: #DBDBDB 1px solid;
      &:last-child {
        border-bottom: #DBDBDB 1px solid;
      }
      .hidden_content {
        display: none;
        font-size: 1rem;
      }
      a {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        text-decoration: none;
      }
      a .articleTitle {
        font-size: 16px;
        line-height: 27px;
        width: 100%;
      }
      a.hidden_content {
        color: #1D374E!important;
        border-bottom: #1D374E 1px solid;
        padding: 0 0 .2rem 0rem!important;
        margin-bottom: 1rem!important;
        text-decoration: none;
        &:hover {
          font-weight: 600;
        }
      }
      a:not(.hidden_content) {
        padding: .6rem 0;
        color: #1D374E;
        &:hover {
          font-weight: 600;
        }
        &:before {
          width: 0;
          height: 0;
          content: '';
          color: #373737;
          display: block;
          margin: 0 .5rem 0 0;
          position: relative;
          top: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 9px solid #23C3A6;
        }
      }
      &.active {
        a:not(.hidden_content) {
          text-decoration: none;
          font-weight: 600;
          &:before {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 9px solid #23C3A6;
            border-top: none;
            top: 0;
          }
        }
        .hidden_content {
          display: block;
          margin-bottom: .6rem;
          color: #373737;
          padding-left: 20px;
        }
        a.hidden_content {
          display: inline-block!important;
          margin-left: 20px;
        }
      }
    }
  }
`

const Guides = styled.div`
  margin-top: 3rem;
  margin-bottom: 2rem;
  h3 {
    color: #28334A;
    font-size: 1.6em;
    margin: 0 0 1rem 0;
    text-align: center;
  }
  a {
    text-decoration: none;
    color: #28334A;
    display: block;
    box-shadow: 0 2px 14px 0 #CED5DF;
    padding: 1.5rem;
    font-weight: bold;
    ${query.combine(['extraSmall', 'small', 'medium'], `
      margin-bottom: 1rem;
      height: auto;
    `)}
    height: 100%;
    img {
      float: left;
      margin-right: .7rem;
    } 
    &:hover{
      .title{
        text-decoration: underline;
      }
    }
  }
`

export {
  Container,
  Text,
  LearnArticles,
  Guides,
  EyebrowH2Styled,
  EyebrowTextStyled,
  MainHeadingL2Styled,
  MainHeadingL3Styled,
}
