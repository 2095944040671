import styled from 'styled-components'
import { container, query } from '../../theme'

const MobileMenuWrap = styled.div`
  height:100vh;
`

const MobileMenuContainer = styled.div`
  ${container}
  padding-top: 6rem;
  &.bottom {
    padding-top: 2rem;
  }
`

const Search = styled.div`
  form {
    position: relative;
    input {
      width: 100%;
      display: block;
      font-size: 16px;
      color: #373737;
      border:0;
      padding: 1.2rem;
      box-shadow: 0 2px 10px 0 #ADB7C5;
      border-radius: 5px;
      &:focus {
        box-shadow: 0 2px 10px 0 rgba(0,0,0,.4);
      }
      ${query.extraSmall`
          font-size: 14px;
      `}
    }
    button {
      border: 0;
      padding: 0;
      background: transparent;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      top: 25%;
      right: 3%;
      z-index: 10;
      cursor: pointer;
    }
  }
`

const Menu = styled.nav`
  margin: 2rem 0 1rem 0;
  ${query.extraSmall`
    margin-bottom: 2rem;
  `}
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      float: left;
      width: 48%;
      margin-bottom: 1rem;
      margin-right: 2%;
      &:nth-child(2n) {
        margin-right: 0;
      }
      ${query.extraSmall`
        float: none;
        width: auto;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0;
        }
      `}
      > a {
        color: #28334A;
        font-size: 24px;
        text-decoration: none;
      }
      &.contact {
        a {
          &:last-child {
            display: block;
            font-size: 16px;
            margin-top: .45rem;
          }
        }
      }
    }
  }
`

const Bottom = styled.div`
  border-top: #DDF0EE 1px solid;
  padding-bottom: 2rem;
  .utils {
    float: left;
    width: 60%;
    margin-top: 13px;
    ${query.extraSmall`
        float: none;
        width: auto;
        margin-top: 0;
    `}
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        display: inline-block;
        font-size: 16px;
        margin-right: 1.5rem;
        ${query.extraSmall`
          display:block;
          margin-bottom: 1.2rem;
        `}
        &:last-child {
          margin-right: 0;
        }
        svg {
          vertical-align: middle;
          margin-top:-4px;
          margin-right: .1rem;
        }
        > a {
          text-decoration: none;
          color: #373737;
          &:hover {
            color: #000;
          }
        }
      }
    }
  }
  button, a[kind='purple_solid'] {
    float: right;
    max-width: none;
    width: 40%;
    cursor: pointer;
    ${query.extraSmall`
        float: none;
        width: 100%!important;
        display: block;
    `}
  }
`

export {
  MobileMenuWrap,
  MobileMenuContainer,
  Search,
  Menu,
  Bottom,
}
