/* eslint-disable import/no-anonymous-default-export */
export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  image: {
    mappedProp: 'image'
  },
  image_alt_text: {
    mappedProp: 'imageAltText'
  },
  main_heading: {
    mappedProp: 'mainHeading',
  },
  main_heading_url: {
    mappedProp: 'mainHeadingUrl',
  },
  subheading: {
    mappedProp: 'subheading',
  },
  body_text: {
    mappedProp: 'bodyText',
  },
  list: {
    mappedProp: 'list',
  },
  button_text: {
    mappedProp: 'buttonText',
  },
  button_url: {
    mappedProp: 'buttonUrl',
  },
  button_style: {
    mappedProp: 'buttonKind',
  },
  button_text_for_screen_readers: {
    mappedProp: 'buttonAccessibleText',
  }
}
