import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const Content = styled.div`
  max-width: 55%;
  float: right;
  padding-right: 3rem;
  padding-top: 3rem;

  ${query.combine(['large'], `
    padding-top: 2rem;
    padding-right: 2rem;
    max-width: 52%;
  `)}   

  ${query.combine(['medium'], `
    padding-top: 2rem;
    padding-right: 2rem;
    max-width: 48%;
  `)} 

  ${query.combine(['extraSmall', 'small'], `
      padding: 2rem;
      max-width: none;
      float:none;
  `)} 

  h2 {
    color: #28334A;
    font-size: 2.1em;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: 1rem;
    line-height: 1.3;

    ${query.combine(['medium', 'large'], `
      font-size: 1.8em;
    `)} 
  }

  p {
    color: #5C596D;
    margin-top: 0;
    margin-bottom: 0;

    ${query.combine(['medium', 'large'], `
      font-size: 1em;
    `)} 
  }
`

const Banner = styled.div`
  background: url(${props => props.bg}) no-repeat;
  background-size: contain;
  height: 260px;
  box-shadow: 0 2px 10px 0 #DBE1EB;

  ${query.combine(['extraSmall', 'small'], `
    background: transparent;
    height: auto;
  `)}

  img {
    display: none;
    ${query.combine(['extraSmall', 'small'], `
    display: block;
  `)}
  }
`

const CTA = styled.div`
  padding: 0 2rem;
  margin-top: 3rem;
  ${query.combine(['extraSmall', 'small', 'medium'], `
    text-align: center;
  `)}  
  h2 {
    color: #28334A;
    font-weight: normal;
    font-size: 1.8em;
    margin: 0;
    line-height: 1.4;
  }
  button {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      display: inline-block;
      width: auto;
      margin-top: 1rem;
    `)}    
    display: block;
    width: 100%;
  }
`

export {
  Container,
  Content,
  Banner,
  CTA,
}
