import React from 'react'
import PropTypes from 'prop-types'

import {
  cleanHtml,
} from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import Image from '../Image'

import {
  Container,
  ImageTextContainer,
  Content,
  Heading,
} from './style'

const ImageText = ({
  showimage,
  position,
  image,
  mobileimage,
  heading,
  text,
  anchorid,
}) => {
  const imageSizes = []
  if (image) imageSizes.push(`${image} 1000w`)
  if (mobileimage) imageSizes.push(`${mobileimage} 768w`)
  return (
    <div id={anchorid} className="image_text content_block">
      <Container>
        <ImageTextContainer>
          <Row>
            {(showimage && position === 'left') && (
              <Col lg="4" md="12" sm="12" xs="12" className="image_col left">
                <Image src={image} altText={heading} srcSet={imageSizes} responsive />
              </Col>
            )}
            <Col lg={showimage ? '8' : '12'} md="12" sm="12" xs="12">
              <Heading>{ heading }</Heading>
              <Content
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
              />
            </Col>
            {(showimage && position === 'right') && (
              <Col lg="4" md="12" sm="12" xs="12" className="image_col right">
                <Image src={image} altText={heading} srcSet={imageSizes} responsive />
              </Col>
            )}
          </Row>
        </ImageTextContainer>
      </Container>
    </div>
  )
}

ImageText.propTypes = {
  anchorid: PropTypes.string,
  showimage: PropTypes.bool,
  position: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  mobileimage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default ImageText
