export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  top_heading: {
    mappedProp: 'topheading',
  },
  top_heading_url: {
    mappedProp: 'topheadingurl',
  },
  heading: {
    mappedProp: 'heading',
  },
  top_text: {
    mappedProp: 'toptext',
  },
  icon_content: {
    mappedProp: 'iconcontent',
  },
  button_text: {
    mappedProp: 'buttontext',
  },
  button_style: {
    mappedProp: 'buttonstyle',
  },
  button_url: {
    mappedProp: 'buttonurl',
  },
  show_button: {
    mappedProp: 'showbutton',
  },
  anchorid: {
    mappedProp: 'anchorid',
  },
}
