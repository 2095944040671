import styled, { css } from 'styled-components'

const headstyle = css`
  color: #28334A;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 9px;
`
const ReviewListStyle = styled.div`
  width: 100%;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 10px 0 #DBE1EB;
  padding: 18px 10px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;

  h3 {
    ${headstyle}
    a { 
      ${headstyle}
      text-decoration: none;
      &:focus, &:hover {
        text-decoration: underline;
      }
    }
  }

  p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0px;
  }

  .author {
    font-size: 14px;
    text-transform: capitalize;
    margin-top: 7px;
    margin-bottom: 0px;
    color: #28334A;

    + .author {
      margin-top: 0px;
    }
    
  }

  .reviewDate {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 14px;
    color: #28334A;
    line-height: 24px;
  }

`

export default {
  ReviewListStyle,
}

export {
  ReviewListStyle,
}
