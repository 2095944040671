export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  background: {
    mappedProp: 'background',
  },
  image: {
    mappedProp: 'image',
  },
  image_alt_text: {
    mappedProp: 'imageAltText',
  },
  image_pos: {
    mappedProp: 'imagepos',
  },
  add_margin_bottom: {
    mappedProp: 'addMarginBottom',
  },
  show_top_text: {
    mappedProp: 'showtoptext',
  },
  top_text: {
    mappedProp: 'toptext',
  },
  top_text_link: {
    mappedProp: 'toptextlink',
  },
  main_heading: {
    mappedProp: 'mainheading',
  },
  text: {
    mappedProp: 'text',
  },
  icon_content: {
    mappedProp: 'iconcontent',
  },
  icon_position: {
    mappedProp: 'iconPosition',
  },
  posts: {
    mappedProp: 'posts',
  },
  buttons: {
    mappedProp: 'buttons',
  },
  anchorid: {
    mappedProp: 'anchorid',
  },
}
