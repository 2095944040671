import styled from 'styled-components'
import { query, container } from '../../theme'

const Container = styled.div`
  ${container}
`

const Featured = styled.div`
  margin: 1.5rem 0;
`

const Article = styled.div`
`

const Image = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  margin-bottom: .5rem;
  border-radius: 5px 5px 0 0;
`

const Title = styled.div`
  .tax {
    font-size: .9em;
    color: #00C2A2;
  }
  &.smaller {
    h2 {
      font-size: 1.2em;   
      margin-bottom: .5rem;
      ${query.combine(['extraSmall', 'small'], `
        font-size: 1.1em;
      `)}        
    }
  }
  h2 {
    margin: .5rem 0 1rem 0;
    line-height: 1.2;
    font-size: 1.9em;
    ${query.combine(['large'], `
      font-size: 1.6em;
      line-height: 1.3;
    `)}      
    ${query.combine(['extraSmall', 'small', 'medium'], `
      font-size: 1.4em;
      line-height: 1.3;
    `)}         
    a {
      color: #28334A;
      text-decoration: none;
      &:hover {
        color: #000;
      }
    }
  }
`

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-size: .95em;
  color: #373737;
  line-height: 1.6;
  &.smaller {
    font-size: .85em;
    ${query.combine(['extraSmall', 'small'], `
      display: none;
    `)}      
  }
`

const Smalls = styled.div`
  ${query.combine(['extraSmall', 'small', 'medium'], `
    margin-top: 1.5rem;
  `)}
  .small {
    margin-top: 1rem;
    ${query.combine(['extraSmall', 'small', 'medium'], `
      margin-top: 1.5rem;
  `)}    
    &:first-child {
      margin-top: 0;
    }
  }
`

export {
  Container,
  Featured,
  Article,
  Image,
  Title,
  Text,
  Smalls,
}
