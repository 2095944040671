import React from "react"
import PropTypes from "prop-types"
import { removeHtmlTagFromString } from "../../utils"

import { Col, Row } from "../Grid"

import { Button } from "../Button"

import {
  Container,
  LR,
  ParagraphStyled,
  ListStyled,
  ListContainerStyled,
  ListContainerTitleStyled,
  CallUsTextStyled,
  DesktopButtonsStyled,
  MobileButtonsStyled,
} from "./style"

const CTA = ({
  heading,
  paragraph,
  list,
  quoteButton,
  contactUsNumber,
  listTitle,
  mobileCallUsButtonText,
  background,
  iconPosition,
  addMarginBottom
}) => {

  const contentClassName = addMarginBottom ? 'lr_content content_block' : 'lr_content'
  const contactUsNumberWithoutSpaces = contactUsNumber.split(" ").join("")
  return (
    <div
      className={contentClassName}
      style={{ backgroundColor: background, padding: "3.5rem 0" }}
    >
      <Container>
        <LR>
          <Row>
            <Col xl="7" lg="6" sm="12" xs="12">
              <h2>{heading}</h2>
              <ParagraphStyled text={removeHtmlTagFromString(paragraph, "p")} />
              <DesktopButtonsStyled>
                <div>
                  <Button
                    type="button"
                    kind="purple_solid_rebrand"
                    size="large"
                    as="a"
                    href={quoteButton[0].url}
                  >
                    {quoteButton[0].text}
                  </Button>
                </div>

                <CallUsTextStyled>
                  or call us
                  <a href={`tel:${contactUsNumberWithoutSpaces}`}>{contactUsNumber}</a>
                </CallUsTextStyled>
              </DesktopButtonsStyled>
            </Col>
            <Col xl="5" lg="6" sm="12" xs="12" className="image_col">
              {list && (
                <ListContainerStyled>
                  <ListContainerTitleStyled>
                    {listTitle}
                  </ListContainerTitleStyled>
                  <ListStyled iconPosition={iconPosition} >
                    {Object.keys(list).map((i) => (
                      <li key={i}>
                        <img src={list[i].icon.url} alt='' />
                        <span>{removeHtmlTagFromString(list[i].text, "p")}</span>
                      </li>
                    ))}
                  </ListStyled>
                </ListContainerStyled>
              )}
            </Col>

            <MobileButtonsStyled>
              <div >
                <Button
                  type="button"
                  kind="purple_solid_rebrand"
                  size="large"
                  as="a"
                  href={quoteButton[0].url}

                >
                  {quoteButton[0].text}
                </Button>
              </div>
              <div>
                <Button
                  type="button"
                  kind="navy_ghost_rebrand"
                  size="large"
                  as="a"
                  href={`tel:${contactUsNumberWithoutSpaces}`}
                >
                  {mobileCallUsButtonText}
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.3204 1.24805C17.2696 1.12512 17.1943 1.0099 17.0948 0.909744C17.0933 0.908255 17.0918 0.90677 17.0903 0.90529C16.9005 0.716585 16.6389 0.599976 16.3501 0.599976H12.1501C11.5702 0.599976 11.1001 1.07008 11.1001 1.64998C11.1001 2.22987 11.5702 2.69998 12.1501 2.69998H13.8152L10.3576 6.15751C9.94759 6.56756 9.94759 7.23239 10.3576 7.64244C10.7677 8.05249 11.4325 8.05249 11.8426 7.64244L15.3001 4.1849V5.84998C15.3001 6.42987 15.7702 6.89998 16.3501 6.89998C16.93 6.89998 17.4001 6.42987 17.4001 5.84998V1.64998C17.4001 1.50761 17.3718 1.37185 17.3204 1.24805Z"
                      fill="#00C2A2"
                    />
                    <path
                      d="M0.600098 1.64998C0.600098 1.07008 1.0702 0.599976 1.6501 0.599976H3.91061C4.4239 0.599976 4.86195 0.971059 4.94633 1.47736L5.72261 6.13502C5.79839 6.58971 5.56876 7.04065 5.15647 7.24679L3.53092 8.05956C4.70303 10.9722 7.02786 13.297 9.94051 14.4692L10.7533 12.8436C10.9594 12.4313 11.4104 12.2017 11.865 12.2775L16.5227 13.0537C17.029 13.1381 17.4001 13.5762 17.4001 14.0895V16.35C17.4001 16.9299 16.93 17.4 16.3501 17.4H14.2501C6.71141 17.4 0.600098 11.2887 0.600098 3.74998V1.64998Z"
                      fill="#00C2A2"
                    />
                  </svg>
                </Button>
              </div>
              <CallUsTextStyled>
                or call us
                <br />
                <a href={`tel:${contactUsNumberWithoutSpaces}`}>{contactUsNumber}</a>
              </CallUsTextStyled>
            </MobileButtonsStyled>
          </Row>
        </LR>
      </Container>
    </div>
  )
}

CTA.propTypes = {
  heading: PropTypes.string,
  addMarginBottom: PropTypes.bool,
  paragraph: PropTypes.string,
  toptext: PropTypes.string,
  contactUsNumber: PropTypes.string,
  listTitle: PropTypes.string,
  quoteButton: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  list: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),

  mobileCallUsButtonText: PropTypes.string,
  background: PropTypes.string,
  iconPosition: PropTypes.object,
}

export default CTA
