import React from "react"
import PropTypes from "prop-types"
import { Col, Row } from "../Grid"
import { DataLayerLink } from "../DataLayerLink"
import { isUndefinedOrNull, cleanHtml, dataLayerPush } from "../../utils"
import {
  Container,
  Text,
  LearnArticles,
  Guides,
  EyebrowH2Styled,
  EyebrowTextStyled,
  MainHeadingL2Styled,
  MainHeadingL3Styled,
} from "./style"

const HelpBlock = ({
  heading,
  text,
  learnarticles,
  popularguides,
  showguides,
  anchorid,
  showtoptext,
  toptext,
  toptextlink,
  addMarginBottom
}) => {
  const toggleAccordion = (e) => {
    e.preventDefault()
    const {
      currentTarget: { parentNode, innerText },
    } = e
    parentNode.classList.toggle("active")
    dataLayerPush({
      event: "questionClick",
      questionName: `${innerText}`.trim(),
    })
  }

  const contentClassName = addMarginBottom ? 'content_block' : ''

  return (
    <div id={anchorid} className={`help-block ${contentClassName}`} >
      <Container>
        <Row>
          <Col lg={{ span: 10, offset: 1 }} md="12" sm="12" xs="12">
            {showtoptext ? (
              <>
                <EyebrowH2Styled>
                  <EyebrowTextStyled href={toptextlink}>
                    <span>{toptext}</span>
                  </EyebrowTextStyled>
                </EyebrowH2Styled>
                <MainHeadingL3Styled>{heading}</MainHeadingL3Styled>
              </>
            ) : (
              <MainHeadingL2Styled>{heading}</MainHeadingL2Styled>
            )}
            <Text>
              <div
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
              />
            </Text>
            <LearnArticles>
              <ul>
                {Object.keys(learnarticles).map((i) => {
                  const url = `/${learnarticles[i].post_type}/${learnarticles[i].post_name}`
                  return (
                    <li key={i}>
                      <a
                        href={learnarticles[i].post_name}
                        onClick={(e) => toggleAccordion(e)}
                      >
                        <span className="articleTitle">
                          {learnarticles[i].post_title}
                        </span>
                      </a>
                      <span
                        className="hidden_content"
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                          __html: cleanHtml(learnarticles[i].post_excerpt),
                        }}
                      />
                      <DataLayerLink
                        eventName="textClick"
                        eventKey="textName"
                        className="hidden_content"
                        eventValue="Continue reading"
                        href={url}
                      >
                        Continue reading
                      </DataLayerLink>
                    </li>
                  )
                })}
              </ul>
            </LearnArticles>
            {showguides && (
              <Guides>
                <h3>Popular Guides</h3>
                <Row>
                  {!isUndefinedOrNull(popularguides) &&
                    Object.keys(popularguides).map((i) => {
                      const url = `/${popularguides[i].post_type}/${popularguides[i].post_name}`
                      return (
                        <Col lg="4" md="12" sm="12" xs="12" key={i}>
                          <DataLayerLink
                            eventName="guideClick"
                            eventKey="guideName"
                            eventValue={popularguides[i].post_title}
                            href={url}
                          >
                            <div className="flexContainer">
                              <div className="title">
                                {popularguides[i].post_title}
                              </div>
                            </div>
                          </DataLayerLink>
                        </Col>
                      )
                    })}
                </Row>
              </Guides>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

HelpBlock.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
  learnarticles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  popularguides: PropTypes.object,
  buttonstyle: PropTypes.string,
  buttontext: PropTypes.string,
  buttonurl: PropTypes.string,
  showbutton: PropTypes.bool,
  showguides: PropTypes.bool,
  anchorid: PropTypes.string,
}

export default HelpBlock
