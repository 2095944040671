import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icons'

import { UnorderedList, ListItem } from './style.js'

const HighlightsList = ({ highlighsListItems }) => highlighsListItems ? (
  <UnorderedList>
    {highlighsListItems.map(o => <ListItem key={o.highlight_list_item_text}><Icon kind="TickMark" size="16" color="#28334A" /> {o.highlight_list_item_text}</ListItem>) }
  </UnorderedList>
) : []

HighlightsList.propTypes = {
  highlighsListItems: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.bool,
  ]),
}

export default HighlightsList
