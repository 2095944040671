import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container};
  background-color: #fff;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 45px;
  max-width: 1108px!important;
  
  ${query.combine(['large'], ` 
    padding-left: 44px;
    padding-right: 44px;
    max-width: 928px!important;
  `)} 
  ${query.combine(['medium'], ` 
    padding-left: 44px;
    padding-right: 44px;
    padding-bottom: 0;
    max-width: 690px!important;
  `)} 

  ${query.combine(['small'], ` 
      padding-left: 22px;
      padding-right: 22px;
      padding-bottom: 0;
      max-width: 512px!important;
    `)}
  ${query.combine(['extraSmall',], ` 
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 0;
    max-width: 100%!important;
  `)} 
`

const Icon = styled.div`
  text-align: center;
  ${query.combine(['extraSmall', 'small'], `
    margin-bottom: 1rem;
  `)}     
  img {
    margin: 0 auto;
    display: block;
  }
  strong {
    color: #28334A;
    font-size: 1.2em;
    display: block;
    margin: .5rem 0 .2rem 0;
  }
  p {
    margin: 0;
    color: #373737;
    font-size: .95em;
    line-height: 1.6;
    a {
        text-decoration: none;
        font-weight: 700;
        color: #373737;
    }
  }
`

const TopHeading = styled.h2`
  color: #1D374E;
  margin: 0 0 10px 0;
  padding-top: 28px;
  text-align: center; 
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  
  span {
    display: block;
    text-transform: uppercase;
    color: #9361B0;
    font-size: .6em;
    a {
      margin-bottom: 16px;
      background-color: #00C2A2;
      padding: 5px 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #1D374E;
      text-decoration: none;
    }
  }

  ${query.combine(['extraSmall', 'small'], ` 
   font-size: 30px;
   line-height: 38px;
   letter-spacing: -0.02em;
  `)} 
`

const TopText = styled.p`
  color: #373737;
  text-align: center;
  margin-bottom: 3rem;
`

const ButtonStyled = styled.div`
  text-align: center;
  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
  `)}

  button, a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 0px;
    display: inline-flex;
    align-content: center;
    width: auto;
    height: auto;
    padding: 10px 18px;
    margin-top: 3rem;
    margin-bottom: 0;
    margin-right: 1rem;

    &:hover {
      svg {
        position: relative;
        transform: translateX(8px);
        transition: transform 0.5s ease 0s;
      }
    }

    svg {
      position: relative;
      transform: translateX(0);
      transition: transform 0.5s ease 0s;
    }

    ${query.combine(['extraSmall', 'small'], `
      padding-left: 30px;
      padding-right: 30px;
      min-width: auto;
      text-align: center;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 16px;
      margin-right: 0;
      display: inline-block;
      margin-left: 0;

      &:last-child {
        margin-bottom: 0;
      }
    `)}

    &:last-child {
      margin-right: 0;
    }
    svg {
      margin-left: 10px;
      width: 10px;
      height: 14px;
      top: 3px;
      position: relative;
    }
  }
`

const WrapperStyled = styled.div`
  background-color: ${props => props.background ? props.background : '#fff'};
  padding-top: 40px;
  padding-bottom: 40px;
  
  ${query.combine(['extraSmall'], ` 
    padding-top: 0;
    padding-bottom: 0;
  `)} 
`

const TopContentStyled = styled.div`
  max-width: 850px;
  margin: 0 auto;
`

export {
  Container,
  Icon,
  TopHeading,
  TopText,
  ButtonStyled,
  WrapperStyled,
  TopContentStyled,
}
