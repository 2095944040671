import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  cleanHtml,
} from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import {
  Button,
} from '../Button'

import {
  Container,
  Heading,
  TopText,
  Grid,
  ItemHeader,
  ItemFooter,
} from './style'

const IconContentGrid = ({
  topheading,
  topheadingurl,
  heading,
  toptext,
  iconcontent,
  buttontext,
  buttonstyle,
  buttonurl,
  showbutton,
  anchorid,
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
  })
  return (
    <div id={anchorid} className="icon_grid content_block">
      <Container>
        <Heading><span><a href={topheadingurl}>{topheading}</a></span> {heading}</Heading>
        <TopText>{toptext}</TopText>
        <Grid>
          <Row>
            {Object.keys(iconcontent).map(i => (
              <Col lg="6" md="12" sm="12" xs="12" key={i}>
                <ItemHeader>
                  <div>
                    {loading ? <img src={iconcontent[i].icon.sizes.large} alt="" /> : null}
                  </div>
                  <div>
                    <h3>
                      {iconcontent[i].heading}
                    </h3>
                  </div>
                </ItemHeader>
                <ItemFooter
                    // eslint-disable-next-line
                    dangerouslySetInnerHTML={{ __html: cleanHtml(iconcontent[i].content) }}
                />
              </Col>
            ))}
          </Row>
          {showbutton && (
            <div style={{ textAlign: 'center' }}>
              <Button kind={buttonstyle} as="a" href={buttonurl}>
                {buttontext}
              </Button>
            </div>
          )}
        </Grid>
      </Container>
    </div>
  )
}

IconContentGrid.propTypes = {
  topheading: PropTypes.string,
  topheadingurl: PropTypes.string,
  heading: PropTypes.string,
  toptext: PropTypes.string,
  iconcontent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  buttontext: PropTypes.string,
  buttonstyle: PropTypes.string,
  buttonurl: PropTypes.string,
  showbutton: PropTypes.bool,
  anchorid: PropTypes.string,
}

export default IconContentGrid
