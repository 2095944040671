import React from 'react'
import PropTypes from 'prop-types'

import {
  cleanHtml,
} from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import Image from '../Image'

import { Button } from '../Button'

import {
  Container,
  Content,
  Banner,
  CTA,
} from './style'

import { urls } from '../../common/const'

const MiniBanner = ({
  image,
  mobileimage,
  text,
}) => (
  <div className="text_accordion content_block">
    <Container>
      <Row>
        <Col lg={{ span: 10, offset: 1 }} md="12" sm="12" xs="12">
          <Banner bg={image}>
            <Image src={mobileimage} responsive altText="Get a quote" />
            <Content
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
            />
          </Banner>
          <CTA>
            <Row>
              <Col lg="8" md="12" sm="12" xs="12">
                <h2>Getting a quote, couldn’t be any easier </h2>
              </Col>
              <Col lg="4" md="12" sm="12" xs="12">
                <Button as="a" href={urls.quickQuote} kind="purple_solid" size="medium">Get a quote</Button>
              </Col>
            </Row>
          </CTA>
        </Col>
      </Row>
    </Container>
  </div>
)

MiniBanner.propTypes = {
  image: PropTypes.string,
  mobileimage: PropTypes.string,
  text: PropTypes.string,
}

export default MiniBanner
