import styled, { css } from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const TopHeading = css`
  color: #28334A;
  margin: 0 0 1rem 0;
  font-size: 2em;
  line-height: 1.2;
  text-align: center;
  span {
    display: block;
    text-transform: uppercase;
    color: #9361B0;
    font-size: .6em;
    margin-bottom: .5rem;
    a {
      color: #9361B0;
      text-decoration: none;
    }
  }
`

const MainHeadingL2Styled = styled.h2`
  ${TopHeading}
`

const MainHeadingL3Styled = styled.h3`
  ${TopHeading}
`

const Rating = styled.div`
  text-align: center;
  margin-bottom:1rem;
  > div {
    margin: 0 auto;
  }
`

const ReviewList = styled.div`
  position: relative;
  height: $nav-height;
  display: flex;
  align-items: center;
  @media (pointer: coarse) {
    overflow: hidden;
  }
  ul {
    position: relative;
    display: flex;
    margin: 0;
    padding: 2rem .5rem;
    align-items: center;
    list-style-type: none;
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    list-style: none;
    flex: 1 1 0px;
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    ${query.combine(['extraSmall', 'small'], `
      margin: 0;
    `)}
    > li {
      display: inline-flex;
      width: 33%;
      border-radius: 5px;
      box-shadow: 0 2px 5px 0 #C9D2DF;
      padding: 1rem;
      align-self: stretch;
      ${query.combine(['extraSmall', 'small', 'medium'], `
        flex-shrink: 0;
      `)}
      ${query.combine(['medium'], `
        width: 50%;
      `)}
      ${query.combine(['extraSmall', 'small'], `
        width: 87%;
      `)}
      &:not(:first-child) {
        margin-left: 2em;
      }
      blockquote {
        margin: 0;
        p {
          margin: 0 0 1rem 0;
          color: #28334A;
          font-size: 1.1em;
          ${query.combine(['extraSmall', 'small', 'medium', 'large'], `
            font-size: 1em;
          `)}
        }
        span {
          color: #28334A;
          font-size: 1em;
          strong {
            display: block;
          }
        }
      }
      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: .5rem 0;
      }
    }
  }
`

const Buttons = styled.div`
  text-align: center; 
  
  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
  `)}

  button, a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 0px;
    display: inline-flex;
    align-content: center;
    width: auto;
    height: auto;
    padding: 9px 18px 8px 18px;
    margin-top: 16px;
    margin-bottom: 0;
    margin-right: 1rem;

    &:hover {
      svg {
        position: relative;
        transform: translateX(8px);
        transition: transform 0.5s ease 0s;
      }
    }

    svg {
      position: relative;
      transform: translateX(0);
      transition: transform 0.5s ease 0s;
    }

    ${query.combine(['extraSmall', 'small'], `
      padding-left: 30px;
      padding-right: 30px;
      min-width: auto;
      text-align: center;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 16px;
      margin-right: 0;
      display: inline-block;
      margin-left: 0;

      &:last-child {
        margin-bottom: 0;
      }
    `)}

    &:last-child {
      margin-right: 0;
    }
    svg {
      margin-left: 10px;
      width: 10px;
      height: 14px;
      top: 3px;
      position: relative;
    }
  }
`


const EyebrowH2Styled = styled.h2`
  text-align: center;
  margin: 0;
  line-height: 0;

  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
  `)}
`

const EyebrowTextStyled = styled.a`
  display: inline-block;
  text-align: left;
  text-transform: uppercase;
  font-size: .5em;
  margin-bottom: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #1D374E;
  text-decoration: none;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  &:focus {
    outline-offset: 2px;
  }

  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    margin-bottom: 15px;
  `)}
  span {
    display: inline-block;
    background-color: #0AC4A9;
    padding: 3px 7px;
    ${query.combine(['extraSmall', 'small'], `
      text-align: left;
      display: inline-block;
      padding: 3px 5px;
    `)}
  }
`

export {
  Container, 
  Rating,
  ReviewList,
  Buttons,
  EyebrowTextStyled,
  EyebrowH2Styled,
  MainHeadingL2Styled,
  MainHeadingL3Styled,
}
