import React from 'react'
import Icon from '../Icons/Icon'
import { BenefitsContainer, BenefitWrapper, BenefitsContentWrapper } from './style'

const CallBenefits = () => (
  <BenefitsContainer>
    <BenefitWrapper>
      <div>
        <Icon kind="ppcCallbackPhone" size="35" />
      </div>
      <BenefitsContentWrapper>
        <h5>Quick and Easy</h5>
        <p>Our UK based customer service team will ask you a few simple questions to understand your cover needs and provide a quote over the phone.</p>
      </BenefitsContentWrapper>
    </BenefitWrapper>
    <BenefitWrapper>
      <div>
        <Icon kind="ppcCallbackBox" size="45" />
      </div>
      <BenefitsContentWrapper>
        <h5>Extra Benefits</h5>
        <p>We will explain the extra benefits you’ll receive with your Beagle Street policy such as terminal illness cover, bereavement counselling and our free will writing service.</p>
      </BenefitsContentWrapper>
    </BenefitWrapper>
    <BenefitWrapper>
      <div>
        <Icon kind="ppcCallbackHands" size="45" />
      </div>
      <BenefitsContentWrapper>
        <h5>Instant Cover</h5>
        <p>If you’re happy with your price and cover we’ll just need to ask you a few more questions to set up your policy and give you instant protection.
        </p>
      </BenefitsContentWrapper>
    </BenefitWrapper>
  </BenefitsContainer>
)

export default CallBenefits
