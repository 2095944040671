import React from 'react'
import PropTypes from 'prop-types'

import {
  Col,
  Row,
} from '../Grid'

import {
  Container,
  Icon,
} from './style'

import { isUndefinedOrNull } from '../../utils'

const IconRow = ({
  content,
}) => (
  <div className="icon_row content_block">
    <Container>
      <Row>
        {!isUndefinedOrNull(content) && (
          Object.keys(content).map(i => (
            <Col lg="4" md="4" sm="12" xs="12" key={i}>
              <Icon>
                <img src={content[i].icon} alt={content[i].heading} width="50" />
                <strong>{content[i].heading}</strong>
                <p>{content[i].text}</p>
              </Icon>
            </Col>
          ))
        )}
      </Row>
    </Container>
  </div>
)

IconRow.propTypes = {
  content: PropTypes.object,
}

export default IconRow
