import styled from 'styled-components'
import { query, container } from '../../theme'

const Container = styled.div`
  ${container}
`

const Tax = styled.span`
  display: block;
  color: #00C2A2;
  font-size: 1em;
  margin-bottom: .5rem;
  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
  `)}     
`

const Intro = styled.p`
  font-size: 1,2em;
  margin-bottom: 1rem;
  color: #373737;
`

const Content = styled.div`
`

const FeaturedImg = styled.img`
  margin-bottom: 2rem;
  max-width: 100%;
  height: auto;
  display: block;
`

const RelatedArticles = styled.aside`
  padding-top: 2rem;
  h2 {
    margin: 0 0 1rem 0;
  }
`

export {
  Container,
  Tax,
  Content,
  Intro,
  FeaturedImg,
  RelatedArticles,
}
