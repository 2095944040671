import styled, { css } from 'styled-components'
import { typeScale, theme } from '../../theme'

const Container = styled.div`
  position: relative;   
  width: 100%;
  background-image: url(${props => props.backgroundImage});
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  text-align: center;
  padding: 80px 0;
  margin-bottom: 50px;

  h1 {
    ${typeScale.heroH1};
    color: ${theme.heroTitle};
    margin-bottom: 40px;
    z-index: 20;
  }

  p {
    ${typeScale.heroPara}
    color: ${theme.textBlue};
  }

  button { 
    z-index: 20;
  }

  .trustPilotStars {
    z-index: 20;
  }

  @media only screen and (min-width: 1300px) {
    button {
      margin-top: -20px;
    }
  }

  @media only screen and (max-width: 768px) {
    margin-top: 10px;
    padding-bottom: 250px;
    padding-top: 20px;

    h1 {
      margin-bottom: 10px;
    }

    button {
      margin: -20px 0 0 0;
    }
  }

  @media only screen and (max-width: 500px) {
    padding-bottom: 150px;
  }
`

const AffiliateWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 20;

  ${props => !props.withAffiliate && css`
    padding-bottom: 50px;
  `}

  div, img {
    width: 8%;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    img {
      width: auto;
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }
`

const SideImagesWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: -5px; // so it always has a little over lap for 'design'.
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 10;

  @media only screen and (max-width: 768px) {
    img {
      width: 100%;
      &:first-child {
        display: none;
      }
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 1400px) {
    img {
      width: 40%;
    }
  }
`

export {
  Container,
  AffiliateWrapper,
  SideImagesWrapper,
}
