import React from 'react'
import PropTypes from 'prop-types'
import { removeHtmlTagFromString } from '../../utils'

import {
  Icon,
  TextStyled,
} from './style'

const GridItem = ({
  itemCount,
  icon,
  alt,
  title,
  body,
}) => {

  if (
    !itemCount ||
    !icon ||
    !title ||
    !body
  ) {
    return null
  }

  return (
    <Icon itemCount={itemCount} className={`glider-slide`}>
      <img src={icon} alt={alt} />
      <strong>{title}</strong>
      <TextStyled text={removeHtmlTagFromString(body, 'p')} />
    </Icon>
  )
}

GridItem.propTypes = {
  itemCount: PropTypes.number.isRequired,
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
}

export default GridItem
