import React from 'react'
import PropTypes from 'prop-types'

import Header from './style'

const Heading = ({
  text,
  subheading,
  pos,
  margin,
}) => (
  <Header pos={pos || 'left'} margin={margin || null}>{text} <span>{subheading}</span></Header>
)

Heading.propTypes = {
  text: PropTypes.string,
  subheading: PropTypes.string,
  pos: PropTypes.string,
  margin: PropTypes.string,
}

export default Heading
