export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  show_image: {
    mappedProp: 'showimage',
  },
  position: {
    mappedProp: 'position',
  },
  image: {
    mappedProp: 'image',
  },
  image_mobile: {
    mappedProp: 'mobileimage',
  },
  heading: {
    mappedProp: 'heading',
  },
  text: {
    mappedProp: 'text',
  },
  anchorid: {
    mappedProp: 'anchorid',
  },
}
