import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import { TrustPilotContext } from '../../containers/contexts'
import { TrustPilotReviewStars } from '../TrustPilotReviewStars'
import { Trustpilot } from '../Trustpilot'
import { isUndefinedOrNull } from '../../utils'

import {
  Container,
  Rating,
  ReviewList,
  Buttons,
  EyebrowTextStyled,
  EyebrowH2Styled,
  MainHeadingL3Styled,
  MainHeadingL2Styled
} from './style'

const TrustPilotBlock = ({
  background,
  addMarginBottom,
  showtoptext,
  topheadingtext,
  topheadingurl,
  mainheading,
  reviews,
}) => {
  const pad = background ? '3.5rem 0' : '0'
  const contentClassName = addMarginBottom ? 'content_block' : ''

  return (
    <div className={`trust_pilot_block ${contentClassName}`} style={{ backgroundColor: background || '#fff', padding: pad }}>
      <Container>
        {showtoptext ? (
          <>
            <EyebrowH2Styled >
              <EyebrowTextStyled href={topheadingurl}>
                <span>{topheadingtext}</span>
              </EyebrowTextStyled>
            </EyebrowH2Styled>
            <MainHeadingL3Styled>{mainheading}</MainHeadingL3Styled>
          </>
          ) : (
          <MainHeadingL2Styled>{mainheading}</MainHeadingL2Styled>
          )
        }
        <Rating>
          <TrustPilotContext.Consumer>
            {(({ trustpilot: { score } }) => {
              if (!isUndefinedOrNull(score)) {
                const { trustScore , stars} = score
                return (<Trustpilot tpStarsRating={stars} trustScore={trustScore} reviewStarsWidth="120px" />)
              }
              return null
            }
            )}
          </TrustPilotContext.Consumer>
        </Rating>
        <ReviewList>
          <ul>
            {Object.keys(reviews).map(i => (
              <li key={i}>
                <blockquote>
                  <p>{reviews[i].text}</p>
                  <span><strong>{reviews[i].author}</strong> Beagle Street Customer</span>
                  <TrustPilotReviewStars width="100px" starsRating={reviews[i].stars} />
                </blockquote>
              </li>
            ))}
          </ul>
        </ReviewList>
      </Container>

      <Buttons>
          <Button kind="navy_ghost_rebrand" as="a" href="/why-beagle-street/customer-reviews">
            More Reviews
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L6 6L1 11" stroke="#00C2A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Button>
      </Buttons>
      
    </div>
  )
}

TrustPilotBlock.propTypes = {
  background: PropTypes.string,
  addMarginBottom: PropTypes.bool,
  topheadingtext: PropTypes.string,
  topheadingurl: PropTypes.string,
  mainheading: PropTypes.string,
  reviews: PropTypes.object,
  buttons: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
}

export default TrustPilotBlock
