import styled from 'styled-components'
import { theme, query } from '../../theme'

const Label = styled.label`
  font-family: 'Montserrat', sans-serif;
  color: ${theme.paragraph};
  font-size: 18px;
  font-weight: 300;
  line-height: 27px;
  display: block;
`
const InputField = styled.input`
  display: block;
  width: 100%;
  font-size: 16px;
  color: ${theme.paragraph};
  margin: 0.625rem auto;
  margin-bottom: 2px;
  padding: 1.2rem;
  -webkit-appearance: none;
  border: 1px solid rgb(173, 183, 197); /* fallback for browsers */
  border: 1px solid rgba(173, 183, 197, 0.2);
  box-sizing: border-box;
  -moz-box-sizing:border-box;
   -webkit-box-sizing:border-box;
  box-shadow: 0 2px 10px 0 rgb(173, 183, 197);
  -webkit-box-shadow: 0 2px 10px 0 rgb(173, 183, 197);
  -moz-box-shadow: 0 2px 10px 0 rgb(173, 183, 197);
  border-radius: 5px;
  &:focus {
    outline: none;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,.4);
    -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,.4);
    -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,.4);
  }
  ${query.extraSmall`
    font-size: 16px;
  `}

  &.errorInput {
    border: #B20A0A 1px solid; 
  }
`

const TextAreaField = styled.textarea`
  font-size: 16px;
  display: block;
  width: 100%;
  color: ${theme.paragraph};
  border: 1px solid rgba(173, 183, 197, 0.2);
  margin: 0.625rem auto;
  margin-bottom: 2px;
  padding: 1.2rem;
  -webkit-box-shadow: 0 2px 10px 0 #ADB7C5;
  -moz-box-shadow: 0 2px 10px 0 #ADB7C5;
  box-shadow: 0 2px 10px 0 #ADB7C5;
  border-radius: 5px;
  &:focus {
    -webkit-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .4);
    -moz-box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .4);
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0,.4);
  }
  ${query.extraSmall`
    font-size: 16px;
  `}

  .errorInput {
    border: #B20A0A 1px solid; 
  }
`

const ErrorMessage = styled.p`
  font-size: 1.125rem;
  color: #B20A0A;
  line-height: 1.6875rem;
  font-family: Montserrat;
  margin: 0;
  padding-top: 7px;
  display: flex;
  align-items: end;

  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
`

const Fieldset = styled.fieldset`

  border: 0;

  legend {
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: bold;
    line-height: 34px;
    color: ${theme.navy};
    margin-bottom: 5px;
  }

`

const RadioItem = styled.div`
  display: block;
  position: relative;
  cursor: pointer;

  ${query.extraSmall`
    width: 100%;
    clear: both;
  `}

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover span {
    border: 2px solid ${theme.navy};

    &:before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 8px;
      width: 8px;
      top: 4px;
      left: 4px;
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;


      background: ${theme.navy};
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    :checked ~ span {
      border: 2px solid ${theme.navy};

      &:before {
        background: ${theme.navy};
      }
    }
  }

  span {
    display: block;
    position: relative;
    float: left;
    border: 2px solid ${theme.navy};
    border-radius: 100%;
    height: 16px;
    width: 16px;
    margin: 5px;

    z-index: 5;
    transition: border .25s linear;
    -webkit-transition: border .25s linear;

    &:before {
      display: block;
      position: absolute;
      content: '';
      border-radius: 100%;
      height: 8px;
      width: 8px;
      top: 4px;
      left: 4px;
      margin: auto;
      transition: background 0.25s linear;
      -webkit-transition: background 0.25s linear;
    }
  }

  label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    display: block;
    float: left;
    line-height: 33px;
    margin-right: 20px;
    ${query.extraSmall`
      margin-bottom: 10px;
    `}
  }

`

const SelectField = styled.select`
  width: 100%;
  font-size: 16px;
  color: ${theme.paragraph};
  border: 1px solid rgb(173, 183, 197);
  border: 1px solid rgba(173, 183, 197, 0.3);
  margin: 0.625rem auto;
  padding: 1.2rem;
  -webkit-box-shadow: 0px 2px 10px 0px rgba(173,183,197,1);
  -moz-box-shadow: 0px 2px 10px 0px rgba(173,183,197,1);
  box-shadow: 0px 2px 10px 0px rgba(173,183,197,1);
  border-radius: 5px;

  /* Adding arrow style for a select input field */
  &::-ms-expand {
    display: none;
  }

  &:focus {
    outline: none;
  }

  &.errorInput {
    border: #B20A0A 1px solid; 
  }

  /* remove the default select arrow for non-IE browsers */
  -webkit-appearance: none;
  -moz-appearance: none; /* works on FF35+ */

  background: url('data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 512 512\"><path d=\"M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z\"/></svg>') no-repeat calc(100% - 10px) 50%;
  background-size: 26px;
`

export {
  Label,
  InputField,
  Fieldset,
  RadioItem,
  SelectField,
  ErrorMessage,
  TextAreaField,
}
