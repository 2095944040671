import styled from 'styled-components'
import { container } from '../../theme'

const Container = styled.div`
  ${container}
`

const Banner = styled.div`
  background: #F3F5F8;
  padding: 3rem 0;
  text-align: center;
  margin-bottom: .5rem;
`

const Text = styled.div`
  h2 {
    margin: 0 0 1.5rem 0;
    color: #28334A;
    font-size: 2.1em;
  }
  p {
    color: #373737;
    margin: 0 0 1.5rem 0;
  }
`

export {
  Container,
  Banner,
  Text,
}
