export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  hero_background: {
    mappedProp: 'heroBackground',
  },
  heading: {
    mappedProp: 'heading',
  },
  text: {
    mappedProp: 'text',
  },
  affiliate_image: {
    mappedProp: 'affiliateImage',
  },
  show_cta: {
    mappedProp: 'showCTA',
  },
  cta_text: {
    mappedProp: 'ctaText',
  },
  cta_url: {
    mappedProp: 'ctaURL',
  },
  show_trustpilot_rating: {
    mappedProp: 'showTrustpilot',
  },
  left_hero_image: {
    mappedProp: 'leftImage',
  },
  right_hero_image: {
    mappedProp: 'rightImage',
  },
}
