import React from 'react'

// import Bread from './style'

// import BWBeagle from '../../assets/img/bwbeagle.svg'

// import { ucAllWords } from '../../utils'

const Breadcrumbs = () =>
// const location = window.location.pathname || ''
// const splitLocation = location.split('/')

// const cleanSplitLocation = splitLocation.filter(x =>
//   (x !== (undefined || null || '')))

  (
  // <Bread>
  //   <ol className="u-clearfix">
  //     <li><a href="http://www.beaglestreet.com"><img alt="Beagle Street" src={BWBeagle} width="24" height="24" /></a></li>
  //     {cleanSplitLocation.map(item => {
  //       let title = item
  //       let extra = null
  //       if (cleanSplitLocation.length > 1) extra = `${cleanSplitLocation[0]}/`
  //       const url = `https://www.${window.location.hostname}/${extra}${item}/`

  //       title = ucAllWords(title)
  //       title = title.replace(/-/g, ' ')

  //       return (
  //         <li key={item} itemScope itemType="http://data-vocabulary.org/Breadcrumb">
  //           <a href={url} itemProp="url">
  //             <span itemProp="title">{title}</span>
  //           </a>
  //         </li>
  //       )
  //     })}
  //   </ol>
  // </Bread>
    <div />
  )

export default Breadcrumbs
