import styled from 'styled-components'

const UnorderedList = styled.ul`
  list-style: none;
  padding-left: 0px;
  display: inline-block;
  margin: 0 auto;

  + a {
    display: block;
  } 
`

const ListItem = styled.li`
  list-style: none;
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  text-align: left;

  svg {
    position: absolute;
    top: 5px;
    left: 0; 
  }
`

export { UnorderedList, ListItem }
