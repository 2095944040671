export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  // main_heading: {
  //   mappedProp: 'mainheading',
  // },
  // hero_image: {
  //   mappedProp: 'heroimage',
  // },
}
