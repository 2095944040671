import styled from 'styled-components'
import { container, query } from '../../theme'

const GuidesContainer = styled.div`
  ${container}
`

const GuidesBG = styled.div`
  padding: 4rem 0;
  background-color: #F3F5F8;
  ${query.combine(['extraSmall', 'small'], `
      padding-bottom: 0rem
      padding-top: 2rem;
  `)}   
`

const Heading = styled.h2`
  color: #28334A;
  margin: 0 0 1.5rem 0;
  text-align: center;
  font-size: 2em;
  ${query.combine(['extraSmall', 'small'], `
      margin-bottom: 1.5rem;
      font-size: 1.7em;
  `)}    
`

const ViewAll = styled.div`
  text-align: center;
  margin-top: 2rem;
  ${query.combine(['extraSmall', 'small'], `
    margin-top: 0;
  `)}    
  a {
    border-bottom: #00C2A2 2px solid;
    text-decoration: none;
    color: #373737;
    text-transform: uppercase;
    padding-bottom: .2rem;
    &:hover {
      text-decoration:none;
    }
  }
`

export {
  GuidesContainer,
  GuidesBG,
  Heading,
  ViewAll,
}
