import React from 'react'
import PropTypes from 'prop-types'

import Search from './style'

import Icon from '../Icons/Icon'

const SearchInput = ({
  action,
  placeholder,
  icon,
  id,
  name,
  method,
  onSubmit, // TODO: hook up search prop PROPERLY
  children,
}) => (
  <Search>
    <form action={action} method={method} onSubmit={() => onSubmit()}>
      <input type="text" name={name} id={id} placeholder={placeholder} required />
      <button type="submit" id={`${id}_submit`}>
        <Icon kind={icon} size="25" color="#28334A" />
      </button>
      {children}
    </form>
  </Search>
)

SearchInput.propTypes = {
  action: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  children: PropTypes.node,
}

export default SearchInput
