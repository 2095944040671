import React from 'react'
import PropTypes from 'prop-types'
import Image from '../Image'
import Icon from '../Icons/Icon'
import {
  CardWrapper,
  BenefitsWrapper,
  BenefitsContainer,
  IconWrapper,
  Wrapper,
} from './style'

const BenefitsTable = ({
  heading,
}) => {
  const benefitsArr = [
    {
      icon: 'FixedPolicy',
      text: <>Your policy premium is fixed and <strong>will not change</strong></>,
    },
    {
      icon: 'ClaimsHouse',
      text: <><strong>99.5%</strong> of claims paid out**</>,
    },
    {
      icon: 'Counselling',
      text: <><strong>Counselling support service</strong> for you and your loved ones</>,
    },
    {
      icon: 'WillWriting',
      text: <><strong>Free Will-writing</strong> service with each policy</>,
    },
    {
      icon: 'PolicyFees',
      text: <>There are <strong>no policy fees</strong> and you can <strong>cancel anytime</strong></>,
    },
    {
      icon: 'Regulated',
      text: <><strong>Authorised and regulated</strong> by the Financial Conduct Authority</>,
    },
    {
      icon: 'CallCentre',
      text: <><strong>UK based</strong> call-centre</>,
    },
  ]

  const renderBenefits = (arr) => (
    arr.map(i => {
      const { icon, text } = i
      return (
        <BenefitsWrapper>
          <IconWrapper>
            <Icon kind={icon} size="30" />
          </IconWrapper>
          <p>{text}</p>
        </BenefitsWrapper>
      )
    })
  )

  return (
    <BenefitsContainer xs="12" md="9" style={{ margin: 'auto auto 20px auto' }}>
      <CardWrapper>
        <p>You could get a <strong>Gift Card</strong> from a range of retailers worth up to <strong>£100*</strong></p>
        <Image src="https://cdn.bgllife.co.uk/beaglestreet/images/gift-card-desktop.png" alt="giftCardImage" />
      </CardWrapper>
      <Wrapper>
        <h3>{heading}</h3>
        {renderBenefits(benefitsArr)}
      </Wrapper>
    </BenefitsContainer>
  )
}

BenefitsTable.propTypes = {
  heading: PropTypes.string,
}

export default BenefitsTable
