import styled from 'styled-components'
import { container, query } from '../../../theme'

const FooterWrap = styled.footer` 
  background: #F3F5F8;
  padding: 1.5rem 0;
  position: relative;
  z-index: 1000;
`
const FooterContainer = styled.div`
  ${container}
`

const Breadcrumbs = styled.div`
  ol {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      font-size: 13px;
      display: block;
      float: left;
      ${query.extraSmall`
          line-height: 1.9;
      `}      
      &:first-child {
        img {
          vertical-align: middle;
          margin-top: -2px;
        }
      }
      &:after {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"/></svg>');
        width: 11px;
        height: 11px;
        content: '';
        color: #373737;
        display:inline-block;
        margin: 0 .75rem;
        position: relative;
        top: 1px;
      }
      a {
        color: #373737;
        text-decoration: none;
        &:hover {
          color: #000;
          text-decoration: underline;
        }
      }
      &:last-child {
        > a {
          font-weight: bold;
        }
        &:after {
          background: transparent;
          margin: 0;
        }
      }
    }
  }
`

const Contact = styled.div`
  margin-bottom: 2rem;
  .contact {
    float: left;
    svg {
      float: left;
      vertical-align: top;    
      ${query.extraSmall`
          float: none;
          margin-bottom: 1rem;
      `}
    }
    .wrap {
      .details {
        margin-left: 2.5rem;
        font-size: 20px;
        color: #28334A;
        a {
          color: #28334A;
          text-decoration: none;
        }
        small {
          font-size: 14px;
          color: #373737;
          display: block;
        }    
        ${query.extraSmall`
          margin-left: 0;
        `}     
      }
      ${query.extraSmall`
          text-align: center;
          margin: 0 auto;
      `}         
    }
    ${query.extraSmall`
      float: none;
    `}     
  }
  .social {
    float: right;
    a {
      display: inline-block;
      margin-right: 1.5rem;
      &:last-child {
        margin-right:0;
      }
    }
    ${query.extraSmall`
      float: none;
      margin-top: 1rem;
      text-align: center;
    `}     
  }
  ${query.extraSmall`
    margin-bottom: 1.5rem;
  `} 
`

const Menus = styled.div`
  border-bottom: #DBDBDB 1px solid;
  border-top: #DBDBDB 1px solid;
  margin: 1.3rem 0;
  padding: 1.3rem 0;
  ${query.combine(['extraSmall', 'small'], `
      padding-bottom: 0;
  `)}   
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      font-size: 14px;
      margin-bottom: .3rem;
      &:last-child {
        margin-bottom: 0;
      }
      &:first-child {
        font-size: 15px;
        > a {
          color: #28334A;
          font-weight: bold;
        }
      }
      > a {
        color: #373737;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
          color: #000;
        }
      }
    }
    ${query.combine(['extraSmall', 'small'], `
      &:nth-child(0), &:nth-child(1) {
        margin-bottom: 1.3rem;
      }
    `)}    
  }
`

const Disclaimer = styled.div`
  p {
    font-size: 12px;
    line-height: 1.6;
    color: #373737;
  }
`

const LegalLinks = styled.nav`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      font-size: 11px;
      display: inline;
      a {
        color: #373737;
        text-decoration: none;
        &:hover {
          color: #000;
          text-decoration: underline;
        }
      }
      &:after {
        content: '|';
        opacity: .5;
        margin: 0 .5rem;
      }
      &:last-child {
        &:after {
          content: '';
          margin: 0;
        }
      }
    }
  }
`

const PPCTerms = styled.div`
  ${container}
  width: 100%;
  max-width: 800px!important;
  p {
    font-size: 14px;
    margin-top: 0;
    color: #757575;
    text-align: center;
    margin-bottom: 15px;
    line-height: 17px;
    a {
      color: rgb(147, 97, 176);
      text-decoration: underline;
    }
  }
`

export {
  FooterWrap,
  FooterContainer,
  Breadcrumbs,
  Contact,
  Menus,
  Disclaimer,
  LegalLinks,
  PPCTerms,
}
