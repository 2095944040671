import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const iconListHeadingSize = (itemCount) => {
  if (itemCount === 1) {
    return "26px"
  }
  if (itemCount === 2) {
    return "18px"
  }
  if (itemCount === 3) {
    return "18px"
  }
  if (itemCount === 4) {
    return "16px"
  }

  if (itemCount === 5) {
    return "18px"
  }

  if (itemCount === 6) {
    return "18px"
  }

  return "1.2em"
}


const iconListParagraphSize = (itemCount) => {
  if (itemCount === 1) {
    return "16px"
  }

  if (itemCount === 2) {
    return "16px"
  }

  if (itemCount === 3) {
    return "16px"
  }

  if (itemCount === 4) {
    return "16px"
  }

  if (itemCount === 5) {
    return "18px"
  }

  if (itemCount === 6) {
    return "18px"
  }

  return "0.95em"
}

const Icon = styled.div`
  text-align: center;
  ${query.combine(['extraSmall', 'small'], `
    margin-bottom: 1rem;
  `)}
  img {
    margin: 0 auto;
    display: block;
  }
  strong {
    color: #28334A;
    font-size: ${props => iconListHeadingSize(props.itemCount)};
    display: block;
    margin: .5rem 0 .2rem 0;
  }
  p {
    margin: 0;
    color: #373737;
    font-size: ${props => iconListParagraphSize(props.itemCount)};
    line-height: 1.6;

  }
`

const WrapperStyled = styled.div`
  margin-top: 4rem;
  @media screen and (max-width: 767px) {
    margin-top: 3rem;
  }
`

export {
  Container,
  Icon,
  WrapperStyled,
}
