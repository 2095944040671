import React from 'react'
import PropTypes from 'prop-types'
import { cleanHtml } from '../../utils'
import Paragraph from './style'

const Text = ({
  text,
  className,
}) => (
  <Paragraph
    className={className}  
    // eslint-disable-next-line
    dangerouslySetInnerHTML={{ __html: cleanHtml(text) || 'No HTML' }}
  />
)

Text.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
}

export default Text
