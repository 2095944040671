export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  accessibility: {
    mappedProp: 'accessibility',
  },
  reviews: {
    mappedProp: 'reviews',
  },
  gifts: {
    mappedProp: 'gifts',
  },
  add_margin_bottom: {
    mappedProp: 'addMarginBottom'
  },
  background: {
    mappedProp: 'background'
  },
  left_image_width_mobile: {
    mappedProp: 'leftImageWidthMobile'
  },
  right_image_width_mobile: {
    mappedProp: 'rightImageWidthMobile',
  },
}
