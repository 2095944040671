import React from 'react'
import PropTypes from 'prop-types'

import PageWrapper from './style'
import * as ACF from '..'
import { ParentComponentContext } from '../../containers/contexts'
import {
  isUndefinedOrNull,
  isEmptyString,
} from '../../utils'

const ACFDynamicComponent = (item) => {
  const { item: { acf_fc_layout: ACFCompName } } = item
  const ComponentName = ACF[ACFCompName]
  const config = ACF[`${ACFCompName}Config`]
  const obj = {}

  if (!isUndefinedOrNull(item) && !isUndefinedOrNull(config)) {
    /**
     * Map the ACF response (json data) to correct component properties
     * You will need to edit the config.acf.js to map these correctly
     */
    Object.keys(config).map((i) => {
      const key = config[i].mappedProp

      if (Object.prototype.hasOwnProperty.call(config, i) &&
        !isUndefinedOrNull(key) &&
        !isEmptyString(key)
      ) {
        obj[key] = item.item[i]
        return true
      }
      return null
    })
    return (
      <ParentComponentContext.Provider value={{ parent: `${ACFCompName}` }}>
        <ComponentName
          {...obj}
        />
      </ParentComponentContext.Provider>

    )
  }
  return null
}

const ACFDynamic = ({
  data,
}) => (
  <PageWrapper>
    {!isUndefinedOrNull(data) && (
      <ACFDynamicComponent
        item={data}
      />
    )}
  </PageWrapper>
)

ACFDynamic.propTypes = {
  data: PropTypes.object,
}

export default ACFDynamic
