import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import Glider from "react-glider"
import { isDesktop } from 'react-device-detect'
import "glider-js/glider.min.css"

const sliderResponsiveSettings = [
  {
    breakpoint: 1200,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 991,
    settings: {
      slidesToShow: 3,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 767,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 0,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
    },
  }
]

const Carousel = ({ children }) => {
  const gliderRef = useRef(null)

  useEffect(() => {
    gliderRef.current.arrows.next.ariaHidden = true
    gliderRef.current.arrows.prev.ariaHidden = true
  }, [])

  return (
    <Glider
      draggable
      hasArrows
      iconRight="❯"
      iconLeft="❮"
      scrollLock={isDesktop ? false : true}
      responsive={sliderResponsiveSettings}
      dragVelocity={1.5}
      ref={gliderRef}
    >
      {children}
    </Glider>
  )
}

Carousel.propTypes = {
  items: PropTypes.object,
}

export default Carousel
