export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  heading: {
    mappedProp: 'heading',
  },
  text: {
    mappedProp: 'text',
  },
  learn_articles: {
    mappedProp: 'learnarticles',
  },
  popular_guides: {
    mappedProp: 'popularguides',
  },
  button_style: {
    mappedProp: 'buttonstyle',
  },
  button_text: {
    mappedProp: 'buttontext',
  },
  buttonurl: {
    mappedProp: 'buttonurl',
  },
  showbutton: {
    mappedProp: 'showbutton',
  },
  show_guides: {
    mappedProp: 'showguides',
  },
  anchorid: {
    mappedProp: 'anchorid',
  },
  add_margin_bottom:{
    mappedProp: 'addMarginBottom',
  },
  show_top_text: {
    mappedProp: 'showtoptext',
  },
  top_text: {
    mappedProp: 'toptext',
  },
  top_text_link: {
    mappedProp: 'toptextlink',
  },
}
