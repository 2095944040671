import React from "react"
import PropTypes from "prop-types"
import { removeHtmlTagFromString } from "../../utils"
import Button from "../Button"
import {
  HeroImgStyled,
  HeroOverlayStyled,
  HeroSectionStyled,
  HeroHeading,
  HeroList,
  HeroOverlayContentStyled,
  HeroParagraphStyled,
} from "./style"

const HeroV2 = ({
  images,
  showAffiliateImage,
  affiliateImage,
  affiliateImageAltText,
  headingFirstLine,
  headingSecondLine,
  cssStyles,
  showListItems,
  listItems,
  body,
  showTallVersion,
  buttons,
}) => {
  return (
    <HeroSectionStyled
      className={cssStyles.add_margin_bottom ? "content_block" : ""}
      showTallVersion={showTallVersion}
    >
      <HeroOverlayStyled
        data-testid="heroOverlay"
        images={images}
        showTallVersion={showTallVersion}
        width={cssStyles.overlay_width_desktop}
      >
        <HeroOverlayContentStyled>
          {showAffiliateImage && affiliateImage && (
            <img src={affiliateImage} alt={affiliateImageAltText} />
          )}
          <HeroHeading width={cssStyles.heading_width_desktop}>
            {headingFirstLine}
            {headingSecondLine && <span>{headingSecondLine}</span>}
          </HeroHeading>
          {showListItems ? (
            <HeroList>
              {listItems.map((item) => {
                const { list_item_icon, list_item_text } = item
                return (
                  <li key={list_item_text}>
                    <img src={list_item_icon} alt="" />
                    <span>{list_item_text}</span>
                  </li>
                )
              })}
            </HeroList>
          ) : (
            <HeroParagraphStyled text={removeHtmlTagFromString(body, "p")} />
          )}
          {buttons && (
            <Button
              href={buttons[0].url}
              to={buttons[0].url}
              as="a"
              kind={buttons[0].style}
              key={0}
              size='large'
            >
              {buttons[0].text}
            </Button>
          )}
        </HeroOverlayContentStyled>
      </HeroOverlayStyled>
      <HeroImgStyled
        data-testid="heroImage"
        images={images}
        showTallVersion={showTallVersion}
      />
    </HeroSectionStyled>
  )
}

HeroV2.propTypes = {
  images: PropTypes.object,
  showAffiliateImage: PropTypes.bool,
  affiliateImage: PropTypes.string,
  affiliateImageAltText: PropTypes.string,
  headingFirstLine: PropTypes.string,
  headingSecondLine: PropTypes.string,
  showListItems: PropTypes.bool,
  listItem: PropTypes.array,
  text: PropTypes.string,
  showButton: PropTypes.bool,
  buttons: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
}

export default HeroV2
