import React from 'react'
import PropTypes from 'prop-types'
import { isUndefinedOrNull, isEmptyObj } from '../../utils'

import { TrustPilotContext } from '../../containers/contexts'
import { TrustPilotReviewStars } from '../TrustPilotReviewStars'
import Image from '../Image'

import {
  Col,
  Row,
} from '../Grid'

import {
  Container,
  Text,
  Banner,
  BrandImage,
  HeroImage,
  TrustPilotStarsWrapper,
} from './style'

const TrustPilotHero = ({
  mainheading = 'Example heading',
  heroimage,
}) =>
  (
    <TrustPilotContext.Consumer>
      {(({ trustpilot }) => {
        if (!isEmptyObj(trustpilot)) {
          const { starsText, score, numberOfReviews } = trustpilot
          return !isUndefinedOrNull(score) && (
            <Banner>
              <Container>
                <Row>
                  <Col lg="6" md="6" sm="12" xs="12">
                    <Text>
                      <h1 data-testid="main-heading-text">{mainheading}</h1>
                      <p data-testid="trustpilot-text">Rated <strong data-testid="stars-text">“{starsText}”</strong> on Trustpilot with {parseFloat(numberOfReviews.total).toLocaleString('en')} reviews</p>
                    </Text>
                    {!isUndefinedOrNull(score.stars) && (
                      <TrustPilotStarsWrapper>
                        <a href="https://uk.trustpilot.com/review/beaglestreet.com" target="_blank" rel="noopener noreferrer">
                          <TrustPilotReviewStars data-testid="stars-rating" starsRating={score.stars} width="150px" />
                          <BrandImage style={{ width: '80px', margin: '0 auto' }} src="https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-brand-image.svg" alt="Trust Pilot brand" />
                        </a>
                      </TrustPilotStarsWrapper>
                    )}
                  </Col>
                  <Col className="xs-hidden" lg="6" md="6" sm="12" xs="12">
                    {!isUndefinedOrNull(heroimage) && (
                      <HeroImage>
                        <Image data-testid="hero-image" src={heroimage} altText="some text" responsive />
                      </HeroImage>
                    )}
                  </Col>
                </Row>
              </Container>
            </Banner>
          )
        }
        return null
      }
      )}
    </TrustPilotContext.Consumer>
  )

TrustPilotHero.propTypes = {
  mainheading: PropTypes.string,
  heroimage: PropTypes.string,
}

export default TrustPilotHero
