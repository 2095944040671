export const API_KEYS = {
  trustPilot: 'K82ZfWBEyNuR22MPIjeShgKQ5ijpHPaK',
}

const API_MAP = {
  trustPilot: {
    rating: 'https://api.trustpilot.com/v1/business-units/51755ae50000640005275317',
    reviews: 'https://api.trustpilot.com/v1/business-units/51755ae50000640005275317/reviews',
  },
  holidays: 'https://www.gov.uk/bank-holidays.json',
}

export default API_MAP
