import React from 'react'
import PropTypes from 'prop-types'
import { DataLayerLink } from '../DataLayerLink'

import {
  QuickLink,
  Container,
  Cont,
} from './style'

const QuickLinks = ({
  links = {},
}) => (
  <div className="quick_links content_block">
    <Container>
      <Cont>
        <QuickLink>
          {
            Object.keys(links).map(i => (
              <li key={i}>
                <DataLayerLink
                  kind="navy_ghost"
                  type="button"
                  size="small"
                  as="a"
                  to={links[i].anchorid}
                  location="anchor"
                  eventName="scrollClick"
                  eventKey="scrollName"
                  eventValue={links[i].text}
                >
                  {links[i].text}
                </DataLayerLink>
              </li>
            ))
          }
        </QuickLink>
      </Cont>
    </Container>
  </div>
)

QuickLinks.propTypes = {
  links: PropTypes.object,
}

export default QuickLinks
