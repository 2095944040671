import React from 'react'

// import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  trimText,
} from '../../utils'

import {
  Col,
} from '../Grid'

import {
  DataLayerLink,
} from '../DataLayerLink'

import {
  Card,
  CardImage,
  CardWrapper,
  CardTitle,
  CardText,
} from './style'

const GuideCard = ({
  post,
}) => {
  const {
    title,
    excerpt,
    tax,
    thumb,
    url,
  } = post

  return (
    <Col md="4" sm="12" xs="12">
      <Card>
        <CardImage bg={thumb}>
          <a href={url}>&nbsp;</a>
        </CardImage>
        <CardWrapper>
          <CardTitle>
            {
              tax && <span className="tax">{tax}</span>
            }
            <h3>
              <DataLayerLink
                eventName="guideClick"
                eventKey="guideName"
                eventValue={title}
                href={url}
              >
                {title}
              </DataLayerLink>
            </h3>

          </CardTitle>
          <CardText>{trimText(excerpt, 95)}</CardText>
        </CardWrapper>
      </Card>
    </Col>
  )
}

GuideCard.propTypes = {
  post: PropTypes.object,
}

export default GuideCard
