import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import GroupedArticles from '../components/GroupedArticles'
import Heading from '../components/Heading'
import FeaturedArticles from '../components/FeaturedArticles'
import BackLink from '../components/BackLink'

// import { getPosts } from '../actions/posts'

import {
  isEmptyObj,
} from '../utils'

class GuidesArchive extends Component {
  state = {
    // loading: true,
  }

  // static getDerivedStateFromProps(props, state) {
  //   const {
  //     // loadPosts,
  //     // posts,
  //     // data: { data },
  //   } = props
  //   // const { loading } = state

  //   //   if (loading) {
  //   //     if (data.acf.featured_guides) {
  //   //       const obj = {
  //   //         api: 'genericEPBS',
  //   //         url: 'misc/get_multi_posts_by_id',
  //   //         params: {
  //   //           ids: data.acf.featured_guides,
  //   //         },
  //   //       }
  //   //       loadPosts('featuredGuides', obj)
  //   //     }
  //   //     const obj = {
  //   //       api: 'genericEPBS',
  //   //       url: 'misc/get_grouped_posts_by_tax',
  //   //       params: {
  //   //         tax: 'guide-categories',
  //   //         post_type: 'guides',
  //   //       },
  //   //     }
  //   //     loadPosts('groupedPosts', obj)
  //   //   }
  //   //   return {
  //   //     loading: false,
  //   //     posts,
  //   //   }
  // }

  render() {
    const { posts } = this.props

    let featuredArticles = {}

    if (!isEmptyObj(posts.featuredGuides)) {
      const firstPost = posts.featuredGuides[0]
      const otherPosts = Object.keys(posts.featuredGuides).reduce((object, key) => {
        if (Number(key) !== Number(0)) {
          object[(Number(key) - 1)] = posts.featuredGuides[key]
        }
        return object
      }, {})

      featuredArticles = {
        firstPost,
        otherPosts,
      }
    }
    return (
      <div className="guides-archive page-margined">
        <div className="container">
          <BackLink url="/learn" text="Learn" />
        </div>
        <Heading text="Beagle Street Guides" subheading={null} margin="0" pos="center" />
        {!isEmptyObj(featuredArticles) && (
          <FeaturedArticles posts={featuredArticles} />
        )}
        {!isEmptyObj(posts.guides) && (
          <GroupedArticles posts={posts.guides} />
        )}
      </div>
    )
  }
}

GuidesArchive.propTypes = {
  // data: PropTypes.object,
  posts: PropTypes.object,
}

const mapStateToProps = state => ({
  posts: state.posts,
})

// const mapDispatchToProps = (dispatch) => ({
//   loadPosts: (key, obj) => dispatch(getPosts(key, obj)),
// })

export default connect(mapStateToProps, null)(GuidesArchive)
