import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container};
  background-color: #fff;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 45px;
  max-width: 1108px!important;
  
  ${query.combine(['large'], ` 
    padding-left: 44px;
    padding-right: 44px;
    max-width: 928px!important;
  `)} 
  ${query.combine(['medium'], ` 
    padding-left: 44px;
    padding-right: 44px;
    padding-bottom: 0;
    max-width: 690px!important;
  `)} 

  ${query.combine(['small'], ` 
      padding-left: 22px;
      padding-right: 22px;
      padding-bottom: 0;
      max-width: 512px!important;
    `)}
  ${query.combine(['extraSmall',], ` 
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 0;
    max-width: 100%!important;
  `)} 
`

const WrapperStyled = styled.div`
  background-color: ${props => props.background ? props.background : '#fff'};
  padding-top: 40px;
  padding-bottom: 40px;
  
  ${query.combine(['extraSmall'], ` 
    padding-top: 0;
    padding-bottom: 0;
  `)} 
`
const TopContentStyled = styled.div`
  max-width: 850px;
  margin: 0 auto;
`

const Heading = styled.h2`
  color: #1D374E;
  margin: 0 0 10px 0;
  padding-top: 28px;
  text-align: center; 
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  
  span {
    display: block;
    text-transform: uppercase;
    color: #9361B0;
    font-size: .6em;
    a {
      margin-bottom: 16px;
      background-color: #00C2A2;
      padding: 5px 8px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #1D374E;
      text-decoration: none;
    }
  }

  ${query.combine(['extraSmall', 'small'], ` 
   font-size: 30px;
   line-height: 38px;
   letter-spacing: -0.02em;
  `)} 
`

const TopText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #344B60;
  margin-top: 0px;
  margin-bottom: 55px;

  ${query.combine(['extraSmall', 'small'], ` 
   margin-bottom: 42px;
   text-align: left;
  `)}
`

const Grid = styled.div`
  margin-bottom: 0;

  .col:nth-child(1),
  .col:nth-child(2) {
    margin-bottom: 35px;
  }

  .col:nth-child(odd) {
    padding-right: 45px;
  }
  .col:nth-child(even) {
    padding-left: 45px;
  }

  ${query.combine(['extraSmall', 'small', 'medium'], ` 
    .col {
      margin-bottom: 40px!important;
      padding-left: 15px;
      padding-right: 15px;
    }
    .col:last-child {
      margin-bottom: 0;
    }

    .col:nth-child(odd),
    .col:nth-child(even) {
      padding-left: 15px;
      padding-right: 15px;
    }
  `)} 
`

const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;

  h3 {
    margin-bottom: 0;
    margin-top: 0;   
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    letter-spacing: -0.02em;
    color: #1D374E;
  }

  img {
    margin-right: ${props => props.iconPosition?.right ? props.iconPosition.right : 18}px;
    margin-top: ${props => props.iconPosition?.top ? `${props.iconPosition.top}px` : 0};
  }

  p {
    margin-top: 0;
  }
`

const ItemFooter = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  color: #344B60;
  p {
    font-size: 16px;
    margin-top: 0;
    line-height: 24px;
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
    ${query.combine(['extraSmall', 'small', 'medium'], ` 
      font-size: 18px;  
    `)} 
  }

  a {
    color: #344B60;
    &:hover {
      font-weight: 600;
    }
  }

`

export {
  Container,
  Heading,
  TopText,
  Grid,
  ItemHeader,
  ItemFooter,
  WrapperStyled,
  TopContentStyled,
}
