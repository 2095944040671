import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`
const Content = styled.div`
  padding: 2rem;
  margin-bottom: 3rem;
  padding-top: 0;
  border-bottom: #F3F5F8 3px solid;
  ${query.combine(['extraSmall', 'small', 'medium'], ` 
    padding: 0 0 2rem 0;    
  `)}    
`

const Accred = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  ${query.combine(['extraSmall', 'small', 'medium'], ` 
    margin-bottom: 2rem;   
  `)}  
  img {
    vertical-align: middle;
    margin-right: 1rem;
    float: left;  
  }
  span {
    p {
      display: inline;
      margin: 0;
      color: #373737;
      font-size: 1em;
      line-height: 1.2;
    }
  }
`

export {
  Container,
  Content,
  Accred,
}
