import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// import { getlearnAccordion } from '../actions/learn'
// import { getPosts } from '../actions/posts'

import LearnHero from '../components/LearnHero'
import LearnAccordionGrid from '../components/LearnAccordionGrid'
import GuidesBlock from '../components/GuidesBlock'

// import { fetchLearnAccordion, fetchFeaturedGuides } from '../store'

import {
  isEmptyObj,
} from '../utils'

const LearnArchive = ({
  data,
  accordionData,
  posts,
}) => (
  <div className="learn-archive">
    <LearnHero data={data} />
    {!isEmptyObj(accordionData) && (
      <LearnAccordionGrid data={accordionData} />
    )}
    {!isEmptyObj(posts.learnGuides) && (
      <GuidesBlock data={posts.learnGuides} />
    )}
  </div>
)

LearnArchive.propTypes = {
  data: PropTypes.object,
  accordionData: PropTypes.object,
  posts: PropTypes.object,
}

const mapStateToProps = state => ({
  accordionData: state.learnAccordion,
  posts: state.posts,
})

// const mapDispatchToProps = (dispatch) => ({
// loadLearnAccordion: () => dispatch(getlearnAccordion()),
//   loadPosts: (key, obj) => dispatch(getPosts(key, obj)),
// })

export default connect(mapStateToProps, null)(LearnArchive)
