import React, { useState, useEffect, useRef } from 'react'

import Icon from '../Icons/Icon'

import SearchContainer from './style'
import { dataLayerPush } from '../../utils'

const Search = () => {

  const [isSearchVisible, setIsSearchVisible] = useState(false)

  let formRef = useRef()

  useEffect(() => {
    let handler = (event) => {
      if (!formRef.current?.contains(event.target)) {
        setIsSearchVisible(false)
      }
    }
    document.addEventListener("mousedown", handler)
    return () => {
      document.removeEventListener("mousedown", handler)
    }
  })

  useEffect(() => {
    if (isSearchVisible) document.getElementById('input_search_top').focus()
  })

  const toggleSearch = () => {

    setIsSearchVisible(!isSearchVisible)
    dataLayerPush({
      event: 'ButtonClick',
      buttonName: 'Search',
    })
  }


  return (
    <SearchContainer>
      <button onFocus={() => toggleSearch()} className={isSearchVisible ? 'searchHidden' : 'searchButton'} onClick={() => toggleSearch()} >
        <Icon kind="search" size="15" color="#ffffff" /> Search
      </button>

      {isSearchVisible && (
        <form ref={formRef} action="/search" method="GET">
          <button id="focusButton" className='search-button' >
            <Icon kind="search" size="15" color="#ffffff" className="icon" />
          </button>
          <input
            type="text"
            name="s"
            id="input_search_top"
            placeholder="Search"
          />
          <button className="close" type="button" onClick={() => toggleSearch()}>
            <Icon kind="close" size="20" color="#28334A" />
          </button>
          <input type="hidden" name="post_type" value="all" />
        </form>
      )}
    </SearchContainer>
  )
}


export default Search
