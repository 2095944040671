import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { isEmptyArray } from '../utils'

import {
  Row,
  Col,
} from '../components/Grid'

import Heading from '../components/Heading'
import SearchInput from '../components/SearchInput'
import SearchResults from '../components/SearchResults'

// eslint-disable-next-line react/prefer-stateless-function
class Search extends Component {
  render() {
    const { posts, general } = this.props
    const { params: { s: searchText, post_type: postType } } = general
    return (
      <div className="page-margined search-results">
        <div className="container">
          <Row>
            <Col lg={{ span: 8, offset: 2 }} md="12" sm="12" xs="12">
              <Heading text="Search results" subheading={null} margin="0 0 2rem 0" />
              <SearchInput
                action="/search"
                method="get"
                id="search_bar"
                name="s"
                placeholder={searchText}
                icon="search"
              >
                <input type="hidden" name="post_type" value={postType} />
              </SearchInput>
              {!isEmptyArray(posts.searchResults) && (
                <SearchResults data={posts.searchResults} />
              )}
              {isEmptyArray(posts.searchResults) && (
                <p>
                  <strong>
                    No results found for &quot;{searchText}&quot;, please try searching again.
                  </strong>
                </p>
              )}
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

Search.propTypes = {
  posts: PropTypes.object,
  general: PropTypes.object,
}

const mapStateToProps = state => ({
  posts: state.posts,
  general: state.api.general,
})

export default connect(mapStateToProps, null)(Search)
