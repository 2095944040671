export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  screenReaderHeading: {
    mappedProp: 'screenReaderHeading'
  },
  content: {
    mappedProp: 'content',
  },
}
