import {
  GET_LEARN_ACC_REQUEST,
  GET_LEARN_ACC_SUCCESS,
  GET_LEARN_ACC_ERROR,
} from '../actions/learn'

const learnAccordion = (
  state = {
    loading: undefined, // Bool
  },
  action,
) => {
  switch (action.type) {
    case GET_LEARN_ACC_REQUEST:
      return { ...state, loading: true }
    case GET_LEARN_ACC_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case GET_LEARN_ACC_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

export default learnAccordion
