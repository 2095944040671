import styled, { css } from 'styled-components'
import { container, query } from '../../theme'
import Text from '../Text'

const Container = styled.div`
  ${container}
`

const ListStyled = styled.ul`
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 0;
  li {
    display: flex;
    list-style: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1D374E;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      max-width: 450px;
    }
  }
  ${query.combine(['extraSmall', 'small'], `
    margin-bottom: 21px;
  `)}
`

const EyebrowH2Styled = styled.h2`
  margin: 0;
  line-height: 0;

  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
  `)}
`

const EyebrowTextStyled = styled.a`
  display: inline-block;
  text-align: left;
  text-transform: uppercase;
  font-size: .5em;
  margin-bottom: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #1D374E;
  text-decoration: none;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  &:focus {
    outline-offset: 2px;
  }

  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    margin-bottom: 15px;
  `)}
  span {
    display: inline-block;
    background-color: #0AC4A9;
    padding: 3px 7px;
    ${query.combine(['extraSmall', 'small'], `
      text-align: left;
      display: inline-block;
      padding: 3px 5px;
    `)}
  }
`

const TextBody = styled.div`
  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    color: #344B60;
    margin-bottom: 8px;
    margin-top: 8px;

    &:last-child {
      margin-top: 8px;
      margin-bottom: 20px;
    }

    &:first-child {
      margin-top: 16px;
    }


    ${query.combine(['extraSmall', 'small'], `
      text-align: left;
      font-size: 16px;
      line-height: 1.5;

      &:last-child {
        margin-top: 8px;
        margin-bottom: 18px;
      }
    `)}
  }
`

const TextStyled = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1D374E;
`

const LR = styled.div`
  .image_col {
    ${query.combine(['extraSmall', 'small', 'medium'], `
        text-align: center;
        img {
          margin-top: 1rem;
          margin-top: 40px;
        }
    `)}
    &.left {
      ${query.combine(['extraSmall', 'small', 'medium'], `
        order: 2;
      `)}
    }
    &.right {
      text-align: right;
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
    }
  }
`

const Posts = styled.div`
  margin-bottom: 24px;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    max-width: 460px;

    li {
      border-top: #B4D7E8 1px solid;
      text-decoration: none;

      &:last-child {
        border-bottom: #B4D7E8 1px solid;
      }
      .hidden_content {
        display: none;
        font-size: 1rem;
      }
      a {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        text-decoration: none;

        > span {
          width: 100%;
          color: #1D374E;
        }
      }
      a.hidden_content {
        color: #1D374E!important;
        border-bottom: #1D374E 1px solid;
        padding: 0 0 .2rem 0rem!important;
        margin-bottom: 1rem!important;
        text-decoration: none;
        &:hover {
          font-weight: 600;
        }
      }
      a:not(.hidden_content) {
        padding: .6rem 0;
        color: #1D374E;
        &:hover {
          font-weight: 600;
        }
        &:before {
          width: 0;
          height: 0;
          content: '';
          color: #373737;
          display: block;
          margin: 0 .5rem 0 0;
          position: relative;
          top: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 9px solid #23C3A6;
        }
      }
      &.active {
        a:not(.hidden_content) {
          text-decoration: none;
          font-weight: 600;
          &:before {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 9px solid #23C3A6;
            border-top: none;
            top: 0;
          }
        }
        .hidden_content {
          display: block;
          margin-bottom: .6rem;
          color: #373737;
          padding-left: 20px;
        }
        a.hidden_content {
          display: inline-block!important;
          margin-left: 20px;
        }
      }

      span {
        max-width: 450px;
      }
    }
  }
`

const Buttons = styled.div`
  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
  `)}

  button, a {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    padding: 0px;
    display: inline-flex;
    align-content: center;
    width: auto;
    height: auto;
    padding: 10px 18px;
    margin-top: 16px;
    margin-bottom: 0;
    margin-right: 1rem;

    &:hover {
      svg {
        position: relative;
        transform: translateX(8px);
        transition: transform 0.5s ease 0s;
      }
    }

    svg {
      position: relative;
      transform: translateX(0);
      transition: transform 0.5s ease 0s;
    }

    ${query.combine(['extraSmall', 'small'], `
      padding-left: 30px;
      padding-right: 30px;
      min-width: auto;
      text-align: center;
      font-weight: 700;
      margin-top: 0;
      margin-bottom: 16px;
      margin-right: 0;
      display: inline-block;
      margin-left: 0;

      &:last-child {
        margin-bottom: 0;
      }
    `)}

    &:last-child {
      margin-right: 0;
    }
    svg {
      margin-left: 10px;
      width: 10px;
      height: 14px;
      top: 3px;
      position: relative;
    }
  }
`

const mainHeadingStyles = css`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #1D374E;
  margin-top: 0px;
  margin-bottom: 15px;
  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    font-size: 30px;
    line-height: 28px;
    margin-bottom: 19px;
  `)}
`

const MainHeadingL2Styled = styled.h2`
  ${mainHeadingStyles}
`

const MainHeadingL3Styled = styled.h3`
  ${mainHeadingStyles}
`

const ListIcon = styled.img`
  height: 100%;
  width: auto;
  margin-right: ${props => props.position?.right ? props.position.right : 15}px;
  margin-top: ${props => props.position?.top ? props.position.top : 3}px;
`

export {
  Container,
  LR,
  Posts,
  Buttons,
  TextBody,
  EyebrowH2Styled,
  EyebrowTextStyled,
  TextStyled,
  ListStyled,
  MainHeadingL2Styled,
  MainHeadingL3Styled,
  ListIcon,
}
