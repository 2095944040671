import { css } from 'styled-components'

// convert font-size to REM, 16 being the base size in HTML.
const rem = (size) => `${parseInt(size, 10) / 16}rem`

// return all font styles.
const textStyles = (
  weight, size, line, tablet, mobile, tiny,
) => (
  css`
    font-size: ${rem(size)};
    font-weight: ${weight};
    line-height: ${line};
    margin: 0;
    @media only screen and (max-width: 768px) {
      font-size: ${rem(tablet)};
    }
    @media only screen and (max-width: 375px) {
      font-size: ${rem(mobile)};
    }
    @media only screen and (max-width: 345px) {
      font-size: ${rem(tiny)};
    }
  `
)

const typeScale = {
  heroH1: textStyles(500, 75, '1.2', 38),
  heroPara: textStyles(500, 18, '1.2', 18, 16, 15),
}

export default typeScale
