import styled from 'styled-components'
import { container, query } from '../../theme'
import {
  Col,
  Row,
} from '../Grid'
import Text from '../Text'

const Container = styled.div`
  ${container}
`

const ColStyled = styled(Col)`
  > div {
    margin-bottom: ${props => props.numberOfItems >= 5 ? "60px" : "0"};

    @media only screen and (max-width: 767px) {
      margin-bottom: 42px;
    }
  }

  @media only screen and (min-width: 768px) {
    &:nth-child(4) {
     > div {
       margin-bottom: 0;
     }
    }
    &:nth-child(5) {
     > div {
       margin-bottom: 0;
     }
    }
    &:nth-child(6) {
     > div {
       margin-bottom: 0;
     }
    }
  }

  @media only screen and (max-width: 767px) {
    &:nth-last-child(1) {
      > div {
        margin-bottom: 0;
      }
    }
  }
`

const RowStyled = styled(Row)`
  justify-content: center;
`

const iconListHeadingSize = (itemCount) => {
  if (itemCount === 1) {
    return "1.625rem"
  }
  if (itemCount === 2) {
    return "1.125rem"
  }
  if (itemCount === 3) {
    return "1.125rem"
  }
  if (itemCount === 4) {
    return "1rem"
  }

  if (itemCount === 5) {
    return "1.125rem"
  }

  if (itemCount === 6) {
    return "1.125rem"
  }

  return "1.2rem"
}


const iconListParagraphSize = (itemCount) => {
  if (itemCount === 1) {
    return "1rem"
  }

  if (itemCount === 2) {
    return "1rem"
  }

  if (itemCount === 3) {
    return "1rem"
  }

  if (itemCount === 4) {
    return "1rem"
  }

  if (itemCount === 5) {
    return "1.125rem"
  }

  if (itemCount === 6) {
    return "1.125rem"
  }

  return "0.95rem"
}

const spacing = (itemCount) => {
  if (itemCount === 3) {
    return "55px"
  }

  if (itemCount === 4) {
    return "30px"
  }

  return "47px"
}

const Icon = styled.div`
  text-align: center;
  padding-left: ${props => spacing(props.itemCount)};
  padding-right: ${props => spacing(props.itemCount)};

  @media screen and (max-width: 767px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  ${query.combine(['extraSmall', 'small'], `
    margin-bottom: 1rem;
  `)}
  img {
    margin: 0 auto;
    display: block;
  }

  strong {
    font-style: normal;
    font-weight: 700;
    color: #28334A;
    font-size: ${props => iconListHeadingSize(props.itemCount)};
    display: block;
    margin: 26px 0 4px 0;
    line-height: 150%;
    ${query.combine(['extraSmall', 'small'], `
      font-size: 1.125rem;
    `)}
  }
`

const ParagraphStyled = styled(Text)`
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 1.5;
  max-width: 840px;
  margin: 18px auto 48px auto;
  text-align: center;
  color: #373737;
  ${query.combine(['extraSmall', 'small'], `
      font-size: 16px;
      margin-bottom: 34px;
  `)}
`

const Heading2Styled = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  a {
    color: #9361b0;
    text-decoration: none;
  }
`

const TextStyled = styled(Text)`
  margin: 0;
  font-size: 1rem;
  line-height: 1.5;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  color: #373737;
  ${query.combine(['extraSmall', 'small'], `
    font-size: 16px;
  `)}

  strong {
    display: inline;
    font-size: 1rem;
  }
`

const Heading3Styled = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;   /* or 125% */
  text-align: center;
  color: #28334a;
  margin: 0;
`


export {
  Container,
  Icon,
  ParagraphStyled,
  Heading2Styled,
  ColStyled,
  RowStyled,
  TextStyled,
  Heading3Styled,
}
