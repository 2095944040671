import React from 'react'
import PropTypes from 'prop-types'

import Carousel from '../Carousel'

import Incentive from '../Incentive'
import GridItem from '../Incentive/GridItem'

import { Container, SlideItem, WrapperStyled } from './style'
import { isUndefinedOrNull, removeHtmlTagFromString } from '../../utils'

const IncentiveWithHeading = ({
  section,
  gridItems
}) => {

  if (isUndefinedOrNull(section) || isUndefinedOrNull(gridItems)) return null

  return (
    <WrapperStyled className="incentive_with_carousel content_block" >
      <Container>
        <Incentive>
          <Incentive.MainHeading text={section.title} url={section.titleLink} />
          <Incentive.SubHeading text={section.subheading} />
          <Incentive.Body>{removeHtmlTagFromString(section.body, 'p')}</Incentive.Body>
          <div>
            <Carousel>
              {gridItems.map(i => (
                <SlideItem className="glider-item">
                  <GridItem
                    itemCount={4}
                    icon={i.iconUrl}
                    alt={i.iconAltText}
                    title={i.title}
                    body={i.body}
                  />
                </SlideItem>
              ))}
            </Carousel>
          </div>
        </Incentive>
      </Container>
    </WrapperStyled>
  )
}

IncentiveWithHeading.propTypes = {
  numberOfListItems: PropTypes.number,
  icon: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
}

export default IncentiveWithHeading
