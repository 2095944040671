export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  heading: {
    mappedProp: 'heading',
  },
  paragraph: {
    mappedProp: 'paragraph',
  },
  quote_button: {
    mappedProp: 'quoteButton',
  },
  contact_us_number: {
    mappedProp: 'contactUsNumber',
  },
  list_title: {
    mappedProp: 'listTitle',
  },
  list: {
    mappedProp: 'list',
  },
  mobile_call_us_button_text: {
    mappedProp: 'mobileCallUsButtonText',
  },
  background: {
    mappedProp: 'background',
  },
  icon_position: {
    mappedProp: 'iconPosition',
  },
  add_margin_bottom: {
    mappedProp: 'addMarginBottom',
  }
}
