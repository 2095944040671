import React from 'react'
import PropTypes from 'prop-types'
import { isUndefinedOrNull, isEmptyObj } from '../../utils'

import { TrustPilotContext } from '../../containers/contexts'

import {
  Banner,
  BrandImage,
  GiftsContainerStyled,
  RatingsWrapper,
  TextRating,
  ReviewsSummary,
  Defaqto,
  TrustpilotLink,
  LeftImage,
  IncentiveText,
  IncentiveTextMobile,
  TrustpilotStarRating,
  RightImage
} from './style'

const IncentiveV2 = ({
  accessibility,
  reviews,
  gifts,
  addMarginBottom,
  background,
  leftImageWidthMobile,
  rightImageWidthMobile,
}) => {
  const { show_defaqto_logo } = reviews

  return (
    <>
      <Banner background={background} addMarginBottom={addMarginBottom} className={addMarginBottom && 'content_block'}>
        <h2 className='sr-only'>{accessibility.hidden_heading}</h2>
        <RatingsWrapper addMargin={show_defaqto_logo}>
          <TrustPilotContext.Consumer>
            {(({ trustpilot }) => {
              if (!isEmptyObj(trustpilot)) {
                const { starsText, score, numberOfReviews } = trustpilot
                return !isUndefinedOrNull(score) && (
                  <>
                    <ReviewsSummary aria-hidden><TextRating>{starsText}</TextRating> {score.trustScore} | {parseFloat(numberOfReviews.total).toLocaleString('en')} reviews</ReviewsSummary>
                    <TrustpilotLink href="https://uk.trustpilot.com/review/beaglestreet.com" target="_blank" rel="noopener noreferrer">
                      <TrustpilotStarRating data-testid="stars-rating" starsRating={score.stars} trustScore={score.trustScore} width="166px" />
                      <BrandImage src={reviews.trustpilot_logo} alt={reviews.trustpilot_logo_alt_text} />
                    </TrustpilotLink>
                  </>
                )
              }
              return null
            }
            )}
          </TrustPilotContext.Consumer>

          {show_defaqto_logo && <Defaqto><img src={reviews.defaqto_logo} alt={reviews.defaqto_logo_alt_text} /></Defaqto>}
        </RatingsWrapper>
        <GiftsContainerStyled showRightImage={gifts.show_right_image}>
          <IncentiveTextMobile showRightImage={gifts.show_right_image}>{gifts.text}</IncentiveTextMobile>
          <div>
            <LeftImage src={gifts.left_image} alt={gifts.left_image_alt_text} imgWidth={leftImageWidthMobile} />
            <IncentiveText>{gifts.text}</IncentiveText>
            {gifts.show_right_image && <RightImage src={gifts.right_image} alt={gifts.right_image_alt_text} imgWidth={rightImageWidthMobile} />}
          </div>
        </GiftsContainerStyled>
      </Banner>
    </>
  )
}

IncentiveV2.propTypes = {
  accessibility: PropTypes.object,
  reviews: PropTypes.object,
  gifts: PropTypes.object,
  addMarginBottom: PropTypes.bool,
  background: PropTypes.string,
}

export default IncentiveV2
