import styled from 'styled-components'
import { Col } from '../Grid'

const CardWrapper = styled.div`
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
  margin-bottom: 22px;
  p {
    line-height: 18px;
    margin-bottom: 5px;
    margin-top: 0;
    color: #203C52;
  }
  img {
    max-width: 165px;
  }
`

const Wrapper = styled.div`
  text-align: center;
  background-color: #F3F5F8;
  padding: 10px 15px;
  h3 {
    font-size: 24px;
    line-height: 32px;
    color: #203C52;
    font-weight: 600;

    @media only screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
`

const BenefitsContainer = styled(Col)`
  @media only screen and (max-width: 768px) {
    max-width: 100%!important;
  }
`

const BenefitsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 75%;
  margin: 0 auto 30px auto;
  @media only screen and (max-width: 767px) {
    width: 90%;
  }
  p {
    color: #373737;
    margin: 0;
    line-height: 17px;
    font-size: 13px;
    padding-left: 10px;
    text-align: left;
    width: 100%;
   }
`

const IconWrapper = styled.div`
  width: 15%;
  height: 35px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const Benefit = styled.div`

`

export {
  CardWrapper,
  BenefitsContainer,
  BenefitsWrapper,
  Benefit,
  Wrapper,
  IconWrapper,
}
