const color = {
  amazonite: '#00C2A2',
  purple: '#8038A9',
  azureishWhite: '#DDF0EE',
  aliceBlue: '#EFF8F7',
  christianGrey: '#373737',
  raisinBlack: '#222222',
  sonicSilver: '#757575',
  pastelGrey: '#CCCCCC',
  whiteSmoke: '#F5F5F5',
  white: '#FFFFFF',
  navy: '#28334A',
  green: '#05B097',
  blue: '#072760',
}

const theme = {
  primary: color.amazonite,
  secondary: color.purple,
  paragraph: color.christianGrey,
  altPrimary: color.amazonite,
  lightPrimary: color.azureishWhite,
  heroTitle: color.green,
  divider: color.pastelGrey,
  white: color.white,
  navy: color.navy,
  tintedWhite: color.aliceBlue,
  textBlue: color.blue,
}

export {
  color,
  theme,
}
