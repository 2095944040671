// import { arrayToObject } from '../utils/convertData'
// import postTypes from '../post-types'

// const postTypeDefaultState = arrayToObject(postTypes)

const defaultState = {
  data: {},
  menus: {
    loading: undefined,
  },
  routes: {
    loading: undefined,
  },
  general: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case 'LOAD_DATA':
      return {
        ...state,
        data: {
          ...state.data,
          [action.key]: action.payload,
        },
      }
    case 'LOAD_ROUTES':
      return {
        ...state,
        routes: {
          ...state.routes,
          loading: false,
          [action.key]: action.payload,
        },
      }
    case 'LOAD_GENERAL':
      return {
        ...state,
        general: {
          ...state.general,
          [action.key]: action.payload,
        },
      }
    case 'LOAD_MENU':
      return {
        ...state,
        menus: {
          ...state.menus,
          loading: false,
          ...action.payload,
        },
      }
    case 'CLEAR_API_CONTENT':

      return {
        ...defaultState,
      }

    case 'CLEAR_API_DATA_BY_SLUG':

      if (state.data[action.payload.type] && state.data[action.payload.type][action.payload.slug]) {
        const newState = { ...state }
        delete newState.data[action.payload.type][action.payload.slug]

        return newState
      }

      return state

    default:
      return state
  }
}
