import React from 'react'
import PropTypes from 'prop-types'

import { removeHtmlTagFromString } from '../../utils'
import { ParagraphStyled } from './style'

const Body = ({
  children,
}) => {
  return (
    <ParagraphStyled text={removeHtmlTagFromString(children, 'p')} />
  )
}

Body.propTypes = {
  children: PropTypes.object.isRequired,
}

export default Body
