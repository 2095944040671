import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BackLink from '../components/BackLink'
import GuideArticle from '../components/GuideArticle'

import {
  isEmptyObj,
} from '../utils'

const Guides = ({data, posts}) => {
  return (
    <div className="page-margined guide-post">
      <div className="container">
        <BackLink url="/guides" text="All Guides" />
        <GuideArticle
          data={data}
          related={!isEmptyObj(posts.relatedGuides) ? posts.relatedGuides : null}
        />
      </div>
    </div>
  )
}

Guides.propTypes = {
  data: PropTypes.object,
  posts: PropTypes.object,
}

const mapStateToProps = state => ({
  posts: state.posts,
})



export default connect(mapStateToProps, null)(Guides)
