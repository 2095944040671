import React from 'react'
import PropTypes from 'prop-types'

import {
  DataLayerLink,
} from '../DataLayerLink'

import {
  isUndefinedOrNull,
  slugify,
  isEmptyString,
  cleanHtml,
} from '../../utils'

import {
  LearnArticleContainer,
  Heading,
  Summary,
  Contents,
  ContentsLink,
  Sections,
  Section,
  RelatedTopics,
} from './style'

const ArticleContents = ({
  section,
  type,
}) => {
  if (isEmptyString(section.heading)) return false

  const link = `#${slugify(section.heading)}`
  let ret

  if (type === 'desktop') {
    ret = (
      <ContentsLink>
        <DataLayerLink
          eventName="scrollClick"
          eventKey="scrollName"
          href={link}
        >
          {section.heading}
        </DataLayerLink>
      </ContentsLink>
    )
  } else if (type === 'mobile') {
    ret = (
      <li>
        <DataLayerLink
          eventName="scrollClick"
          eventKey="scrollName"
          href={link}
        >
          {section.heading}
        </DataLayerLink>
      </li>
    )
  }

  return ret
}

const ArticleSection = ({
  section,
}) => {
  const id = slugify(section.heading)
  return (
    <Section>
      <h2 id={id}>{section.heading}</h2>
      <div
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: cleanHtml(section.content) }}
      />
    </Section>
  )
}

const RelatedTopicLink = ({
  post,
}) => {
  const link = `/${post.post_type}/${post.post_name}`
  return (
    <li><a href={link}>{post.post_title}</a></li>
  )
}

const LearnArticle = ({
  data,
}) => (
  <LearnArticleContainer>
    <Heading>{data.title}</Heading>
    {(!isUndefinedOrNull(data.acf.summary) && !isEmptyString(data.acf.summary)) && (
      <Summary><strong>Summary:</strong> {data.acf.summary}</Summary>
    )}
    {(!isUndefinedOrNull(data.acf.section_content) && data.acf.section_content) && (
      <Contents>
        <strong>Contents</strong>
        <div className="links u-clearfix">
          {Object.keys(data.acf.section_content).map(i => <ArticleContents section={data.acf.section_content[i]} key={i} type="desktop" />)}
          <div className="mobile-contents" aria-hidden="true">
            { /* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="contents_check">Contents</label>
            <input id="contents_check" type="checkbox" name="contents" />
            <ul>
              {Object.keys(data.acf.section_content).map(i => <ArticleContents section={data.acf.section_content[i]} key={i} type="mobile" />)}
            </ul>
          </div>
        </div>
      </Contents>
    )}
    {!isUndefinedOrNull(data.acf.section_content) && (
      <Sections>
        {Object.keys(data.acf.section_content).map(i =>
          <ArticleSection section={data.acf.section_content[i]} key={i} />)}
      </Sections>
    )}
    {(!isUndefinedOrNull(data.acf.related_topics) && !isEmptyString(data.acf.related_topics)) && (
      <RelatedTopics>
        <strong>Related Topics</strong>
        <ul>
          {Object.keys(data.acf.related_topics).map(i =>
            <RelatedTopicLink post={data.acf.related_topics[i]} key={i} />)}
        </ul>
      </RelatedTopics>
    )}
  </LearnArticleContainer>
)

ArticleContents.propTypes = {
  section: PropTypes.object,
  type: PropTypes.string,
}

ArticleSection.propTypes = {
  section: PropTypes.object,
}

RelatedTopicLink.propTypes = {
  post: PropTypes.object,
}

LearnArticle.propTypes = {
  data: PropTypes.object,
}

export default LearnArticle
