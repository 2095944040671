import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const LR = styled.div`
  h2 {
    span {
      display: block;
      text-transform: uppercase;
      color: #9361B0;
      font-size: .5em;
      margin-bottom: .5rem;
      a {
        color: #9361B0;
        text-decoration: none;
      }
    }
    font-size: 2.2em;
    line-height: 1.2;
    margin: 0 0 1.5rem 0;
    color: #28334A;
    ${query.large`
      font-size: 1.8em;
    `}
  }
  .content {
    p {
      font-size: 1em;
      ${query.large`
        font-size: 1em;
      `}
    }
    a {
      color: #8e5aad;
      text-decoration: none;
      &:hover {
        color: #000;
      }
    }
  }
  .image_col {
    ${query.combine(['extraSmall', 'small', 'medium'], `
        text-align: center;
        img {
          margin-top: 1rem;
        }
    `)}
    &.left {
      ${query.combine(['extraSmall', 'small', 'medium'], `
        order: 2;
      `)}
    }
    &.right {
    }
  }
`

const Icons = styled.div`
  .iconCont {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.5rem;
    &:first-child {
      ${query.combine(['extraSmall', 'small'], `
        margin-bottom: 1em;
    `)}
    }
  }
  img {
    vertical-align: middle;
    margin-right: 1rem;
    float: left;
  }
  span {
    p {
      display: inline;
      margin: 0;
      color: #373737;
      font-size: 1em;
      line-height: 1.2;
    }
  }
`

const Posts = styled.div`
  margin-bottom: 1.5rem;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      border-top: #DBDBDB 1px solid;
      &:last-child {
        border-bottom: #DBDBDB 1px solid;
      }
      .hidden_content {
        display: none;
        font-size: 1em;
      }
      a {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        flex-direction: row;
        > span {
          width: 100%;
        }
      }
      a.hidden_content {
        color: #9361B0!important;
        border-bottom: #DBDBDB 1px solid;
        padding: 0 0 .2rem 0rem!important;
        margin-bottom: 1rem!important;
        text-decoration: none;
        &:hover {
          color: #000!important;
        }
      }
      a:not(.hidden_content) {
        /* display: inline-block; */
        padding: .6rem 0;
        color: #9361B0;
        text-decoration: none;
        &:hover {
          color: #000;
        }
        &:before {
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"/></svg>');
          background-repeat: no-repeat;
          width: 15px;
          height: 15px;
          content: '';
          color: #373737;
          display: block;
          margin: 0 .5rem 0 0;
          position: relative;
          top: 3px;
        }
      }
      &.active {
        a:not(.hidden_content) {
          &:before {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"/></svg>');
            background-repeat: no-repeat;
          }
        }
        .hidden_content {
          display: block;
          margin-bottom: .6rem;
          color: #373737;
          padding-left: 1.2rem;
        }
        a.hidden_content {
          display: inline-block!important;
          margin-left: 1.2rem;
        }
      }
    }
  }
`

const Buttons = styled.div`
  button, a {
    ${query.combine(['extraSmall', 'small'], `
      margin-right: 0;
      margin-bottom: 1rem;
      display: block;
      width: 100%;
      &:last-child {
        margin-bottom: 0;
      }
    `)}
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`

export {
  Container,
  LR,
  Icons,
  Posts,
  Buttons,
}
