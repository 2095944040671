import React from 'react'
import PropTypes from 'prop-types'
import {
  isUndefinedOrNull, dataLayerPush, anchorClick,
} from '../../utils'
import Btn from './style'

const DataLayerLink = ({
  children,
  href,
  eventName,
  eventKey,
  className,
  kind,
  as,
  to,
  type,
  size,
  location,
  eventValue,
  ariaLabel,
  checkIndexes= true,
}) => {
  const button = React.createRef()
  const clickHandler = (e) => {
    const { currentTarget: { innerText }, target } = e
    const links = [...document.querySelectorAll(!isUndefinedOrNull(as) ? as : 'a')].filter(link => link.innerText === innerText)
    const linkIndex = links.indexOf(target) > 0 && checkIndexes ? links.indexOf(target) + 1 : ''
    const isContinueReading = innerText.toLocaleLowerCase() === 'continue reading'
    const message = isContinueReading ? `${innerText} ${href}` : innerText
    const hasEventVale = !isUndefinedOrNull(eventValue)
    dataLayerPush({
      event: eventName,
      [eventKey]: `${!hasEventVale ? message : eventValue} ${linkIndex}`.trim(),
    })
  }

  if (as === 'a' && location === 'anchor') {
    return (
      <Btn
        kind={kind}
        as={as}
        to={to}
        ref={button}
        href={href}
        type={type}
        size={size}
        location={location}
        onClick={(e) => {
          // setSendRequest(true)
          clickHandler(e)
          anchorClick(e, location)
        }}
      >
        {children}
      </Btn>
    )
  }
  if (as === 'a') {
    return (
      <Btn
        kind={kind}
        as={as}
        to={to}
        ref={button}
        href={href}
        type={type}
        size={size}
        location={location}
        onClick={(e) => {
          clickHandler(e)
        }}
      >
        {children}
      </Btn>
    )
  }
  if (!isUndefinedOrNull(location)) {
    return (
      <Btn
        kind={kind}
        as={as}
        to={to}
        href={href}
        type={type}
        size={size}
        location={location}
        onClick={(e) => {
          clickHandler(e)
          // anchorClick(e, location)
        }}
      >
        {children}
      </Btn>
    )
  }
  if (!isUndefinedOrNull(href)) {
    return (
      <a
        href={href}
        className={className}
        onClick={(e) => {
          clickHandler(e)
        }}
        aria-label={ariaLabel ? ariaLabel : null}
      >
        {children}
      </a>
    )
  }
  return null
}

DataLayerLink.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  eventName: PropTypes.string,
  eventKey: PropTypes.string,
  to: PropTypes.string,
  location: PropTypes.string,
  kind: PropTypes.string,
  size: PropTypes.string,
  as: PropTypes.oneOf(['div', 'a', 'link', 'button']),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  eventValue: PropTypes.string,
  ariaLabel: PropTypes.string,
}

export default DataLayerLink
