import React from 'react'
import PropTypes from 'prop-types'

import { isArray, mergeStyles } from '../../utils'
import { IconList, IconObjects } from '../../common/const'
import * as Svg from './svgLoader'

const IconSelector = (i) => {
  if (IconList.includes(i)) {
    return Svg[IconObjects.filter(o => o.value === i)[0].key]()
  }
  return (<img alt="missing Icon" />)
}

const Icon = ({
  kind,
  size = 20,
  color,
  ...props
}) => React.cloneElement(
  IconSelector(kind),
  {
    width: isArray(size) ? size[0] : size,
    height: isArray(size) ? size[1] : size,
    ...props,
    style: mergeStyles({ fill: color }, props),
  },
)

Icon.propTypes = {
  /**
   * The name of svg to use
   * Available Icons:
   * arrow-r, check, chevron-u, clock, cutlery, home, plus, refresh, sort-desc, close, user, users
   */
  kind: PropTypes.oneOf(IconList).isRequired,
  /**
   * The Size in pixels
   * @type {string|number|array[width, height]}
   */
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  color: PropTypes.string,
}

export default Icon
