import React from 'react'
import PropTypes from 'prop-types'
import { Heading3Styled } from './style'

const Incentive = ({
  text,
}) => {

  if(!text) return null

  return (
    <Heading3Styled>{text}</Heading3Styled>
  )
}

Incentive.propTypes = {
  content: PropTypes.object,
}

export default Incentive
