import React from 'react'
import PropTypes from 'prop-types'

import {
  cleanHtml,
} from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import {
  Container,
  Heading,
  TopText,
  Grid,
  ItemHeader,
  ItemFooter,
  WrapperStyled,
  TopContentStyled
} from './style'

const IconContentGridV2 = ({
  background,
  showTopHeading,
  topHeading,
  topHeadingUrl,
  heading,
  topText,
  iconContent,
  iconPosition,
  showMarginBottom,
}) => {

  const contentBlock = showMarginBottom ? 'content_block' : ""
  return (
    <WrapperStyled data-testid="wrapper" className={`icon_grid ${contentBlock}`} background={background}>
      <Container>
        <TopContentStyled>
          <Heading>
            {showTopHeading && <span><a href={topHeadingUrl}>{topHeading}</a></span>}
            {heading}
          </Heading>
          <TopText>{topText}</TopText>
        </TopContentStyled>
        <Grid>
          <Row>
            {Object.keys(iconContent).map(i => (
              <Col lg="6" md="12" sm="12" xs="12" key={i}>
                <ItemHeader iconPosition={iconPosition[0]}>
                  <img src={iconContent[i].icon.sizes.large} alt="" />
                  <div>
                    <h3>
                      {iconContent[i].heading}
                    </h3>
                  </div>
                </ItemHeader>
                <ItemFooter
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{ __html: cleanHtml(iconContent[i].content) }}
                />
              </Col>
            ))}
          </Row>
        </Grid>
      </Container>
    </WrapperStyled>
  )
}

IconContentGridV2.propTypes = {
  background: PropTypes.string,
  showTopHeading: PropTypes.bool,
  topHeading: PropTypes.string,
  topHeadingUrl: PropTypes.string,
  heading: PropTypes.string,
  topText: PropTypes.string,
  iconContent: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  iconPosition: PropTypes.object
}

export default IconContentGridV2
