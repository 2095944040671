import React from 'react'
import PropTypes from 'prop-types'

import {
  isEmptyObj,
  isUndefinedOrNull,
  cleanHtml,
} from '../../utils'

import {
  Row,
  Col,
} from '../Grid'

import Heading from '../Heading'
import GuideCard from '../GuideCard'

import {
  Container,
  Tax,
  Content,
  Intro,
  FeaturedImg,
  RelatedArticles,
} from './style'

const GuideArticle = ({
  data,
}) => (
  <Container>
    <Row>
      <Col lg={{ span: 8, offset: 2 }} md="12" sm="12" xs="12">
        <article>
          {!isEmptyObj(data.terms) && (
            <Tax>{data.terms[0].name}</Tax>
          )}
          <Heading text={data.title} subheading={null} margin="0 0 .5rem 0" />
          {!isUndefinedOrNull(data.acf) && (
            <Intro>{data.acf.intro_message}</Intro>
          )}
          {data.media.article_block && (
            <FeaturedImg src={data.media.article_block} alt={data.title} />
          )}
          <Content
            className="article-content"
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: cleanHtml(data.content) }}
          />
        </article>
      </Col>
    </Row>
    {data.acf && data.acf.related_guides && (data.acf.related_guides.length > 0) && (
      <RelatedArticles>
        <h2>Related Articles</h2>
        <Row>
          {data.acf.related_guides.map(i => {
            const {
              title,
              excerpt,
              terms,
              thumbs,
              url,
            } = i
            const post = {
              title,
              excerpt,
              tax: (terms.length > 0 && terms[0].name) || "Beagle Street Guides" ,
              thumb: thumbs.small_block,
              url,
            }
            return (
              <GuideCard post={post} key={i} />
            )
          })}
        </Row>
      </RelatedArticles>
    )}
  </Container>
)

GuideArticle.propTypes = {
  data: PropTypes.object,
  related: PropTypes.object,
}

export default GuideArticle
