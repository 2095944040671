import styled from 'styled-components'
import { typeScale, theme } from '../../theme'

const HeroWrapper = styled.div`
  height: calc(100vh - ${props => props.headerHeight}px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${props => !props.background ? '#DDF0EE' : props.background};
  color: #28334A;
  margin-bottom: 20px;
  z-index: 10; 

  p {
    font-size: 17px;
  }

  @media only screen and (max-width: 1024px) {
    padding: 30px 15px 30px 15px;
    height: auto;
  }

  @media only screen and (max-width: 767px) {
    padding-bottom: 0;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: -10;

  img {
    height: 100%;
  }

  @media only screen and (max-width: 767px) {
    display: none;
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    img {
      height: 80%;
    }
  }
`

const HeroHeading = styled.h1`
  color: ${theme.heroTitle};
  ${typeScale.heroH1};
  text-align: center;
  margin-bottom: 15px;
`

const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 50px;
  max-width: 345px;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 15px;
    font-size: 16px;
    max-width: 300px;
  }
`

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;

  :not(&:last-child) {
    margin-bottom: 10px;
  }

  img {
    margin-right: 15px;
  }

  p {
    width: 100%;
    margin: 0;
    line-height: 1.5rem;
    
    @media only screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }
`

const AffiliateWrapperMobile = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin-bottom: 20px;

   p {
     text-align: center;
     font-size: 16px;
     margin: 0;
   }

   img {
     width: 50%;
   }
    
   @media only screen and (min-width: 1025px) {
     display: none;
   }
`

const AffiliateWrapperDesktop = styled.div`
  display: flex;
  align-items: center;

   p {
     text-align: center;
     margin-right: 15px;
   }

   @media only screen and (max-width: 1024px) {
     display: none;
   }
`

const ArrowWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 15px;

  p {
    margin: 0;
    font-size: 12px;
  }

  button {
    background: none;
    border: none;
    cursor: pointer;
  }

  @media only screen and (max-width: 1024px) {
    display: none;
  }
`

// need to break out the mobile padding (but not make absolute)
const MobileImageWrapper = styled.div`
	position: relative;
	margin-left: -50vw;
	margin-right: -50vw;
	max-width: 100vw;
	width: 100vw;

  img {
    width: 100%;
    height: 100%;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }

`

export {
  HeroWrapper,
  ImageWrapper,
  HeroHeading,
  ListWrapper,
  ListItem,
  AffiliateWrapperMobile,
  AffiliateWrapperDesktop,
  ArrowWrapper,
  MobileImageWrapper,
}