import React from 'react'
import PropTypes from 'prop-types'

import {
  Col,
  Row,
} from '../Grid'

import {
  Container,
  Stat,
  Text,
} from './style'

const StatsRow = ({
  content,
}) => (
  <div className="stats_row content_block">
    <Container>
      <Row>
        {Object.keys(content).map(i => (
          <Col lg="4" md="4" sm="12" xs="12" key={i}>
            <Stat>
              <span>{content[i].stat}</span>
              <Text>{content[i].secondary_text}</Text>
            </Stat>
          </Col>
        ))}
      </Row>
    </Container>
  </div>
)

StatsRow.propTypes = {
  content: PropTypes.object,
}

export default StatsRow
