import React from 'react'
import PropTypes from 'prop-types'

import {
  UlStyled,
  ParagraphStyled,
  ImageStyled,
} from './style'

import { isUndefinedOrNull, removeHtmlTagFromString } from '../../utils'

const RowList = ({
  content,
  screenReaderHeading,
  kind = 'default',
  className,
}) => (
  <>
    {!isUndefinedOrNull(content) && Object.keys(content).length > 0 && (
      <>
        {!isUndefinedOrNull(screenReaderHeading) && screenReaderHeading.trim() && <h2 className="sr-only">{screenReaderHeading}</h2>}
        <UlStyled kind={kind} className={className}>
          {Object.keys(content).map(i => (
            <li key={`${i}_item`}>
              {content[i].icon && <ImageStyled src={content[i].icon} altText={content[i].iconAltText ? content[i].iconAltText.trim() : ''} />}
              {content[i].text && content[i].text.trim() && <ParagraphStyled text={removeHtmlTagFromString(content[i].text, 'p')} />}
            </li>
          ))}
        </UlStyled>
      </>
    )}
  </>
)

RowList.propTypes = {
  content: PropTypes.object,
  screenReaderHeading: PropTypes.string,
  kind: PropTypes.string,
  className: PropTypes.string,
}

export default RowList
