import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { TrustPilotContext } from './contexts'

import ContentBlock from '../components/utils/ContentBlock'
import {
  getRating,
  getReviews,
} from '../actions/trustpilot'

class Reviews extends Component {
  componentDidMount() {
    const { loadRatings, loadReviews } = this.props
    loadRatings()
    loadReviews(1)
  }

  render() {
    const { data, trustpilot, loadReviews } = this.props

    if (data || trustpilot) {
      return (
        <TrustPilotContext.Provider value={{ trustpilot, loadReviews }}>
          <div className="page-margined">
            <ContentBlock content={data.data.acf} />
          </div>
        </TrustPilotContext.Provider>
      )
    }
    return null
  }
}
Reviews.propTypes = {
  data: PropTypes.object,
  loadRatings: PropTypes.func,
  trustpilot: PropTypes.object,
  loadReviews: PropTypes.func,
}

const mapStateToProps = state => ({
  trustpilot: state.trustpilot,
})

const mapDispatchToProps = (dispatch) => ({
  loadRatings: () => dispatch(getRating()),
  loadReviews: (pageNumber) => dispatch(getReviews(pageNumber)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Reviews)
