import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty } from 'lodash'
import {
  Col,
  Row,
} from '../Grid'
import ReCaptcha from '../ReCaptcha'

import {
  Input,
  Select,
  TextArea,
} from '../Form'

import { Button } from '../Button'

import {
  CallbackFormWrapper,
  Heading,
} from './style'

const Form = ({
  onChange = () => { },
  isValidNumber = () => { },
  errorMessage,
  image,
  showPolicyNumber,
  showPleaseSpecify,
  getDates = [],
  getTimes = [],
  showErrorMessage = () => { },
  helpWith,
  handleSubmit = () => { },
  heading,
  disclaimer,
  ppcBool = false,
}) => {
  const submitBtnText = ppcBool ? 'Get a Quote' : 'Request a call back'

  const colType = ppcBool ? { className: 'ppc', xs: '12' } : { xs: '12', md: '8', lg: '6' }

  return (
    <form onSubmit={(e) => handleSubmit(e)} method="POST" id="form">
      <CallbackFormWrapper className={ppcBool ? 'ppcCallback' : undefined}>
        <Row>
          <Col {...colType}>
            <div className="content_container">
              <Heading>{heading}</Heading>
              <Row style={{ marginBottom: '0px' }}>
                {ppcBool ? (
                  <>
                    <Col xs="12" sm="6">
                      <Input inputType="text" identifier="firstName" labelText="First name" onInputChange={onChange} errorMessage={errorMessage.errorfirstName} showError={showErrorMessage('firstName_Field')} />
                    </Col>
                    <Col xs="12" sm="6">
                      <Input inputType="text" identifier="lastName" labelText="Last name" onInputChange={onChange} errorMessage={errorMessage.errorlastName} showError={showErrorMessage('lastName_Field')} />
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xs="12">
                      <Input inputType="text" identifier="firstName" labelText="First name" onInputChange={onChange} errorMessage={errorMessage.errorfirstName} showError={showErrorMessage('firstName_Field')} />
                    </Col>
                    <Col xs="12">
                      <Input inputType="text" identifier="lastName" labelText="Last name" onInputChange={onChange} errorMessage={errorMessage.errorlastName} showError={showErrorMessage('lastName_Field')} />
                    </Col>
                  </>
                )}
                <Col xs="12">
                  <Input inputType="tel" maxLength="11" identifier="phoneNumber" labelText="Phone number" onKeyDown={isValidNumber} onInput={isValidNumber} onInputChange={onChange} errorMessage={errorMessage.errorPhone} showError={showErrorMessage('phoneNumber_Field')} />
                </Col>
                {!ppcBool && (
                  <Col xs="12">
                    <Select label="What can we help you with?" identifier="needHelpWith" items={helpWith} onInputChange={onChange} errorMessage={errorMessage.errorHelpWith} showError={showErrorMessage('needHelpWith')} />
                  </Col>
                )}
                {showPolicyNumber && (
                  <Col xs="12">
                    <Input inputType="number" identifier="policyNumber" labelText="Policy number" onInputChange={onChange} errorMessage={errorMessage.errorPolicy} showError={showErrorMessage('policyNumber_Field')} />
                  </Col>
                )}
                {showPleaseSpecify && (
                  <Col xs="12" sm="6">
                    <TextArea rows="4" identifier="pleaseSpecify" labelText="Please specify (optional)" onInputChange={onChange} />
                  </Col>
                )}
                {!isEmpty(getDates) && (
                  <>
                    <Col xs="12" sm="7" md="7" lg="7">
                      <Select label="Day for call back" identifier="dayForCallback" items={getDates} onInputChange={onChange} errorMessage={errorMessage.errorCallbackDay} showError={showErrorMessage('dayForCallback')} />
                    </Col>
                    <Col xs="12" sm="5" md="5" lg="5">
                      <Select label="Preferred time" identifier="preferredTime" items={getTimes} onInputChange={onChange} errorMessage={errorMessage.errorPreferredTime} showError={showErrorMessage('preferredTime')} />
                    </Col>
                  </>
                )}
              </Row>
            </div>
            {ppcBool && (
              <p id="disclaimer">{disclaimer}</p>
            )}
          </Col>
          {!ppcBool && (<Col xs="12" md="4" lg="6" className="image_container" style={{ backgroundImage: `url(${image})` }} />)}
        </Row>
        <Row>
          <Col md="12">
            <div className="form_footer">
              <ReCaptcha testid="recaptcha" reCaptchaSiteKey={process.env.REACT_APP_CAPTCHA_SITE_KEY} errorMessage={errorMessage.errorReCaptcha} showError={showErrorMessage('isCaptchaValid')} />
              <Button type="submit" kind="purple_solid" size="medium">{submitBtnText}</Button>
            </div>
          </Col>
        </Row>
      </CallbackFormWrapper>
    </form>
  )
}

Form.propTypes = {
  onChange: PropTypes.func,
  isValidNumber: PropTypes.func,
  errorMessage: PropTypes.object,
  image: PropTypes.string,
  showPolicyNumber: PropTypes.bool,
  showPleaseSpecify: PropTypes.bool,
  getDates: PropTypes.array,
  getTimes: PropTypes.array,
  showErrorMessage: PropTypes.func,
  helpWith: PropTypes.array,
  handleSubmit: PropTypes.func,
  heading: PropTypes.string,
  disclaimer: PropTypes.string,
  ppcBool: PropTypes.bool,
}

export default Form
