import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { TemplateContext } from '../../containers/contexts'

import {
  isEmptyObj,
  isUndefinedOrNull,
  updatePageAnalytics,
  endRoute,
  createSlug,
} from '../../utils'

import {
  pageTitleSuffix,
} from '../../common/const'

import Home from '../../containers/Home'
import Blog from '../../containers/Blog'
import Category from '../../containers/Category'
import Guides from '../../containers/Guides'
import GuidesArchive from '../../containers/GuidesArchive'
import Learn from '../../containers/Learn'
import LearnArchive from '../../containers/LearnArchive'
import LearnCategories from '../../containers/LearnCategories'
import Page from '../../containers/Page'
import Post from '../../containers/Post'
import Reviews from '../../containers/Reviews'
import Search from '../../containers/Search'

const templates = {
  Blog,
  Category,
  GuidesArchive,
  Guides,
  Home,
  Learn,
  LearnArchive,
  LearnCategories,
  Page,
  Post,
  Reviews,
  Search,
}

class LoadTemplate extends Component {
  state = {
    container: undefined,
    context: undefined,
    templateData: {},
    pageTitle: '',
    pageDesc: '',
    currentPage: undefined,
  }

  static getDerivedStateFromProps(props) {
    const {
      container,
      pageData,
      archiveData,
      taxonomyData,
      context,
      slug,
      route,
      analytics,
    } = props
    // document.body.classList.add(container.toLowerCase())
    let templateData
    let pageTitle
    let pageDesc
    let index
    // eslint-disable-next-line camelcase
    const data = (createSlug(route).split('/').length > 1) ? pageData[endRoute(slug)] : pageData[slug]
    if (context === 'post' && !isEmptyObj(data)) {
      templateData = data
      if (!isUndefinedOrNull(data.yoast)) {
        pageTitle = data.yoast.yoast_wpseo_title || `${data.title} | ${pageTitleSuffix}`
        pageDesc = data.yoast.yoast_wpseo_metadesc || ''
        index = data.yoast['yoast_wpseo_meta-robots-noindex'] === '1' ? 'noindex' : 'index'
      } else if (!isUndefinedOrNull(data.yoast_head_json)) {
        pageTitle = data.yoast_head_json.title || `${data.title} | ${pageTitleSuffix}`
        pageDesc = data.yoast_head_json.description || ''
        index = data.yoast_head_json.robots.index
      } else {
        pageTitle = `${data.name} | ${pageTitleSuffix}`
        pageDesc = ''
      }
    } else if (context === 'taxonomy' && !isEmptyObj(taxonomyData.data)) {
      templateData = taxonomyData
      if (!isUndefinedOrNull(taxonomyData.data.yoast)) {
        pageTitle = taxonomyData.data.yoast.wpseo_title || `${taxonomyData.data.name} | ${pageTitleSuffix}`
        pageDesc = taxonomyData.data.yoast.wpseo_metadesc || ''
      } else {
        pageTitle = `${taxonomyData.data.name} | ${pageTitleSuffix}`
        pageDesc = ''
      }
    } else if (context === 'archive' && !isEmptyObj(data)) {
      templateData = data
      if (!isUndefinedOrNull(data.acf)) {
        const acfTitle = Object.prototype.hasOwnProperty.call(data.acf, 'page_title')
        const acfDesc = Object.prototype.hasOwnProperty.call(data.acf, 'post_excerpt')

        pageTitle = acfTitle ? `${data.acf.page_title} | ${pageTitleSuffix}` : `${data.name} | ${pageTitleSuffix}`
        pageDesc = acfDesc ? data.acf.page_description || '' : ''
      } else {
        pageTitle = `${data.name} | ${pageTitleSuffix}`
        pageDesc = ''
      }
    }

    // Exception (static page conflicting with a route "search")
    if (container === 'Search') {
      templateData = { page: 'Search' }
      pageTitle = 'Search'
      pageDesc = ''
    }

    return {
      container,
      pageData,
      archiveData,
      taxonomyData,
      templateData,
      pageTitle,
      pageDesc,
      index,
      context,
      route,
      analytics,
    }
  }

  componentDidMount() {
    const { analytics, route } = this.props
    const { currentPage } = this.state
    this.setState({
      currentPage: route,
    }, () => {
      if (!isUndefinedOrNull(analytics.route) && isUndefinedOrNull(currentPage)) {
        updatePageAnalytics()
      }
    })
  }

  componentDidUpdate() {
    const { route, updateAnalytics, analytics } = this.props
    if (route !== analytics.route) {
      updateAnalytics({
        route,
      })
    }
  }

  render() {
    let Meta = () => null
    const {
      templateData,
      pageTitle,
      pageDesc,
      container,
      context,
      index
    } = this.state

    const pageKey = Object.keys(this.props.pageData)[0]
    const { [pageKey]: { yoast, permalink } } = this.props.pageData

    let canonicalUrlFallback = ""

    if (!yoast || !permalink) {
      canonicalUrlFallback = `${process.env.REACT_APP_DISCOVERY_SITE_URL}${this.props.routeData.all[0].route}`
    }

    const Template = templates[container]
    if (!Template) {
      return <Redirect to="/not-found" />
    }
    
    if (!isUndefinedOrNull(pageTitle) && !isUndefinedOrNull(pageDesc)) {
      Meta = () => (
        <Helmet>
          <title>{pageTitle}</title>
          <meta name="description" content={pageDesc} />
          <meta name="robots" content={index} />
          <link rel="canonical" href={yoast?.yoast_wpseo_canonical || permalink || canonicalUrlFallback} />
        </Helmet>
      )
    }

    let display
    if (!isUndefinedOrNull(templateData)) {
      const { slug } = templateData
      display = (
        <TemplateContext.Provider value={{ template: slug, layout: templateData }}>
          <Template data={templateData} />
        </TemplateContext.Provider>
      )
    }

    return (
      <div className="template-wrap" data-context={context}>
        <Meta />
        {display}
      </div>
    )
  }
}

LoadTemplate.propTypes = {
  route: PropTypes.string,
  updateAnalytics: PropTypes.func,
  analytics: PropTypes.object,
  container: PropTypes.string,
  pageData: PropTypes.object,
  archiveData: PropTypes.object,
  taxonomyData: PropTypes.object,
  context: PropTypes.string,
  slug: PropTypes.string,
}

const mapStateToProps = state => ({
  pageData: state.api.data,
  routeData: state.api.routes,
  analytics: state.analytics,
  postData: state.postType,
})

const mapDispatchToProps = dispatch => ({
  updateAnalytics: (payload) => dispatch({
    type: 'UPDATE_ANALYTICS',
    payload,
  }),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoadTemplate)
