import {
  GET_CALLBACK_DATA_REQUEST,
  GET_CALLBACK_DATA_SUCCESS,
  GET_CALLBACK_DATA_ERROR,
  // POST_CALLBACK_DATA_REQUEST,
  // POST_CALLBACK_DATA_SUCCESS,
  // POST_CALLBACK_DATA_ERROR,
} from '../actions/callback'

const callback = (
  state = {
    loading: undefined, // Bool
  },
  action,
) => {
  switch (action.type) {
    case GET_CALLBACK_DATA_REQUEST:
      return { ...state, loading: true }
    case GET_CALLBACK_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case GET_CALLBACK_DATA_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

// const postCallback = (
//   state = {
//     loading: undefined, // Bool
//   },
//   action,
// ) => {
//   switch (action.type) {
//     case POST_CALLBACK_DATA_REQUEST:
//       return { ...state, loading: true }
//     case POST_CALLBACK_DATA_SUCCESS:
//       return { ...state, loading: false, data: action.payload }
//     case POST_CALLBACK_DATA_ERROR:
//       return { ...state, error: action.payload }
//     default:
//       return state
//   }
// }

export default callback
