import styled from 'styled-components'
import { query } from '../../theme'

const TrustPilotWrapper = styled.div`
  display: block;
  margin-top: 20px;
  max-width: 208px;
  text-align: center;

  ${query.combine(['extraSmall', 'small'], `
    margin: 0 auto; 
    margin-top: 20px;
  `)}
`

const TrustPilotStarsWrapper = styled.span`
  display: inline-block;

  ${query.combine(['small', 'extraSmall'], `
    margin: 0 auto; 
    display: block;
    text-align: center; 
  `)}
  
`

const BrandImage = styled.img`
  display: block;
  width: 150px;
  height: 29px;
  
  ${query.combine(['small', 'extraSmall'], `
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 120px;
    height: 23.2px;

    + img {
      margin-bottom: 40px;
      margin-left: auto;
    }
  `)}

  + img {
    width: 95px;
    margin-left: calc((150px - 95px) / 2);

    ${query.combine(['small', 'extraSmall'], `
      margin-left: auto;
  `)}
  }
`

export {
  BrandImage,
  TrustPilotStarsWrapper,
  TrustPilotWrapper,
}
