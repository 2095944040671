import axios from 'axios'
import { isEmptyArray } from '../utils'

// Unique tag to make sure there's no overlap of tags
export const CALL_API = '@@CALL_API'

// Format multiple types into one type
function formatActionTypes(types) {
  let [requestType, successType, errorType] = types

  if (typeof requestType === 'string' || typeof requestType === 'symbol') {
    requestType = { type: requestType }
  }
  if (typeof successType === 'string' || typeof successType === 'symbol') {
    successType = { type: successType }
  }
  if (typeof errorType === 'string' || typeof errorType === 'symbol') {
    errorType = { type: errorType }
  }

  return [requestType, successType, errorType]
}

export function callAPIMiddleware({ dispatch }) {
  return next => (action) => {
    if (!Object.prototype.hasOwnProperty.call(action, CALL_API)) {
      return next(action)
    }

    const callOption = action[CALL_API]
    const {
      url,
      method,
      headers,
      body,
      types,
    } = callOption
    const [requestType, successType, errorType] = formatActionTypes(types)
    dispatch(requestType) // Request
    return axios.request({
      method,
      url,
      headers,
      data: body,
    }).then((resp) => {
      const { status, data } = resp
      if (status === 200 || status === 304) {
        if (!isEmptyArray(data)) {
          dispatch({
            ...successType,
            payload: { ...data },
            key: callOption.key || null,
            headers: resp.headers,
          }) // Success
        } else {
          dispatch({ ...errorType, payload: data }) // Error
        }
      } else {
        dispatch({ ...errorType, payload: data }) // Error
      }
    }).catch(error => {
      dispatch({ ...errorType, payload: error }) // Error
    })
  }
}
