import React from 'react'
import PropTypes from 'prop-types'

import ACFDynamic from '../../ACFDynamic'

const ContentBlock = ({
  content,
}) => {
  const { PageData } = content
  return (
    Object.keys(PageData).map(i => {
      const key = `${PageData[i].acf_fc_layout}-${i}`
      return (
        <ACFDynamic
          key={key}
          data={PageData[i]}
        />
      )
    })
  )
}

ContentBlock.propTypes = {
  content: PropTypes.object,
}

export default ContentBlock
