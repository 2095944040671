import styled from 'styled-components'
import { container, query } from '../../theme'

const Wrapper = styled.div`
  ${container}
`

const Accordion = styled.div`
  .gridColSpacing {
    &:nth-child(odd) {
      margin-bottom: 40px;
    }
    ${query.combine(['extraSmall', 'small'], `
      margin-bottom: 35px!important;
    `)}  
  }
  padding: 4rem 0 2.5rem 0;
  ${query.combine(['extraSmall', 'small'], `
      padding-top: 2.5rem;
      padding-bottom: 1rem;
  `)}   
  h2 {
    color: #28334A;
    font-size: 1.4em;
    margin: 0;
    img {
      vertical-align: middle;
      width: 50px;
      height: 50px;
      margin-right: .3rem;
    }
  }
  ul {
    list-style: none;
    margin: 1.5rem 0;
    padding: 0;
    &.posts {
      display: none;
      margin: 0 0 1.5rem 2.5rem;
      li {
        margin-top: .8rem;
        font-size: .9em;
        a {
          color: #373737;
          text-decoration: none;
          &:hover {
            color: #000;
            text-decoration: underline;
          }
        }
      }
    }
    .cat {
      margin-top: .6rem;
      line-height: 27px;
      button {
        color: #373737;
        font-size: 1em;
        font-family: 'Montserrat', sans-serif;
        padding: 0;
        background: transparent;
        margin: 0;
        border: 0;
        cursor: pointer;
        &:before {
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"/></svg>');
          width: 15px;
          height: 15px;
          content: '';
          color: #373737;
          display:inline-block;
          margin: 0 .3rem 0 0;
          position: relative;
          top: 1px;
        }
      }
      &.active {
        button {
          color: #00C2A2;
          &:before {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"/></svg>');
          }
        }
        ul.posts {
          display: block;
        }
      }
    }
  }
`

const HeadingWithIcon = styled.h2`
  display: flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }
`

export {
  Wrapper,
  Accordion,
  HeadingWithIcon,
}
