import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import ReCaptchaContext from '../../containers/contexts/ReCaptchaContext'
import { ErrorMessage, RecaptchaOutter, RecaptchaInner } from './style'

const ReCaptcha = (
  {
    reCaptchaSiteKey,
    showError,
    errorMessage,
    testid,
  },
) => {
  const contextValue = useContext(ReCaptchaContext)
  const { errorCallback, recapchacallback } = contextValue
  useEffect(() => {
    const script = document.createElement('script')

    script.src = 'https://www.google.com/recaptcha/api.js'
    script.async = true
    script.defer = true

    document.body.appendChild(script)

    // If any error occurs?
    if (typeof window === 'object') {
      window.errorCallback = () => {
        axios.post('/api/error', {
          text: 'Google reCaptcha has an error.',
        }).catch((error) => {
          errorCallback(error)
        })
        errorCallback()
      }
    }

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <RecaptchaOutter id="formRecaptcha" data-testid={testid}>
      <RecaptchaInner
        error={showError}
        className="g-recaptcha"
        data-sitekey={reCaptchaSiteKey}
        data-callback={recapchacallback}
        render="explicit"
        data-error-callback="errorCallback"
      />
      {showError && (
        <ErrorMessage><img src="https://cdn.bgllife.co.uk/beaglestreet/icons/ic-baseline-error.svg" alt="Error Message" />{errorMessage}</ErrorMessage>
      )}
    </RecaptchaOutter>
  )
}

ReCaptcha.propTypes = {
  reCaptchaSiteKey: PropTypes.string,
  errorMessage: PropTypes.string,
  showError: PropTypes.bool,
  testid: PropTypes.string,
}

export default ReCaptcha
