import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  cleanHtml,
  dataLayerPush,
} from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import {
  Container,
  Heading,
  TabHeaders,
  TabContent,
} from './style'

class IconTabs extends Component {
  toggleTab = (e) => {
    e.preventDefault()
    const target = e.currentTarget
    const dataTab = target.getAttribute('data-tab')
    const tab = document.getElementById(dataTab)
    const active = document.querySelector('.tab.active')

    const parent = target.parentNode
    const parentNodeType = parent.nodeName

    // Remove current active class on tab name
    const activeTabName = document.querySelector('.tabNames .active')

    // Add active class to tab name
    if (parentNodeType === 'LI') {
      activeTabName.classList.toggle('active')
      parent.classList.toggle('active')
    }

    // Hide current tab
    active.classList.toggle('active')

    // Open Target tab
    tab.classList.toggle('active')

    const { currentTarget: { innerText } } = e
    dataLayerPush({
      event: 'ButtonClick',
      buttonName: `${innerText}`.trim(),
    })
  }

  render() {
    const {
      showheading,
      background,
      heading,
      content,
      anchorid,
    } = this.props

    const pad = background ? '3.5rem 0' : '0'

    return (
      <div id={anchorid} className="icon_tabs content_block" style={{ backgroundColor: background || '#fff', padding: pad }}>
        <Container>
          {showheading && (
            <Heading>{heading}</Heading>
          )}
          <Row>
            <Col lg="5" md="12" sm="12" xs="12">
              <TabHeaders>
                <ul role="tablist" aria-orientation="horizontal" className="tabNames">
                  {Object.keys(content).map(i => {
                    const ariaControls = `tab-${i}`
                    const selected = (i > 0 ? 'false' : 'true')
                    const className = (i > 0 ? '' : 'active')
                    return (
                      <li key={i} className={className}>
                        <button
                          type="button"
                          onClick={(e) => this.toggleTab(e)}
                          role="tab"
                          aria-selected={selected}
                          aria-controls={ariaControls}
                          data-tab={ariaControls}
                          id={`tab_${i}`}
                        >
                          <img src={content[i].icon} alt={content[i].title} width="40" />
                          <span>{content[i].title}</span>
                        </button>
                      </li>
                    )
                  })}
                </ul>
              </TabHeaders>
            </Col>
            <Col lg="7" md="12" sm="12" xs="12">
              <TabContent>
                {Object.keys(content).map(i => {
                  const ariaControls = `tab-${i}`
                  const selected = (i > 0 ? 'false' : 'true')
                  const classes = ['tab']
                  if (parseFloat(i) === parseFloat(0)) classes.push('active')
                  return (
                    <div
                      role="tabpanel"
                      aria-labelledby={ariaControls}
                      key={i}
                      className={classes.join(' ')}
                      id={ariaControls}
                    >
                      <h3>
                        <button
                          type="button"
                          onClick={(e) => this.toggleTab(e)}
                          role="tab"
                          aria-selected={selected}
                          aria-controls={ariaControls}
                          data-tab={ariaControls}
                        >
                          <div className="flexContainer">
                            <img src={content[i].icon} alt={content[i].title} width="30" />
                            <span>{content[i].title}</span>
                          </div>
                        </button>
                      </h3>
                      <div
                        className="tab_content animated fadeIn"
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{ __html: cleanHtml(content[i].text) }}
                      />
                    </div>
                  )
                })}
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

IconTabs.propTypes = {
  showheading: PropTypes.bool,
  background: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.object,
  anchorid: PropTypes.string,
}

export default IconTabs
