export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  image: {
    mappedProp: 'image',
  },
  mobile_image: {
    mappedProp: 'mobileimage',
  },
  text: {
    mappedProp: 'text',
  },
}
