import axios from 'axios'
import API_MAP, { API_KEYS } from '../services/endpoints'

export const GET_REVIEW_REQUEST = 'GET_REVIEW_REQUEST'
export const GET_REVIEW_SUCCESS = 'GET_REVIEW_SUCCESS'
export const GET_REVIEW_FAILURE = 'GET_REVIEW_FAILURE'

/**
 *
 * getRating - 
 * firstly retrieve data from TP endpoint, then retrieve the rating string from another TP endpoint.
 *
 */
export const getRating = () => async dispatch => {
  dispatch(({ type: GET_REVIEW_REQUEST }))

  try {
    let data = await axios.get(API_MAP.trustPilot.rating, { params: { apikey: API_KEYS.trustPilot } })
    const { data: { score, numberOfReviews, links } } = data

    try {
      let rating = await axios.get(links[2].href, { params: { apikey: API_KEYS.trustPilot } })
      const { data: { string } } = rating

      dispatch(({
        type: GET_REVIEW_SUCCESS,
        payload: {
          score,
          numberOfReviews,
          starsText: string,
        },
      }))

    } catch (e) {
      console.log(`Error getting rating string in trustpilot getRating: ${e}`)
      dispatch({ type: GET_REVIEW_FAILURE, payload: e.message })
    }

  } catch (e) {
    console.log(`Error getRating action: ${e}`)
    dispatch({ type: GET_REVIEW_FAILURE, payload: e.message })
  }
}


/**
 *
 * getReviews -
 * retrieve reviews only from TP endpoint.
 *
 */
export const getReviews = (page, perPage = 30) => async dispatch => {
  dispatch(({ type: GET_REVIEW_REQUEST }))

  try {
    let data = await axios.get(`${API_MAP.trustPilot.reviews}`, { params: { apikey: API_KEYS.trustPilot, page, perPage } })
    const { data: { reviews } } = data

    dispatch(({
      type: GET_REVIEW_SUCCESS,
      payload: {
        reviews,
      },
    }))
  } catch (e) {
    console.log(`Error getReviews action: ${e}`)
    dispatch({ type: GET_REVIEW_FAILURE, payload: e.message })
  }
}
