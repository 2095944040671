import {
  UPDATE_ANALYTICS,
} from '../actions/analytics'

const initState = {
  route: undefined,
}

const analytics = (
  state = initState,
  action,
) => {
  switch (action.type) {
    case UPDATE_ANALYTICS:
      return { ...state, ...action.payload }
    default:
      return state
  }
}

export default analytics
