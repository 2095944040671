import React from 'react'
import PropTypes from 'prop-types'
import { removeHtmlTagFromString } from '../../utils'

import {
  cleanHtml,
  dataLayerPush,
} from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import {
  Button,
} from '../Button'

import {
  DataLayerLink,
} from '../DataLayerLink'

import Image from '../Image'

import {
  Container,
  LR,
  Posts,
  Buttons,
  TextBody,
  EyebrowTextStyled,
  ListStyled,
  MainHeadingL2Styled,
  MainHeadingL3Styled,
  EyebrowH2Styled,
  ListIcon,
} from './style'

const Feature = ({
  background,
  image,
  imageAltText,
  imagepos,
  addMarginBottom,
  showtoptext,
  toptext,
  toptextlink,
  mainheading,
  text,
  iconcontent,
  iconPosition,
  posts,
  buttons,
  anchorid,
}) => {
  const toggleAccordion = (e) => {
    e.preventDefault()
    const { currentTarget: { parentNode, innerText } } = e
    parentNode.classList.toggle('active')
    dataLayerPush({
      event: 'questionClick',
      questionName: `${innerText}`.trim(),
    })
  }

  const pad = background ? '3.5rem 0' : '0'
  const altText = imageAltText?.trim() || ""

  const contentClassName = addMarginBottom ? 'content_block' : ''

  return (
    <div id={anchorid} className={`lr_content ${contentClassName}`}  style={{ backgroundColor: background || '#fff', padding: pad }}>
      <Container>
        <LR>
          <Row>
            {imagepos === 'left' && (
              <Col lg="6" sm="12" xs="12" className="image_col left">
                <Image src={image.url} responsive altText={altText} />
              </Col>
            )}
            <Col lg="6" sm="12" xs="12">
              {showtoptext ? (
                  <>
                    <EyebrowH2Styled>
                      <EyebrowTextStyled href={toptextlink}>
                        <span>{toptext}</span>
                      </EyebrowTextStyled>
                    </EyebrowH2Styled>
                    <MainHeadingL3Styled>{mainheading}</MainHeadingL3Styled>
                  </>
                ) : (
                  <MainHeadingL2Styled>{mainheading}</MainHeadingL2Styled>
                )
              }
              <TextBody dangerouslySetInnerHTML={{__html: cleanHtml(text) }} />
              {iconcontent && (
                <ListStyled>
                  {Object.keys(iconcontent).map(i => (
                    <li key={i}>
                      <ListIcon src={iconcontent[i].icon.sizes.large} alt="" position={iconPosition} />
                      <span
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{ __html: cleanHtml(removeHtmlTagFromString(iconcontent[i].text, 'p')) }}
                      />
                    </li>
                  ))}
                </ListStyled>
              )}
              {posts && (
                <Posts>
                  <ul>
                    {Object.keys(posts).map(i => {
                      const url = `/${posts[i].post_type}/${posts[i].post_name}`
                      return (
                        <li key={i}>
                          <a href={posts[i].post_name} onClick={(e) => toggleAccordion(e)}>
                            <span>{posts[i].post_title}</span>
                          </a>
                          <span
                            className="hidden_content"
                            // eslint-disable-next-line
                            dangerouslySetInnerHTML={{ __html: cleanHtml(posts[i].post_excerpt) }}
                          />
                          <DataLayerLink
                            eventName="textClick"
                            eventKey="textName"
                            className="hidden_content"
                            href={url}
                            ariaLabel={`Continue reading ${posts[i].post_title}`}
                          >
                            Continue reading
                          </DataLayerLink>
                        </li>
                      )
                    })}
                  </ul>
                </Posts>
              )}
              {buttons && (
                <Buttons>
                  {Object.keys(buttons).map(i => (
                    <Button href={buttons[i].url} to={buttons[i].url} as="a" kind={buttons[i].style} key={i}>{
                      buttons[i].text
                    }
                      <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L6 6L1 11" stroke="#00C2A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                      </svg>
                    </Button>
                  ))}
                </Buttons>
              )}
            </Col>
            {imagepos === 'right' && (
              <Col lg="6" sm="12" xs="12" className="image_col right">
                <Image src={image.url} responsive altText={altText} />
              </Col>
            )}
          </Row>
        </LR>
      </Container>
    </div>
  )
}


Feature.propTypes = {
  background: PropTypes.string,
  anchorid: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
  imageAltText: PropTypes.string,
  imagepos: PropTypes.oneOf(['left', 'right']),
  addMarginBottom: PropTypes.bool,
  toptext: PropTypes.string,
  showtoptext: PropTypes.bool,
  toptextlink: PropTypes.string,
  mainheading: PropTypes.string,
  text: PropTypes.string,
  iconcontent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  iconPosition: PropTypes.object,
  posts: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  buttons: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
}

export default Feature
