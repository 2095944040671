export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  text: {
    mappedProp: 'text',
  },
  heading: {
    mappedProp: 'heading',
  },
  sub_heading: {
    mappedProp: 'subheading',
  },
  image: {
    mappedProp: 'image',
  },
  show_button: {
    mappedProp: 'showbutton',
  },
  affiliate_image: {
    mappedProp: 'affiliateImage',
  },
  small_title: {
    mappedProp: 'smallTitle',
  },
  show_trustpilot_rating: {
    mappedProp: 'showTrustpilotRating',
  },
  highlights_list: {
    mappedProp: 'highlightsList',
  },
}
