import { CALL_API } from '../middleware/callAPIMiddleware'
import API_MAP from '../services/endpoints'

const GET_LEARN_ACC_REQUEST = 'GET_LEARN_ACC_REQUEST'
const GET_LEARN_ACC_SUCCESS = 'GET_LEARN_ACC_SUCCESS'
const GET_LEARN_ACC_ERROR = 'GET_LEARN_ACC_ERROR'

const { genericEPBS } = API_MAP

export const getlearnAccordion = () => ({
  [CALL_API]: {
    url: `${genericEPBS}learn/accordion`,
    method: 'GET',
    types: [GET_LEARN_ACC_REQUEST, GET_LEARN_ACC_SUCCESS, GET_LEARN_ACC_ERROR],
  },
  type: GET_LEARN_ACC_REQUEST,
})

export {
  GET_LEARN_ACC_REQUEST,
  GET_LEARN_ACC_SUCCESS,
  GET_LEARN_ACC_ERROR,
}
