import React from 'react'
import PropTypes from 'prop-types'

import Button from '../Button'
import Image from '../Image'

import {
  Container,
  HeadingTwoStyled,
  HeadingThreeStyled,
  Paragraph1Styled,
  RowStyled,
  ImageColStyled,
  ContentColStyled,
  ContentWrapper,
  RowListStyled,
} from './style'
import { removeHtmlTagFromString } from '../../utils'

const SplitWithImage = ({
  image,
  imageAltText,
  mainHeading,
  mainHeadingUrl,
  subheading,
  bodyText,
  list,
  buttonText,
  buttonUrl,
  buttonKind,
  buttonAccessibleText,
}) => {

  // Do not render if required data is missing
  if (
    !image ||
    !mainHeading ||
    !mainHeadingUrl ||
    !subheading ||
    !bodyText ||
    !list ||
    !buttonText ||
    !buttonUrl ||
    !buttonKind
  ) {
    return null
  }

  const renderButton = (
    buttonKind && buttonText && buttonText.trim() && buttonUrl && buttonUrl.trim()
  )

  return (
    <Container>
      <RowStyled>
        <ContentColStyled xs="12" sm="12" md="12" lg="6">
          <ContentWrapper>
            {mainHeading.trim() &&
              <HeadingTwoStyled>
                <a href="http://www.google.com">{mainHeading}</a>
              </HeadingTwoStyled>
            }
            {subheading.trim() &&
              <HeadingThreeStyled>{subheading}</HeadingThreeStyled>
            }
            {bodyText.trim() &&
              <Paragraph1Styled text={removeHtmlTagFromString(bodyText, 'p')} />
            }
            {/* RowList component has built in safeguards,
                additional ones are not needed */}
            <RowListStyled
              content={list}
              kind="vertical"
            />

            {renderButton &&
              <Button
                as="a"
                kind={buttonKind}
                ariaLabel={(buttonAccessibleText && buttonAccessibleText.trim()) && buttonAccessibleText}
                href={buttonUrl}
              >
                {buttonText}
              </Button>
            }
          </ContentWrapper>
        </ContentColStyled>
        {/* Image is in the second colum to maintain a better HTML structure */}
        {/* On desktop, image appears on the left colum due to CSS styles */}
        <ImageColStyled xs="12" sm="12" md="12" lg="6">
          {image.trim() &&
            <Image
              dataTestId="main-illustration"
              src={image}
              altText={imageAltText && imageAltText.trim() && imageAltText}
            />
          }
        </ImageColStyled>
      </RowStyled>
    </Container>
  )

}

SplitWithImage.propTypes = {
  image: PropTypes.string,
  imageAltText: PropTypes.string,
  mainHeading: PropTypes.string,
  mainHeadingUrl: PropTypes.string,
  subheading: PropTypes.string,
  bodyText: PropTypes.string,
  list: PropTypes.object,
  buttonText: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonKind: PropTypes.string,
  buttonAccessibleText: PropTypes.string,
}

export default SplitWithImage
