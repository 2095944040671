import styled, { css } from 'styled-components'
import { container, query } from '../../theme'
import Text from '../Text'

const Container = styled.div`
  ${container}
`

const ListStyled = styled.ul`
  border-top: 1px solid #B4D7E8;
  border-bottom: 1px solid #B4D7E8;
  margin-bottom: 17px;
  padding: 11px 0;
  li {
    
    list-style: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 27px;
    color: #1D374E;
    margin-bottom: 16px;
    text-decoration: underline;

    a {
      display: inline-flex;

      span {
        max-width: 430px;
      }
      &:hover{
        font-weight: 600;
      }
      &:-webkit-any-link{
        color: #1D374E;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }
 
  }
  ${query.combine(['extraSmall', 'small'], `
    margin-bottom: 21px;
  `)}
`

const EyebrowH2Styled = styled.h2`
  margin: 0;
  line-height: 0;
  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
  `)}
`

const EyebrowTextStyled = styled.a`
  display: inline-block;
  text-align: left;
  text-transform: uppercase;
  font-size: .5em;
  margin-bottom: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1em;
  color: #1D374E;
  text-decoration: none;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  &:focus {
    outline-offset: 2px;
  }

  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    margin-bottom: 15px;
  `)}
  span {
    display: inline-block;
    background-color: #0AC4A9;
    padding: 3px 7px;
    ${query.combine(['extraSmall', 'small'], `
      text-align: left;
      display: inline-block;
      padding: 3px 5px;
    `)}
  }
`

const ParagraphStyled = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #344B60;
  margin-bottom: 30px;
  margin-top: 16px;

  ${query.combine(['extraSmall', 'small'], `
    text-align: left;
    margin-bottom: 35px;
    line-height: 1.4;
  `)}
`

const TextStyled = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1D374E;
`

const LR = styled.div`
  .image_col {
    ${query.combine(['extraSmall', 'small', 'medium'], `
        text-align: center;
        img {
          margin-top: 1rem;
        }
    `)}
    &.left {
      ${query.combine(['extraSmall', 'small', 'medium'], `
        order: 2;
      `)}
    }
    &.right {
      text-align: right;
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
    }
  }
`


const mainHeadingStyles = css`
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  color: #1D374E;
  margin-top: 0px;
  margin-bottom: 15px;
  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
    font-size: 20px;
    margin-bottom: 19px;
  `)}
`

const MainHeadingL2Styled = styled.h2`
  ${mainHeadingStyles}
`

const MainHeadingL3Styled = styled.h3`
  ${mainHeadingStyles}
`

const ListIcon = styled.img`
  height: 100%;
  width: auto;
  margin-right: ${props => props.position?.right ? props.position.right : 14}px;
  margin-top: ${props => props.position?.top ? `${props.position.top}px` : 0};
`

export {
  Container,
  LR,
  ParagraphStyled,
  EyebrowH2Styled,
  EyebrowTextStyled,
  TextStyled,
  ListStyled,
  MainHeadingL2Styled,
  MainHeadingL3Styled,
  ListIcon,
}
