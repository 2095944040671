import React from 'react'
import PropTypes from 'prop-types'
import { isUndefinedOrNull } from '../../utils'

import { SelectField, Label, ErrorMessage } from './style'

const Select = ({
  label,
  identifier,
  items,
  onInputChange,
  showError,
  errorMessage,
}) => {
  const handleChange = (e) => {
    onInputChange(e.target.value, e.target.name)
  }

  return (
    <div>
      <Label htmlFor={identifier}>{label}</Label>
      <SelectField
        id={identifier}
        name={identifier}
        defaultValue="selected"
        onChange={e => handleChange(e)}
        className={showError ? 'errorInput' : null}
      >
        <option disabled="disabled" value="selected">Please select</option>
        {(items) && (
          items.map(i => {
            if (!isUndefinedOrNull(i)) {
              return <option key={i.key} value={i.key}>{i.value}</option>
            }
            return null
          })
        )}
      </SelectField>
      {showError && (
        <ErrorMessage>
          <img src="https://cdn.bgllife.co.uk/beaglestreet/icons/ic-baseline-error.svg" alt="error" />
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  )
}

Select.propTypes = {
  label: PropTypes.string,
  identifier: PropTypes.string,
  items: PropTypes.array,
  onInputChange: PropTypes.func,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
}

Select.defaultProps = {
  items: [],
}

export default Select
