import React, { useState } from 'react'
import { TrustPilotContext } from '../../containers/contexts'
import { isEmptyObj } from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import { ReviewItem } from '../ReviewItem'
import { Button } from '../Button'

import {
  Container,
  Banner,
  List,
  LinkButton,
} from './style'

const TrustPilotReview = () => {
  const [currentReviewIndex, setCurrentReviewIndex] = useState(6)

  // const reviewsIndex = (loadReviews) => {
  const reviewsIndex = () => {
    setCurrentReviewIndex(currentReviewIndex + 6)
  }

  // {({ trustpilot: { reviews }, loadReviews }) => {
  return (
    <TrustPilotContext.Consumer>
      {(({ trustpilot: { reviews } }) => {
        if (!isEmptyObj(reviews)) {
          return (
            <Banner>
              <Container>
                <Row>
                  <List data-testid="reviews-list">
                    {(Object.values(reviews).filter(reviewObj => reviewObj.stars > 3 ).slice(0, currentReviewIndex).map((review) => (
                      <Col style={{ display: 'flex' }} lg="4" md="6" sm="12" xs="12" key={review.id}>
                        <ReviewItem review={review} />
                      </Col>
                    ))
                    )}
                  </List>
                  {((currentReviewIndex <= (Object.keys(reviews).length))) && (
                    <div style={{ margin: '0 auto', textAlign: 'center', paddingBottom: '20px' }}>
                      {currentReviewIndex !== 30 ? <Button data-testid="see-more-btn" type="button" kind="navy_ghost" size="small" onClick={() => reviewsIndex()}>More Reviews</Button> : <LinkButton data-testid="see-more-trustpilot-btn" kind="navy_ghost" href="https://www.trustpilot.com/review/beaglestreet.com" target="_blank" rel="noopener noreferrer">See more reviews on Trustpilot</LinkButton>}
                    </div>
                  )}
                </Row>
              </Container>
            </Banner>
          )
        }
        return null
      })}
    </TrustPilotContext.Consumer>
  )
}

export default TrustPilotReview
