import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import styled from 'styled-components'
import CallbackForm from '../components/CallbackForm'
import HeroImage from '../components/HeroImage/HeroImage'
import { Col } from '../components/Grid'
import { container } from '../theme'

const Wrapper = styled.div`
  display: flex;
  ${container}

  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`

const CallbackLead = () => {
  const ultraURL = useSelector(state => state.api.general, shallowEqual)

  const highlights = [
    { highlight_list_item_text: 'Early payout for terminal illness' },
    { highlight_list_item_text: 'You could get a Gift Card worth up to £100*' },
    { highlight_list_item_text: 'Accidental Death Benefit included' },
  ]

  return (
    <div id="callbackLead" style={{ marginBottom: '30px' }}>
      <HeroImage
        heading="Straight-talking"
        subheading="life insurance"
        highlightsList={highlights}
        affiliateImage="https://cdn.bgllife.co.uk/wp-content/uploads/2021/01/14143005/beaglestreet-logo.png"
        image="https://cdn.bgllife.co.uk/wp-content/uploads/2021/06/25094138/Monther-and-child.png"
        showTrustpilotRating
        ppc
      />
      <Wrapper>
        <Col xs="12" md="12" style={{ padding: 0 }}>
          <CallbackForm
            ultraUrl={ultraURL}
            ppcBool
          />
        </Col>
      </Wrapper>
    </div>
  )
}

export default CallbackLead
