import styled from 'styled-components'

import { query } from '../../theme'

const LearnArticleContainer = styled.article`
  padding: 3rem 2.5rem;
  ${query.combine(['medium', 'small', 'extraSmall'], `
    padding: 0rem 0 2rem 0; 
  `)}    
`

const Heading = styled.h1`
  color: #28334A;
  margin: 0 0 2rem 0;
  font-size: 2.8em;
  line-height: 1.2;
  ${query.combine(['large', 'medium'], `
    font-size: 2em;
  `)}  
  ${query.extraSmall`
    font-size: 1.7em;
  `}      
`

const Summary = styled.aside`
  padding: 1.5rem;
  background-color: #DDF0EE;
  color: #373737;
  margin-bottom: 2rem;
  ${query.large`
    font-size: .95em;
  `}   
`

const Contents = styled.div`
  strong {
    display:block;
    margin-bottom: .8rem;
    color: #28334A;
    ${query.combine(['small', 'extraSmall'], `
      display: none;
    `)}      
  }
  .links {
    border-top: #DBDBDB 1px solid;
    border-bottom: #DBDBDB 1px solid;
    margin-bottom: 2rem;
    padding: 1.2rem 1.2rem .6rem 1.2rem;   
    ${query.combine(['small', 'extraSmall'], `
      border: 0;
      padding: 0;
    `)}   
    .mobile-contents {
      display: none;
      ${query.combine(['small', 'extraSmall'], `
        display: block;
      `)}        
      border-radius: 5px;
      padding: 1.2rem;
      box-shadow: 0 2px 5px 0 #ADB7C5;
      position: relative;
      &:after {
        background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"/></svg>');
        width: 20px;
        height: 20px;
        content: '';
        color: #373737;
        display:inline-block;
        margin: 0 .3rem 0 0;
        position: absolute;
        top: 21px;      
        right: 12px;
        z-index: -1;
      }
      input {
        display: none;
      }     
      label {
        position: relative;
        display: block;
        cursor: pointer;
        font-weight: bold;
        color: #28334A;     
      }      
      ul {
        overflow: hidden;
        max-height: 0;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
          margin-top: .75rem;
          a {
            color: #373737;
            text-decoration: none;
          }
        }        
      }  
      input:checked~ul {
        max-height: none;
      }      
    }    
  }
`

const ContentsLink = styled.span`
  display: block;
  width: 48%;
  float: left;
  margin-right: 2%;
  margin-bottom: .8rem;
  &:nth-child(2n) {
    margin-right: 0;
  }
  a {
    color: #8e5aad;
    text-decoration:none;
    &:hover {
      color: #373737;
      text-decoration: underline;
    }
  }
  ${query.combine(['small', 'extraSmall'], `
    display: none;
  `)}     
`

const Sections = styled.div`
`

const Section = styled.section`
  h2 {
    color: #28334A;
    ${query.large`
      font-size: 1.2em;
    `}      
  }
  ul {
    li {
      line-height: 1.6;
    }
  }
  color: #373737;
  iframe {
    width: 100%;
  }
  p {
    ${query.large`
      font-size: 1em;
    `}     
  }
  * {
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: #8e5aad;
    text-decoration: none;
    &:hover {
      color: #000;
      text-decoration: underline;
    }
  }
  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`

const RelatedTopics = styled.aside`
  border-top: #DBDBDB 1px solid;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
  strong {
    display:block;
    color: #28334A;
    font-size: 1.1em;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-top: .75rem;
      a {
        color: #373737;
        text-decoration: none;
        &:hover {
          color: #000;
          text-decoration: underline;
        }
      }
    }
  }
`

export {
  LearnArticleContainer,
  Heading,
  Summary,
  Contents,
  ContentsLink,
  Sections,
  Section,
  RelatedTopics,
}
