import { createGlobalStyle } from 'styled-components'
import normalize from 'normalize.css'
import animate from '../assets/css/animate.css'
import {
  query,
  theme,
  container,
} from '.'

const GlobalStyles = createGlobalStyle`
  @import url(${normalize});
  @import url(${animate});
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

  html {
    font-size: 16px;
  }
  html, body {
    height: 100%;
    margin: 0%;
    padding: 0;
  }
  * {
    box-sizing: border-box;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
  }
  body {

    /* accessibility styles */
    .sr-only {
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    /* disable iOs auto zoom */
    input[type='text']:focus,
    input[type='number']:focus,
    input[type='tel']:focus,
    textarea:focus {
      font-size: 16px;
    }

    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      select:focus,
      textarea:focus,
      input:focus {
        font-size: 16px;
      }
    }

    /* end zoom code */

    &.loading {
      height: 100%;
    }
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    line-height: 1.5;
    font-size: 1em;
    -webkit-font-smoothing: antialiased;
    position: relative;
    &.mobile_menu_open {
      background: #F3F5F8;

      .header-main {
        position: absolute;
        z-index: 9999;
        top: 0;
        margin-bottom: 0;
      }
      .footer-main {
        display: none;
      }

      .template-wrap {
        display: none;
      }

      #mobile_menu {
        background: #F3F5F8;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        z-index: 9998;
        display:block;
      }
    }

    .backdrop {
      background: linear-gradient(90deg,#F3F5F8 20%,#fff 90%);
      ${query.combine(['extraSmall', 'small', 'medium'], `
        background: transparent;
      `)}
      .main_content {
        background: #fff;
        ${query.combine(['extraSmall', 'small', 'medium'], `
          background: transparent;
        `)}
      }
      .sidebar {

      }
    }

  }

  .content_block {
    margin-bottom: 4rem;
    ${query.combine(['extraSmall', 'small', 'medium'], `
       margin-bottom: 3rem;
    `)}
  }

  #root {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }
  #mobile_menu {
    display: none;
  }
  .u-clearfix::after {
    display: block;
    content: "";
    clear: both;
  }

  p {
    color: '${theme.paragraph}';
    font-family: inherit;
    font-size: 1.125em;
    line-height: 30px;
    ${query.combine(['extraSmall', 'small'], `
      font-size: 1em;
      line-height: 27px;
    `)}
  }

  .flexContainer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

  .container {
    ${container}
  }

  .guidesarchive, .post, .guides {
    .template-wrap {
      margin: 3rem 0;
      ${query.combine(['extraSmall', 'small'], `
        margin: 1.5rem 0;
      `)}
    }
  }

  .blog-archive, .search-results, .blog-post, .guide-post, .guides-archive {
    margin: 3rem 0;
    ${query.combine(['extraSmall', 'small'], `
      margin: 1.5rem 0;
    `)}
  }

  .article-content {
    * {
      &:last-child {
        margin-bottom: 0;
      }
    }
    a {
      color: #8038A9;
      &:hover {
        color: #000;
        text-decoration: none;
      }
    }
    p {
      color: #373737;
      margin: 0 0 2rem 0;
    }
    ul, ol {
      margin-bottom: 2rem;
      padding-left: 2rem;
      margin-top: 0;
      > li {
        margin-bottom: .3rem;
        color: #373737;
        line-height: 1.8;
        &:last-child {
          margin-bottom: 0;
        }
      }
      ${query.combine(['extraSmall', 'small'], `
        padding-left: 1rem;
      `)}
    }
    h2, h3 {
      margin: 0 0 1rem 0;
      color: #28334A;
    }
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
    img.alignright {float:right; margin:0 0 1em 1em}
    img.alignleft {float:left; margin:0 1em 1em 0}
    img.aligncenter {display: block; margin-left: auto; margin-right: auto}
    a img.alignright {float:right; margin:0 0 1em 1em}
    a img.alignleft {float:left; margin:0 1em 1em 0}
    a img.aligncenter {display: block; margin-left: auto; margin-right: auto}
  }

  .responsive-embed {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
    margin-bottom: 1rem;
  }

  .table_outer {
    width: 100%;
    height: 100%;
    display: table;
    .table_inner_mid {
        width: 100%;
        height: 100%;
        display: table-cell;
        vertical-align: middle;
    }
  }

  .responsive-table {
    display: block;
    width: 100%;
    overflow-x: auto;
    table {
      border: 1px solid #D3D9E5;
      border-radius: 5px;
      box-shadow: 0 2px 10px 0 #DBE1EB;
      border-spacing: 0;
      width: 100%;
      table-layout: fixed;
      ${query.combine(['extraSmall', 'small', 'medium'], `
        table-layout: auto;
      `)}
      &.no-bold-headers {
        thead {
          th {
            font-weight: normal;
          }
        }
      }
      &.centered {
        th, td {
          text-align: center;
        }
      }
      thead {
        th {
          color: #28334A;
          padding: 1.5rem;
          font-size: 1.1em;
          border-right: #D3D9E5 1px solid;
          &:last-child {
            border: 0;
          }
        }
      }
      tbody {
        tr {
          &:nth-child(odd) {
            background: #F3F5F8;
          }
          td {
            padding: 1rem;
            font-size: .95em;
            color: #373737;
            border-right: #D3D9E5 1px solid;
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
  }

  #CoronavirusIcon {
    width: 55px;
  }

  .incentive_with_carousel {
    .glider-contain {
      display: flex;
      .glider-prev {
        font-size: 22px;
        left: -5px;
        top: 47%;
        padding: 16px 22px;
        margin: -16px -22px;
        position: relative;
      }

      .glider-next {
        font-size: 22px;
        right: -5px;
        top: 47%;
        padding: 16px 22px;
        margin: -16px -22px;
        position: relative;
      }

      .glider-item {
        .glider-slide {
          padding: 0 20px;
        }
      }

      .glider {
        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
          display: none;
        }
      }

      @media only screen and (max-width: 767px) {
        .glider-prev {
          top: 48%;
        }

        .glider-next {
          top: 48%;
        }
      }
    }
  }

`
export default GlobalStyles
