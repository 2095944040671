import styled from 'styled-components'

const Img = styled.img`
  border: none;
  /* Dimensions */
  ${props => !props.responsive && (`
    width: ${props.dimensions.width};
    height: ${props.dimensions.height};
  `)}

  ${props => props.responsive && `
    max-width: 100%;
    height: auto;
  `}

`

export default Img
