import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  Row,
} from '../components/Grid'

// import { Button } from '../components/Button'
import Heading from '../components/Heading'
import FeaturedArticles from '../components/FeaturedArticles'
import GuideCard from '../components/GuideCard'

// import {
//   // getPosts,
//   morePosts,
// } from '../actions/posts'

import {
  isEmptyObj,
  // hasPropKey,
  // isUndefinedOrNull,
} from '../utils'

class Blog extends Component {
  // state = {
  //   loading: true,
  //   currentPage: 1,
  //   totalPages: 1,
  //   PER_PAGE: 9,
  //   posts: {},
  // }

  // static getDerivedStateFromProps(props, state) {
  // const {
  //   loadPosts,
  //   data,
  // } = props
  // const { posts } = props
  // const { loading, currentPage, PER_PAGE } = state

  // if (loading) {
  //   if (!isUndefinedOrNull(data.acf) && data.acf.featured_posts) {
  //     const obj = {
  //       api: 'genericEPBS',
  //       url: 'misc/get_multi_posts_by_id',
  //       params: {
  //         ids: data.acf.featured_posts,
  //       },
  //     }
  //     loadPosts('featuredPosts', obj)
  //   }
  //   const obj = {
  //     api: 'genericEP',
  //     url: 'posts',
  //     params: {
  //       page: currentPage,
  //       per_page: PER_PAGE,
  //     },
  //   }
  //   loadPosts('blog', obj)
  // }
  // const totalPg = hasPropKey(posts, 'headers') ? posts.headers['x-wp-totalpages'] : 1

  // return {
  //   loading: false,
  //   posts: posts,
  //   totalPages: totalPg,
  // }
  // }

  // loadMorePosts = (e) => {
  //   e.preventDefault()
  //   const { loadPosts } = this.props
  //   const { currentPage, PER_PAGE } = this.state
  //   const nextPage = currentPage + 1
  //   const obj = {
  //     api: 'genericEP',
  //     url: 'posts',
  //     params: {
  //       per_page: PER_PAGE + 9,
  //     },
  //   }

  //   loadPosts('blog', obj)
  //   this.setState({
  //     currentPage: nextPage,
  //     PER_PAGE: parseFloat(PER_PAGE + 9),
  //   })
  // }

  render() {
    const { data, posts: { posts } } = this.props
    const { acf } = data

    // const { currentPage, totalPages, posts } = this.state

    let featuredArticles = {}

    if (!isEmptyObj(acf.featuredPosts)) {
      const firstPost = acf.featuredPosts[0]
      const otherPosts = Object.keys(acf.featuredPosts).reduce((object, key) => {
        if (Number(key) !== Number(0)) {
          object[(Number(key) - 1)] = acf.featuredPosts[key]
        }
        return object
      }, {})

      featuredArticles = {
        firstPost,
        otherPosts,
      }
    }

    return (
      <div className="blog-archive page-margined">
        <Heading text="Beagle Street Blog" subheading={null} margin="0 0 2rem 0" pos="center" />
        {!isEmptyObj(featuredArticles) && (
          <FeaturedArticles posts={featuredArticles} />
        )}
        {!isEmptyObj(posts) && (
          <div className="container">
            <Row>
              {Object.keys(posts).map(i => {
                const {
                  title,
                  slug,
                  excerpt,
                  // eslint-disable-next-line camelcase
                  category_names,
                  id,
                  media,
                } = posts[i]
                const post = {
                  title,
                  excerpt,
                  tax: category_names[0],
                  thumb: media.small_block,
                  url: `/${slug}`,
                }
                return (
                  <GuideCard post={post} key={id} />
                )
              })}
            </Row>
            {/* {currentPage <= parseFloat(totalPages) && (
              <div style={{ textAlign: 'center' }}>
                <Button
                  kind="navy_ghost"
                  onClick={(e) => this.loadMorePosts(e)}>View more posts
                </Button>
              </div>
            )} */}
          </div>
        )}
      </div>
    )
  }
}

Blog.propTypes = {
  data: PropTypes.object,
  posts: PropTypes.object,
  // loadPosts: PropTypes.func,
  // more: PropTypes.func,
}

const mapStateToProps = state => ({
  posts: state.posts,
})

// const mapDispatchToProps = (dispatch) => ({
//   // more: (e) => dispatch(morePosts(e)),
// })

export default connect(mapStateToProps, null)(Blog)
