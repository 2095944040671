import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import BackLink from '../components/BackLink'
import GuideArticle from '../components/GuideArticle'

class Post extends Component {
  showMore = () => {

  }

  render() {
    const { data } = this.props
    return (
      <div className="page-margined blog-post">
        <div className="container">
          <BackLink url="/blog" text="All Posts" />
          <GuideArticle data={data} related={null} />
        </div>
      </div>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object,
}

export default connect(null, null)(Post)
