import styled from 'styled-components'

import { container, query, theme } from '../../theme'

const CallbackFormContainer = styled.div`
  background-color: #FFFFFF;
  width: 100%;

  .ppc {
    padding: 0;
  }

  .formErrorMessage {
    display: contents;
    font-weight: bold;
  }

  .image_container {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 92%;
    ${query.combine(['extraSmall', 'small'], `
      display:none;
    `)}
  }
  .content_container {
    .col {
      margin-bottom: 30px;
    }
  }

  #needHelpWith,
  #dayForCallback,
  #preferredTime {
    margin-bottom: 0;
  }

  .form_footer {
    margin-bottom: 40px;

    ${query.combine(['extraSmall'], `
      margin-top: 30px;
      text-align: center;
    `)}
  }


  // css for PPC callback form
  .ppcCallback {
    h2 {
      margin-bottom: 40px;
      margin-top: 0;

      @media only screen and (max-width: 767px) {
        text-align: center;
      }
    }

    #disclaimer {
      text-align: left;
      width: 92%;
      margin: auto;
      font-size: 13px;
      line-height: 16px;
      margin: 0 auto 25px auto;
      
      a {
        color: ${theme.secondary};
      }

      @media only screen and (max-width: 767px) {
        text-align: center;
        margin: 25px auto 0 auto;
      }
    }

    #formRecaptcha {
      div {
        div {
          margin: auto;
        }
      }
    }

    .form_footer {
      text-align: right;

      @media only screen and (max-width: 767px) {
        button {
          width: 100%;
        }
      }
    }
  }
`

const Heading = styled.h2`
  color: #28334A;
  font-family: Montserrat;
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  margin-top: 40px;
  margin-bottom: 60px;
`

const CallbackFormWrapper = styled.div`

  ${container}

  img {
    width: 100%;
  }
`

export {
  CallbackFormContainer,
  CallbackFormWrapper,
  Heading,
}
