import styled, { css } from 'styled-components'

const RecaptchaOutter = styled.div`
  margin-bottom: 30px;
`
const error = css`
  border: #B20A0A 1px solid; 
`

const RecaptchaInner = styled.div`
  & > div {
   ${props => props.error && error}
  }
`

const ErrorMessage = styled.p`
  font-size: 1.125rem;
  color: #B20A0A;
  line-height: 1.6875rem;
  font-family: Montserrat;
  margin: 0;
  padding-top: 7px;
  display: flex;
  align-items: end;

  img {
    width: 24px;
    height: 24px;
    margin-right: 5px;
  }
`

export {
  ErrorMessage,
  RecaptchaInner,
  RecaptchaOutter,
}
