import React from 'react'
import { Helmet } from 'react-helmet'

import Page404 from './style'

const NotFound = () => (
  <Page404>
    <Helmet>
      <title>404 page not found - Beagle Street Life Insurance</title>
    </Helmet>
    <h1>
      <span>404</span>
      Page not found
    </h1>
    <p>
      The page you were looking for has moved or never even existed at all.
      <br />
      <a href="/">Take me back to the homepage</a>
    </p>
  </Page404>
)

export default NotFound
