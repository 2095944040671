import React from 'react'
import PropTypes from 'prop-types'

import {
  Row,
} from '../Grid'

import GuideCard from '../GuideCard'

import {
  Container,
  Section,
} from './style'

import { isUndefinedOrNull } from '../../utils'

const GroupedArticles = ({
  posts,
}) => (
  <Container>
    {!isUndefinedOrNull(posts) && (
      Object.keys(posts).map(i => (
        <Section key={i}>
          <h2>{posts[i].cat}</h2>
          <Row>
            {!isUndefinedOrNull(posts[i].posts) && (
              Object.keys(posts[i].posts).map(x => {
                const {
                  title,
                  excerpt,
                  terms,
                  thumbs,
                  url,
                } = posts[i].posts[x]
                const post = {
                  title,
                  excerpt,
                  tax: terms[0].name,
                  thumb: thumbs.small_block,
                  url,
                }
                return (
                  <GuideCard post={post} key={x} />
                )
              })
            )}
          </Row>
        </Section>
      ))
    )}
  </Container>
)

GroupedArticles.propTypes = {
  posts: PropTypes.object,
}

export default GroupedArticles
