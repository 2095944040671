const UPDATE_ANALYTICS = 'UPDATE_ANALYTICS'
const UPDATE_ANALYTICS_SUCCESS = 'UPDATE_ANALYTICS_SUCCESS'
const updateAnalyics = (payload) => ({
  type: UPDATE_ANALYTICS,
  payload,
})

export {
  updateAnalyics,
  UPDATE_ANALYTICS,
  UPDATE_ANALYTICS_SUCCESS,
}
