import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  cleanHtml,
  dataLayerPush,
} from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import {
  Button,
} from '../Button'

import {
  DataLayerLink,
} from '../DataLayerLink'

import Image from '../Image'

import {
  Container,
  LR,
  Icons,
  Posts,
  Buttons,
} from './style'

class LRContent extends Component {
  state = {
    loading: false,
  }

  componentDidMount() {
    this.setState({ loading: true })
  }

  toggleAccordion = (e) => {
    e.preventDefault()
    const { currentTarget: { parentNode, innerText } } = e
    parentNode.classList.toggle('active')
    dataLayerPush({
      event: 'questionClick',
      questionName: `${innerText}`.trim(),
    })
  }

  render() {
    const {
      background,
      image,
      imagepos,
      showtoptext,
      toptext,
      toptextlink,
      mainheading,
      text,
      iconcontent,
      posts,
      buttons,
      anchorid,
    } = this.props

    const pad = background ? '3.5rem 0' : '0'
    const { loading } = this.state

    return (
      <div id={anchorid} className="lr_content content_block" style={{ backgroundColor: background || '#fff', padding: pad }}>
        <Container>
          <LR>
            <Row>
              {imagepos === 'left' && (
                <Col lg="6" sm="12" xs="12" className="image_col left">
                  <Image src={image.url} responsive altText={mainheading} />
                </Col>
              )}
              <Col lg="6" sm="12" xs="12">
                <h2>
                  {showtoptext && (
                    <span><a href={toptextlink}>{toptext}</a></span>
                  )}
                  {mainheading}
                </h2>
                <div
                  className="content"
                  // eslint-disable-next-line
                  dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
                />
                {iconcontent && (
                  <Icons>
                    <Row>
                      {Object.keys(iconcontent).map(i => (
                        <Col md="6" sm="12" xs="12" key={i}>
                          <div className="iconCont">
                            <div>
                              {loading ? <img src={iconcontent[i].icon.sizes.large} alt="Icon" /> : null}
                            </div>
                            <div>
                              <span
                                // eslint-disable-next-line
                                dangerouslySetInnerHTML={{ __html: cleanHtml(iconcontent[i].text) }}
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Icons>
                )}
                {posts && (
                  <Posts>
                    <ul>
                      {Object.keys(posts).map(i => {
                        const url = `/${posts[i].post_type}/${posts[i].post_name}`
                        return (
                          <li key={i}>
                            <a href={posts[i].post_name} onClick={(e) => this.toggleAccordion(e)}>
                              <span>{posts[i].post_title}</span>
                            </a>
                            <span
                              className="hidden_content"
                              // eslint-disable-next-line
                              dangerouslySetInnerHTML={{ __html: cleanHtml(posts[i].post_excerpt) }}
                            />
                            <DataLayerLink
                              eventName="textClick"
                              eventKey="textName"
                              className="hidden_content"
                              href={url}
                            >
                              Continue reading
                            </DataLayerLink>
                          </li>
                        )
                      })}
                    </ul>
                  </Posts>
                )}
                {buttons && (
                  <Buttons>
                    {Object.keys(buttons).map(i => (
                      <Button href={buttons[i].url} to={buttons[i].url} as="a" kind={buttons[i].style} key={i}>{
                        buttons[i].text
}
                      </Button>
                    ))}
                  </Buttons>
                )}
              </Col>
              {imagepos === 'right' && (
                <Col lg="6" sm="12" xs="12" className="image_col right">
                  <Image src={image.url} responsive altText={mainheading} />
                </Col>
              )}
            </Row>
          </LR>
        </Container>
      </div>
    )
  }
}

LRContent.propTypes = {
  background: PropTypes.string,
  anchorid: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
  imagepos: PropTypes.oneOf(['left', 'right']),
  toptext: PropTypes.string,
  showtoptext: PropTypes.bool,
  toptextlink: PropTypes.string,
  mainheading: PropTypes.string,
  text: PropTypes.string,
  iconcontent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  posts: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  buttons: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
}

export default LRContent
