import React from 'react'
import PropTypes from 'prop-types'
import { isUndefinedOrNull } from '../../utils'

import { Heading2Styled } from './style'

const MainHeading = ({
  text,
  url,
}) => {
  if (isUndefinedOrNull(text) && isUndefinedOrNull(url)) {
    return null
  }
  return (
    <Heading2Styled>
      <a href={url}>{text}</a>
    </Heading2Styled>
  )
}

MainHeading.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string,
}

export default MainHeading
