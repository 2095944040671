import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// import { row } from './style.module.css'

import { RowContainer } from './styles'

const Row = ({
  children,
  className = '',
  ...props
}) => (
  <RowContainer className={classNames(className)} {...props}>{children}</RowContainer>
)

Row.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default Row
