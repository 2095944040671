import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import SearchInput from '../SearchInput'
import Icon from '../Icons/Icon'
import { Button } from '../Button'

import {
  MobileMenuWrap,
  MobileMenuContainer,
  Search,
  Menu,
  Bottom,
} from './style'

import { TelephoneNumber } from '../../common/text'
import { urls } from '../../common/const'

const MobileMenu = ({ menu }) => {
  useEffect(() => {
    document.body.classList.add('mobile_menu_open')
    return function cleanup() {
      document.body.classList.remove('mobile_menu_open')
    }
  })

  const buildMenu = () => {
    if (menu) {
      return Object.keys(menu).map((item) => {
        const {
          title,
          classes,
          ID,
          url,
        } = menu[item]
        return (
          <li key={ID} className={classes}>
            <a href={url}>{title}</a>
          </li>
        )
      })
    }
    return true
  }
  const { quickQuote, login } = urls
  return ReactDOM.createPortal(
    <MobileMenuWrap>
      <MobileMenuContainer>
        <Search id="mob_search">
          <SearchInput
            action="/search"
            method="get"
            id="search"
            name="s"
            placeholder="Search e.g. how much cover?"
            icon="search"
          >
            <input type="hidden" name="post_type" />
          </SearchInput>
        </Search>
        <Menu>
          <ul className="u-clearfix" id="mainMobileNavigation">
            {buildMenu()}
            <li className="contact">
              <a href="/contact-us">Contact Us</a>
              <a href={`tel:${TelephoneNumber}`}><Icon kind="phone" size="17" color="#28334A" /> <span>Call {TelephoneNumber}</span></a>
            </li>
          </ul>
        </Menu>
      </MobileMenuContainer>
      <Bottom>
        <MobileMenuContainer className="bottom">
          <div className="u-clearfix">
            <div className="utils">
              <ul>
                <li className="signin"><a href={login}><Icon kind="sign-in" size="26" color="#28334A" /> Sign in</a></li>
                <li className="retrieve"><a href="https://quotes.beaglestreet.com/RetrieveQuote"><Icon kind="retrieve-quote" size="26" color="#28334A" /> Retrieve quote</a></li>
              </ul>
            </div>
            <Button as="a" href={quickQuote} kind="purple_solid" size="extraLarge">Get a quote</Button>
          </div>
        </MobileMenuContainer>
      </Bottom>
    </MobileMenuWrap>,
    document.getElementById('mobile_menu'),
  )
}

MobileMenu.propTypes = {
  menu: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
}

export default MobileMenu
