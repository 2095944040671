import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const Icon = styled.div`
  text-align: center;
  ${query.combine(['extraSmall', 'small'], `
    margin-bottom: 1rem;
  `)}     
  img {
    margin: 0 auto;
    display: block;
  }
  strong {
    color: #28334A;
    font-size: 1.2em;
    display: block;
    margin: .5rem 0 .2rem 0;
  }
  p {
    margin: 0;
    color: #373737;
    font-size: .95em;
    line-height: 1.6;
  }
`

export {
  Container,
  Icon,
}
