import styled from 'styled-components'
import { query, container } from '../../theme'

const Container = styled.div`
  ${container}
`

const Section = styled.div`
  margin-top: 2rem;
  ${query.combine(['extraSmall', 'small', 'medium'], `
    margin-top: 1rem;
  `)}   
  h2 {
    margin: 0 0 1rem 0;
    color: #28334A;
  }
`

export {
  Container,
  Section,
}
