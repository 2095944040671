export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  background: {
    mappedProp: 'background',
  },
  add_margin_bottom: {
    mappedProp: 'addMarginBottom',
  },
  show_top_text: {
    mappedProp: 'showtoptext'
  },
  top_heading_text: {
    mappedProp: 'topheadingtext',
  },
  top_heading_url: {
    mappedProp: 'topheadingurl',
  },
  main_heading: {
    mappedProp: 'mainheading',
  },
  reviews: {
    mappedProp: 'reviews',
  },
  show_street_head: {
    mappedProp: 'showstreethead',
  },
  street_head_text: {
    mappedProp: 'streetheadtext',
  },
}
