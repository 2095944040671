export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  button_text: {
    mappedProp: 'children',
  },
  open_in_new_window: {
    mappedProp: '',
  },
  target: {
    mappedProp: 'as',
  },
  size: {
    mappedProp: '',
  },
  style: {
    mappedProp: 'kind',
  },
  url: {
    mappedProp: 'to',
  },
}
