import styled from 'styled-components'

const SearchList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    margin-bottom: 2rem;
    &:last-child {
      margin-bottom: 0;
    }
    a {
      text-decoration: none;
      color: #9361B0;
      font-weight: bold;
      &:hover {
        color: #000;
      }
    }
    p {
      color: #373737;
      font-size: .95em;
      line-height: 1.5;
      margin-bottom: 0;
    }
  }
`
export default SearchList
