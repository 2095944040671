import styled from 'styled-components'

import { query } from '../../theme'

const LearnSidebar = styled.div`
  padding-top: 3rem;
  ${query.combine(['medium', 'small', 'extraSmall'], `
    padding-top:1.5rem;
  `)}     
`

const Backlink = styled.a`
  color: #28334A;
  text-decoration: none;
  font-size: 1.1em;
  display: inline-block;
  margin-bottom: 2rem;
  font-weight: bold;
  ${query.combine(['medium', 'small', 'extraSmall'], `
    margin-bottom: 1.5rem;
  `)}   
  &:before {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"/></svg>');
    width: 20px;
    height: 20px;
    content: '';
    color: #373737;
    display:inline-block;
    margin: 0 .3rem 0 0;
    position: relative;
    top: 3px;
  }
`

const LearnHide = styled.div`
  ${query.combine(['medium', 'small', 'extraSmall'], `
    display: none;
  `)}     
`

const Search = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  .search_icon {
    position: absolute;
    left: 0%;
    top: 3px;
  }
  .close {
    position: absolute;
    top: 0;
    right: -15px;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
  input {
    width: 100%;
    border: 0;
    border-bottom: #DBDBDB 1px solid;
    padding: 0 0 .5rem 1.3rem;
    background: transparent;
    font-size: .9em;
  }
`

const Tree = styled.div`
  position: relative;
  z-index: 10;
  border-left: #fff 2px solid;
  padding-left: 1.5rem;
  .topLevelBtn {
    color: #28334A;
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    font-weight: bold;
    line-height: 22px;
  }
  .subCatBtn {
    color: #373737;
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    line-height: 24px;
    }
  .cat_link {
    font-family: 'Montserrat', sans-serif;
    font-size: 1em;
    line-height: 24px;
    color: #373737;
    &.active {
      color: #373737;
      font-weight: bold;
    }
  }
`

const NavSection = styled.div`
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
  button {
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    &:before {
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"/></svg>');
      width: 15px;
      height: 15px;
      content: '';
      color: #373737;
      display:inline-block;
      margin: 0 .3rem 0 0;
      position: relative;
      top: 1px;    
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  border-left: transparent 2px solid;

  > button {
    font-weight: bold;
    color: #28334A;
  }

  &.active {
    border-left-color: #00C2A2;
    padding-left: 1.6rem;
    margin-left: -1.6rem;
    > button {
        margin-bottom: 1rem;
        &:before {
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"/></svg>');
        }
    }
    .top {
      li.cat {
        display: block;
        &.active {
          > button {
            &:before {
              background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"/></svg>');
            }          
          }          
          .posts {
            display: block;
          }        
        }
      }
    }
  }

  .top {
    padding-left: 1rem;
    > li {
      margin-bottom: 1rem;
      button {
        ${query.combine(['large'], `
          font-size: .9em;
        `)}        
      }
      &:last-child {
        margin-bottom: 0;
      }
      display: none;
      .posts {
        display: none;
        padding-left: 2rem;
        margin-bottom: 1.5rem;
        > li {
          margin-top: 1rem;
          font-size: .9em;
          ${query.combine(['large'], `
            font-size: .85em;
          `)}            
          a {
            color: #373737;
            text-decoration: none;
            &:hover {
              color: #000;
              text-decoration: underline;
            }
            &.active {
              font-weight: bold;
              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
`

const SearchResults = styled.div`
  border-left: #fff 2px solid;
  padding-left: 1.5rem;
  margin-top: 1rem;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      padding: .5rem 0;
      a {
        color: #373737;
        font-size: .95em;
        text-decoration: none;
        &:hover {
          color: #000;
        }
      }
    }
  }
`

export {
  LearnSidebar,
  Backlink,
  Search,
  LearnHide,
  Tree,
  NavSection,
  SearchResults,
}
