export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  background: {
    mappedProp: 'background',
  },
  content: {
    mappedProp: 'content',
  },
  main_heading: {
    mappedProp: 'mainheading',
  },
  top_text: {
    mappedProp: 'toptext',
  },
  add_margin_bottom: {
    mappedProp: 'addMarginBottom',
  },
  show_button: {
    mappedProp: 'showButton',
  },
  button_text: {
    mappedProp: 'buttonText'
  },
  button_url: {
    mappedProp: 'buttonURL'
  },
}
