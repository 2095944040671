import santizeHtml from 'sanitize-html'
import { objectToArray } from './convertData'

/**
 * Is value undefined or null
 * @param {any} val - variable to check
 * @return {boolean} if variable is undefined or null
 */
const isUndefinedOrNull = (val) =>
  (val === undefined || val === null)

const dataLayer = typeof window === 'undefined' ? [] : window.dataLayer

/**
 * Is String
 * @param {string} val - val to check
 * @return {boolean} if val is Array
 */
const isString = (val) =>
  val && val.constructor === String

/**
 * Is Object
 * @param {any} val - val to check
 * @return {boolean} if val is object
 */
const isObject = (val) =>
  val.constructor === Object

/**
 * Is Array
 * @param {any} val - val to check
 * @return {boolean} if val is Array
 */
const isArray = (val) =>
  val && val.constructor === Array

/**
 * Shallow compare objects
 * @param {object} o1 - Object 1 to check
 * @param {object} o2 - Object 2 to check
 * @return {boolean} If objects are the same
 */
const shallowObjectCompare = (o1, o2) => {
  if (isUndefinedOrNull(o1) || isUndefinedOrNull(o2)) {
    return o1 === o2
  }
  const o1Keys = Object.keys(o1)
  const o2Keys = Object.keys(o2)

  if (o1Keys.length !== o2Keys.length) {
    return false
  }

  const compo1 = o1Keys.map(key => o1[key] === o2[key]).every(key => key === true)
  const compo2 = o2Keys.map(key => o2[key] === o1[key]).every(key => key === true)

  if (!compo1 || !compo2) {
    return false
  }

  return true
}

/**
 * Make a dataLayer Page event push
 * @param {obj} data, obj which should contain url and title for the dataLayer push
 * @returns {func} a dataLayer push with a event pageName with PageUrl and PageTitle variables
 */
const updatePageAnalytics = () => !isUndefinedOrNull(dataLayer) && (dataLayer.push({
  event: 'pageName',
}))

/**
 * Make a dataLayer event push
 * @param {obj} data, obj which should contain url and title for the dataLayer push
 * @returns {func} a dataLayer push with a event
 */
const dataLayerPush = (obj) => {
  if (obj && !isUndefinedOrNull(dataLayer)) {
    return dataLayer.push(obj)
  }
  return false
}

/**
 * Take object and searches for key
 * @param {object} obj - object to look through
 * @param {string} key - key to look for
 * @return {boolean} If object has key
 */
const hasPropKey = (obj, key) =>
  Object.keys(obj).includes(key)

/**
 * Mergers props.style with inline styles, inline will be overwritten with props
 * @param {object} c - inline style
 * @param {object} c - props
 * @returns {object} style object
 */
const mergeStyles = (c, n) => ({ ...c, ...(hasPropKey(n, 'style') ? n.style : {}) })

/**
 * Is Empty Array
 * @param {array} arr - arr to check length
 * @return {boolean} if arr is empty
 */
const isEmptyArray = (arr) =>
  isUndefinedOrNull(arr) || (Array.isArray(arr) && arr.length === 0)

/**
 * Is Empty Object
 * @param {object} obj - obj to check length
 * @return {boolean} if obj is empty
 */
const isEmptyObj = (obj) =>
  obj === undefined || (Object.keys(obj).length === 0 && obj.constructor === Object)

/**
 * Is string empty
 * @param {any} str - string to check
 * @return {boolean} if string is empty
 */
const isEmptyString = (str) =>
  typeof (str) === 'string' && (str === '' || str.length === 0)

/**
 * Find a key in object
 * @param {any} str - key
 * @param {any} obj - obj to check
 * @return {string} value of object
 */
const findValue = (str, obj) => {
  const allowed = [str]
  return (
    Object.keys(obj)
      .filter(key => allowed.includes(key))
      .reduce((objNew, key) => {
        objNew[key] = obj[key]
        return objNew[key]
      }, {})
  )
}

const slugify = (string) => {
  const a = 'àáäâãåăæąçćčđďèéěėëêęǵḧìíïîįłḿǹńňñòóöôœøṕŕřßśšșťțùúüûǘůűūųẃẍÿýźžż·/_,:;'
  const b = 'aaaaaaaaacccddeeeeeeeghiiiiilmnnnnooooooprrssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    // eslint-disable-next-line
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    // eslint-disable-next-line
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

const trimText = (str, limit) => {
  let shortText = str
  shortText = shortText.substr(0, shortText.lastIndexOf(' ', limit)) + '...' // eslint-disable-line prefer-template
  return shortText
}

const buildQueryStr = (obj) =>
  Object.keys(obj).map(key => `${key}=${obj[key]}`).join('&')

const ucAllWords = str =>
  str.replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase())

/**
 * Allow anchor smooth scroll
 * @param {any} str - string input
 * @return {string} - string value
 */
const hashLinkScroll = (location) => {
  if (location !== '') {
    setTimeout(() => {
      const element = document.getElementById(location)
      if (element) element.scrollIntoView({ behavior: 'smooth' })
    }, 0)
  }
}

/**
 * Anchor Link Scroll
 * @param {any} str - key
 * @param {any} obj - obj
 * @return {func} -  pushes click to anchor link
 */
const anchorClick = (e, location) => {
  e.preventDefault()
  const {
    attributes,
  } = e.target

  if (location) {
    const newAttr = objectToArray(attributes)
    const allowed = ['to']
    const filtered = newAttr.filter(key => allowed.includes(key.nodeName))
      .reduce((obj, key) => {
        const {
          nodeName,
          nodeValue,
        } = key
        obj[nodeName] = nodeValue
        return obj
      }, {})
    hashLinkScroll(filtered.to)
  }
  return null
}

/**
 * Read cookie value
 * @param {string} string, this is a key of the cookie
 * @returns {string/null} string, this is a value in lowercase  of the cookie
 */

const getCookieValue = (key) => {
  const b = document.cookie.match(`(^|[^;]+)\\s*${key}\\s*=\\s*([^;]+)`)
  return b ? b.pop() : null
}

/**
 *  Checks whether there is a joint policy and returns a store value
 *  This way we can return the correct retained data needed
 *  @param {string} str, query string name
 *  @param {string} params, query string params
 *  @returns {string} returns query string value
 */
const getQueryString = (str, params) => {
  const queryString = new URLSearchParams(params)
  return queryString.get(str)
}

const cleanHtml = html => {
  const config = {
    allowedTags: ['h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'p', 'a', 'ul', 'ol', 'nl', 'li', 'b', 'i', 'strong', 'em', 'strike', 'hr', 'br', 'div', 'table', 'thead', 'caption', 'tbody', 'tr', 'th', 'td', 'pre', 'iframe', 'img'],
    allowedAttributes: {
      a: ['href', 'name', 'target', 'id'],
      img: ['src', 'class', 'alt', 'id'],
      table: ['class', 'id'],
      div: ['class', 'id'],
      iframe: ['class', 'id', 'src'],
    },
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
    allowedIframeHostnames: ['www.youtube.com'],
  }
  const clean = santizeHtml(html, config)
  return clean
}

const reviewCreatedAt = (createdAtDate) => {
  let timeAgo

  // Get a difference of hours and minutes between the current and given time
  const timeDifferenceMilliseconds = new Date().getTime() - new Date(createdAtDate).getTime()
  const timeDifferenceHours = timeDifferenceMilliseconds / (3600 * 1000)

  // Display a correct text for the time, i.e. minutes, hours or weeks
  if ((timeDifferenceHours > 0) && (timeDifferenceHours <= 1)) {
    timeAgo = '1 hour ago'
  } else if ((timeDifferenceHours > 1) && (timeDifferenceHours <= 23)) {
    timeAgo = `${Math.ceil(timeDifferenceHours)} hours ago`
  } else if ((timeDifferenceHours > 23) && (timeDifferenceHours <= 48)) {
    timeAgo = '1 day ago'
  } else if ((timeDifferenceHours > 48) && (timeDifferenceHours <= 144)) {
    timeAgo = `${Math.round(timeDifferenceHours / 24)} days ago`
  } else if ((timeDifferenceHours > 144) && (timeDifferenceHours <= 336)) {
    timeAgo = '1 week ago'
  } else if ((timeDifferenceHours > 336) && (timeDifferenceHours <= 720)) {
    timeAgo = `${Math.round(timeDifferenceHours / 24 / 7)} weeks ago`
  } else if ((timeDifferenceHours > 720) && (timeDifferenceHours <= 1460)) {
    timeAgo = '1 month ago'
  } else if ((timeDifferenceHours > 1460) && (timeDifferenceHours <= 8765)) {
    timeAgo = `${Math.round((timeDifferenceHours / 24) / 30.4)} months ago`
  } else if ((timeDifferenceHours > 8765) && (timeDifferenceHours <= 17530)) {
    timeAgo = '1 year ago'
  } else if (timeDifferenceHours > 17530) {
    timeAgo = `${Math.round(timeDifferenceHours / 24 / 365)} years ago`
  } else {
    timeAgo = `${timeDifferenceHours} hours ago`
  }

  return timeAgo
}

/**
 * Returns a percentage of 2 supplied numbers
 * @param {number} a to divide by b
 * @param {number} b to divided by a
 * @returns {number} return number percentage of the 2 numbers supplied
 */
const getPercentage = (a, b) =>
  (!isUndefinedOrNull(a) && !isUndefinedOrNull(b)) ? Math.round(a / (b / 100)) : null

/**
 * Truncates a strong and adds an elipsis
 * @param {string} string to truncate
 * @param {number} length to truncate
 * @returns {sting} return string truncated with elipsis
 */
const truncateStr = (input, length) => {
  if (!isUndefinedOrNull(input) && isString(input)) {
    return input.length > length ? `${input.substring(0, length)}...` : input
  }
  return null
}
/**
 * Returns bool if string contains numbers
 * @param {string} string to check for numbers
 * @returns {boolean} Returns bool if string contains numbers
 */
const stringContainsNumbers = (str) => /^\d+$/.test(parseInt(str, 10))

const getQuoteURL = () =>
  process.env.QUOTE_URL

const removeWrappedSlashes = (url) => {
  // Remove leading slash from URL
  let slug = url.replace(/^\/|\/$/g, '')
  // If no slug, assume homepage
  slug = slug.length === 0 ? '_homepage' : slug
  return slug
}

const createSlug = (url) => {
  // Remove leading slash from URL
  let slug = url.replace(/^\/|\/$/g, '')
  // If no slug, assume homepage
  slug = slug.length === 0 ? '_homepage' : slug
  return slug
}

const endRoute = (url) =>
  createSlug(url).substr(createSlug(url).lastIndexOf('/') + 1)

/**
 * Set a cookie
 * @returns {func} return function to create cookie
 */
const createCookie = (cookieName, cookieValue, daysToExpire, domain) => {
  const date = new Date()
  date.setTime(date.getTime() + (daysToExpire * 24 * 60 * 60 * 1000))
  const cookieExpiry = isUndefinedOrNull(daysToExpire) ? '' : `expires=${date.toGMTString()};`
  document.cookie = `${cookieName}=${cookieValue}; ${cookieExpiry} path=/;domain=${domain};`
  return `${cookieName}=${cookieValue}; ${cookieExpiry} path=/;domain=${domain};`
}

/**
 * Determines what the campaign code will be
 * Depended on the referrer
 * @returns {func} return function to create cookie
 */
const campCode = (params = '') => {
  // Campaign Code Logic
  if (typeof document !== 'undefined') {
    const documentReferrer = document.referrer
    const queryCampaignCode = getQueryString('src', params) || getQueryString('SRC', params)
    const campaignCodeCookie = getCookieValue('campaignCode') || null
    let referralCampaignCode = null

    // Referrer
    if (isUndefinedOrNull(queryCampaignCode)) {
      if (
        documentReferrer.indexOf('google') !== -1 ||
        documentReferrer.indexOf('yahoo') !== -1 ||
        documentReferrer.indexOf('bing') !== -1
      ) {
        referralCampaignCode = 'BS09'
      } else if (documentReferrer !== '') {
        referralCampaignCode = 'BS07'
      } else {
        referralCampaignCode = 'BS05'
      }
    }
    const campaignCode = queryCampaignCode || referralCampaignCode || (campaignCodeCookie && campaignCodeCookie.toString()) || 'BS05'
    const campaignCodeCookieDomain = (window.location.host).split('.').slice(1).join('.')
    const domainCookie = campaignCodeCookieDomain.length > 1 ? `.${campaignCodeCookieDomain}` : window.location.hostname

    if (isUndefinedOrNull(campaignCodeCookie) ||
      (!isUndefinedOrNull(queryCampaignCode) &&
        queryCampaignCode !== campaignCodeCookie)) {
      createCookie('campaignCode', campaignCode, null, domainCookie)
    }
  }
}

/**
 * Triggers DataLayer function when menu item is clicked
 * @param {e} event triggered the click
 */
const dataLayerOnClick = (e) => {
  if (!isUndefinedOrNull(e)) {
    const { currentTarget, target: { innerText }, target } = e
    const links = [...document.querySelectorAll('a')].filter(link => link.innerText === innerText)

    const linkIndex = links.indexOf(target) > 0 ? links.indexOf(target) + 1 : ''
    const hasTemplateAtt = currentTarget.getAttribute('template')
    const message = stringContainsNumbers(innerText) ? 'phoneCall' : `${innerText} ${!isUndefinedOrNull(hasTemplateAtt) && ` - ${hasTemplateAtt}`}`
    dataLayerPush({
      event: 'menuClick',
      menuName: `${message} ${linkIndex}`.trim(),
    })
  }
}

/**
 * @param {object} o1 - Object for dates
 * @return {object}
 */
const getDates = (availableDays) => {
  if (!isUndefinedOrNull(availableDays)) {
    const newDateObj = Object.keys(availableDays).map(i => {
      const dateTimeObj = {
        key: availableDays[i].timestamp,
        value: availableDays[i].date,
      }
      return dateTimeObj
    })
    return newDateObj
  }
  return null
}

/**
 * @param {object} o1 - Object for times
 * @param {string} timestamp - Object for dates
 * @return {object}
 */
const getTimes = (availableDays, dayForCallback = '') => {
  const displayTimes =
    Object
      .keys(availableDays)
      .filter(o => parseInt(dayForCallback, 10) === availableDays[o].timestamp)
      .map(i => {
        const { times } = availableDays[i]
        const result = Object.keys(times).map(key => ({
          key,
          value: times[key],
        }))
        return result
      })
  return displayTimes[0]
}

/**
 *  Event handler to only allow numbers to be entered into input type
 *  @param {object} event
 *  @param {string,array}
 */
const onlyAllowNumber = (e) => {
  const { value } = e.target
  const hasInvalidChars = /\d|\W/
  // Allow A-z dash, single quote
  const onlyAllowedChars = /[0-9]+/
  // left and right apostrophe because IOS
  const isApostrophes = /[‘’]/
  if (hasInvalidChars.test(value)) {
    value
      .split('')
      // replaces apples apostrophes with normal ones for ios
      .map(c => isApostrophes.test(c) ? '\'' : c)
      .filter(c => onlyAllowedChars.test(c))
      .join('')
  }
}

/**
 *  Validate Phone
 *  @param {string} str - string
 */
const validatePhone = (str) => /^(020[7,8]{1}[1-9]{1}[0-9]{2}[0-9]{4})|(0[1-8]{1}[0-9]{3}[0-9]{6})\s*$/i.test(str)

/**
 *  Return time, round to nearest minute and + 1 to the minutes
 */
const getNearestHalfHourTimeString = (date) => {
  const hour = date.getHours()
  let minutes = date.getMinutes()
  const seconds = date.getSeconds()

  if (seconds >= 30) minutes += 1

  if (minutes < 10) {
    return `${hour}:0${minutes + 1}:00`
  }

  return `${hour}:${minutes + 1}:00`
}

/**
 * Removes an opening and closing HTML tags from a string
 * @param {string} text string of text
 * @param {string} tag HTML element to remove
 * @returns {string} return a string without html element
 */
const removeHtmlTagFromString = (text, element) => {
  const pattern = `<${element}>|</${element}>`
  const expression = new RegExp(pattern, "g")
  return text.replace(expression, '')
}

export {
  isString,
  isUndefinedOrNull,
  isObject,
  isArray,
  mergeStyles,
  isEmptyArray,
  isEmptyObj,
  isEmptyString,
  findValue,
  slugify,
  trimText,
  buildQueryStr,
  shallowObjectCompare,
  hasPropKey,
  ucAllWords,
  updatePageAnalytics,
  hashLinkScroll,
  anchorClick,
  getCookieValue,
  getQueryString,
  cleanHtml,
  reviewCreatedAt,
  truncateStr,
  getPercentage,
  getQuoteURL,
  removeWrappedSlashes,
  endRoute,
  createSlug,
  campCode,
  createCookie,
  dataLayerPush,
  stringContainsNumbers,
  dataLayerOnClick,
  getDates,
  getTimes,
  onlyAllowNumber,
  validatePhone,
  getNearestHalfHourTimeString,
  removeHtmlTagFromString,
}
