import React from 'react'
import PropTypes from 'prop-types'
import { isUndefinedOrNull, cleanHtml } from '../../utils'

import {
  Col, Row,
} from '../Grid'

import {
  Container,
  Content,
  Accred,
} from './style'

const Accreditations = ({
  items,
}) => (
  <Container id="accreditations">
    <Content>
      <Row>
        {!isUndefinedOrNull(items) && (
          Object.keys(items).map(i => (
            <Col lg="4" md="12" sm="12" xs="12" key={i}>
              <Accred>
                <img src={items[i].icon.sizes.large} alt="" />
                { /* eslint-disable-next-line */}
                <span dangerouslySetInnerHTML={{ __html: cleanHtml(items[i].text) }} />
              </Accred>
            </Col>
          ))
        )}
      </Row>
    </Content>
  </Container>
)

Accreditations.propTypes = {
  items: PropTypes.array,
}

export default Accreditations
