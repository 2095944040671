import React, { useState } from 'react'
import PropTypes from 'prop-types'

import {
  Label,
  TextAreaField,
} from './style'

const TextArea = ({
  identifier,
  labelText,
  onInputChange,
  rows,
}) => {
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e) => {
    setInputValue(e.target.value)
    onInputChange(e.target.value, e.target.name)
  }

  return (
    <div>
      <Label htmlFor={identifier}>
        {labelText}
        <TextAreaField
          tabIndex="0"
          rows={rows}
          name={`${identifier}_Field`}
          id={identifier}
          onChange={(e) => handleChange(e)}
          value={inputValue}
        />
      </Label>
    </div>
  )
}

TextArea.propTypes = {
  identifier: PropTypes.string,
  labelText: PropTypes.string,
  onInputChange: PropTypes.func,
  rows: PropTypes.string,
}

export default TextArea
