import styled from 'styled-components'

import { query } from '../../theme'

const Search = styled.div`
  margin-bottom: 2rem;
  form {
    position: relative;
    input {
      width: 100%;
      display: block;
      font-size: 16px;
      color: #373737;
      border:0;
      padding: 1.2rem;
      box-shadow: 0 2px 10px 0 #ADB7C5;
      border-radius: 5px;
      &:focus {
        box-shadow: 0 2px 10px 0 rgba(0,0,0,.4);
      }
      ${query.extraSmall`
          font-size: 14px;
      `}
    }
    button {
      border: 0;
      padding: 0;
      background: transparent;
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      top: 25%;
      right: 3%;
      z-index: 10;
      cursor: pointer;
    }
  }
`

export default Search
