import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import LearnHero from '../components/LearnHero'
import CallbackForm from '../components/CallbackForm'

class Callback extends Component {
  data = {
    data: {
      acf: {
        learn_heading: 'Request a call back',
        learn_hero_image: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/11/27092317/callback_hero_image.png',
        learn_popular_questions: [
          {
            ID: 43,
            post_title: 'What is Life Insurance?',
            post_name: 'what-is-life-insurance',
            post_type: 'learn',
          },
          {
            ID: 40,
            post_title: 'What affects the cost of your Life Insurance',
            post_name: 'what-affects-the-cost-of-your-life-insurance-beagle-street',
            post_type: 'learn',
          },
        ],
      },
    },
  }

  render() {
    const { callbackData, general } = this.props
    return (
      <div className="page-margined">
        <LearnHero {...this.data} showSearch={false} />
        <CallbackForm data={callbackData} ultraUrl={general} ppcBool={false} />
      </div>
    )
  }
}

Callback.propTypes = {
  callbackData: PropTypes.object,
  general: PropTypes.object,
}

const mapStateToProps = state => ({
  callbackData: state.callback.data,
  general: state.api.general,
})

export default connect(mapStateToProps, null)(Callback)
