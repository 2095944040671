import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import {
  Col,
  Row,
} from '../Grid'
import {
  Container,
  Icon,
  TopHeading,
  TopText,
  ButtonStyled,
  WrapperStyled,
  TopContentStyled,
} from './style'
import { 
  isUndefinedOrNull, 
  removeHtmlTagFromString 
} from '../../utils'

const IconRowV2 = ({
  content,
  background,
  mainheading,
  toptext,
  addMarginBottom,
  showButton,
  buttonText,
  buttonURL,
}) => {
  const contentBlock = addMarginBottom ? 'content_block' : ""
  return (
  <WrapperStyled data-testid="wrapper" className={`icon_row ${contentBlock}`} background={background}>
    <Container data-testid="container">
      <TopContentStyled>
        <TopHeading>
          {mainheading}
        </TopHeading>
        <TopText>{toptext}</TopText>
      </TopContentStyled>
      <Row>
        {!isUndefinedOrNull(content) && (
          Object.keys(content).map(i => (
            <Col lg="4" md="4" sm="12" xs="12" key={i}>
              <Icon>
                <img src={content[i].icon} alt={content[i].heading} width="50" />
                <strong>{content[i].heading}</strong>
                <p dangerouslySetInnerHTML={{ __html: removeHtmlTagFromString(content[i].text, 'p') }} />
              </Icon>
            </Col>
          ))
        )}
      </Row>
      {showButton && (
        <ButtonStyled data-testid="button-test">
          <Button 
          type="button"
          kind="navy_ghost_rebrand" 
          as="a" 
          href={buttonURL}
          >
            {buttonText}
            <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L6 6L1 11" stroke="#00C2A2" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </Button>
        </ButtonStyled>
        )
      }
    </Container> 
 </WrapperStyled>
)
}

IconRowV2.propTypes = {
  content: PropTypes.object,
  background: PropTypes.string,
  addMarginBottom: PropTypes.bool,
  mainheading: PropTypes.string,
  toptext: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonURL: PropTypes.string,
}

export default IconRowV2