import styled from 'styled-components'
import { query } from '../../theme'

const Paragraph = styled.p`
  font-family: 'Montserrat', sans-serif;
  ${query.combine(['extraSmall', 'small'], `
    text-align: center;
  `)}
`

export default Paragraph
