import * as colors from './colors.js'
import * as query from './mediaQueries'
import container from './container'
import typeScale from './typography'

export default colors

const { theme } = colors

export {
  theme,
  query,
  container,
  typeScale,
}
