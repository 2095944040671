import { isEmptyArray, isUndefinedOrNull } from '../../utils'

const currentDate = new Date()
const currentYear = new Date().getFullYear()
const currentHour = currentDate.getHours()
const currentMin = currentDate.getMinutes()
const closedArr = [`${currentYear}-12-25`, `${currentYear}-12-26`, `${currentYear}-01-01`, `${currentYear + 1}-01-01`]
const currentDayNoTime = currentDate.toISOString().slice(0, 10)
const addDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}
const tomorrowDateNoTime = addDays(currentDate, 1).toISOString().slice(0, 10)
const isTomorrowClosed = closedArr.indexOf(tomorrowDateNoTime) >= 0
const isClosed = closedArr.indexOf(currentDayNoTime) >= 0
const eveningHours = currentHour > 21 || currentHour > 17
const currentDay = currentDate.getDay() >= 1 && currentDate.getDay() < 5 ? 1 : currentDate.getDay()

const getHolidayArrayDatesBasedOffYear = (array, year) => array.filter(o => new Date(o.date)
  .getFullYear() === year).map(o => o.date)

const getMessage = (key) => {
  const message = {
    SundayAM: 'Closed',
    SundayPM: 'Open from 8am to 8pm tomorrow',
    SundayDefault: 'Closed',
    SaturdayAM: 'Open from 9am to 5pm today',
    SaturdayPM: 'Open from 8am to 8pm Monday',
    SaturdayDefault: 'Open until 5pm today',
    FridayPM: 'Open from 9am to 5pm tomorrow',
    weekdayPM: 'Open from 8am to 8pm tomorrow',
    weekdayAM: 'Open from 8am to 8pm today',
    weekdayDefault: 'Open until 8pm tonight',
    bankHoliday: 'Open from 9am to 5pm today',
    bankHolidayTomorrow: 'Open from 9am to 5pm tomorrow',
    closedTomorrow: 'Closed Tomorrow',
    closedToday: 'Closed Today',
  }

  return (isUndefinedOrNull(key) ? null : message[key])
}

const getDayTimeMessaging = (day) => {
  if (isUndefinedOrNull(day)) return false
  const getDayTimeMessage = {
    0: () => {
      if (currentHour < 10) {
        return getMessage('SundayAM')
      }
      return (((currentHour === 15) && (currentMin > 29)) || (currentHour > 16)) ? getMessage('SundayPM') : getMessage('SundayDefault')
    },
    1: () => {
      if (currentHour < 8) {
        return getMessage('weekdayAM')
      }
      return (currentHour >= 18) ? getMessage('weekdayPM') : getMessage('weekdayDefault')
    },
    5: () => {
      if (currentHour < 8) {
        return getMessage('weekdayAM')
      }
      return (currentHour >= 18) ? getMessage('FridayPM') : getMessage('weekdayDefault')
    },
    6: () => {
      if (currentHour < 9) {
        return getMessage('SaturdayAM')
      }
      return (currentHour >= 16) ? getMessage('SaturdayAM') : getMessage('SaturdayDefault')
    },
  }
  return (isUndefinedOrNull(getDayTimeMessage[day]) ? null : getDayTimeMessage[day]())
}

const addMessaging = (events) => {
  if (isEmptyArray(events)) return false
  let message = ''

  const govBankHolidayDates = getHolidayArrayDatesBasedOffYear(events, currentYear)
  // Adding Christmas Eve and New Years Eve to bank holiday dates.
  const customBankHolidayDates = [`${currentYear}-12-24`, `${currentYear}-12-31`]
  const bankHolidaysUpdate = [...govBankHolidayDates, ...customBankHolidayDates]
  const isTomorrowBankHoliday = bankHolidaysUpdate.indexOf(tomorrowDateNoTime) >= 0
  const isBankHoliday = bankHolidaysUpdate.indexOf(currentDayNoTime) >= 0

  if (!isClosed && !isBankHoliday) message = getDayTimeMessaging(currentDay)
  if (!isClosed && isBankHoliday) message = getMessage('bankHoliday')
  if (isClosed) message = getMessage('closedToday')
  if (isTomorrowBankHoliday && eveningHours) message = getMessage('bankHolidayTomorrow')
  if (isTomorrowClosed && eveningHours) message = getMessage('closedTomorrow')
  return message
}
const OpeningHours = (bankHolidays) => (isUndefinedOrNull(bankHolidays)
  ? null : addMessaging(bankHolidays))

export default OpeningHours
