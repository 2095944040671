export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  section: {
    mappedProp: 'section',
  },
  gridItems: {
    mappedProp: 'gridItems',
  },
}
