import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import _ from 'lodash'
import { Row, Col } from '../../Grid'
import Icon from '../../Icons/Icon'
import Search from '../../Search'
import MobileMenu from '../../MobileMenu'
import { DataLayerLink } from '../../DataLayerLink'
import Logo from '../../../assets/img/svg/Logo'
import BeagleHead from '../../../assets/img/svg/BeagleHead'
import { TelephoneNumber } from '../../../common/text'
import { urls } from '../../../common/const'
import { OpeningHoursContext } from '../../../containers/contexts'
import {
  isUndefinedOrNull,
  dataLayerPush,
  dataLayerOnClick,
} from '../../../utils'

import {
  HeaderWrap,
  TopNav,
  HeaderContainer,
  HeaderRight,
  MainNav,
  SubNav,
} from './style'

const HeaderV2 = () => {
  const menus = useSelector(state => state.api.menus)
  const page = useSelector(state => state.api.data)
  const campaignCode = useSelector(state => state.api.general)
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false)
  const [top, setTop] = useState(0)
  const [scroll, setOffset] = useState(0)

  //Updating the scroll value
  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset)
    window.removeEventListener('scroll', onScroll)
    window.addEventListener('scroll', onScroll, { passive: true })
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    let scroll = true
    try {
      const { acf } = page[Object.keys(page)[0]]
      if (acf && acf.hide_header) scroll = false
    } catch (e) {
      throw new Error(e)
    }
    if (scroll) {
      const element = isMobile ? 'header-main' : 'main_nav'
      const el = document.getElementById(element)
      setTop(el.offsetTop)
    }
    return null
  }, [])

  //Adding underline to the Active Nav Item
  useEffect(() => {
    document.querySelectorAll('.nav-links-list a').forEach
      (link => {
        if (link.href === window.location.href) {
          link.classList.add('active')
        }
      })
  }, [])

  const buildMenu = (menus) => {
    if (Object.prototype.hasOwnProperty.call(menus, 'primary') && !isUndefinedOrNull(menus.primary)) {
      const { primary } = menus
      const headerLink = React.createRef()
      return primary.map((item) => (
        <li className='nav-links-list' key={item.ID}>
          <a ref={headerLink} template="header" onClick={(e) => dataLayerOnClick(e)} href={item.url}>{item.title}</a>
        </li>
      ))
    }
    return null
  }

  const toggleMobileMenu = (e) => {
    e.preventDefault()
    window.scrollTo(0, 0)
    setIsMobileMenuVisible(!isMobileMenuVisible)
    dataLayerPush({
      event: 'menuClick',
      menuName: `${{ isMobileMenuVisible } ? 'menuClose' : 'menuOpen'}`,
    })
  }

  const { quickQuote } = urls
  const { acf } = page[Object.keys(page)[0]]

  if (!_.isEmpty(page[Object.keys(page)[0]]) && acf && acf.hide_header) {
    return false
  }

  return (
    <HeaderWrap className={scroll > top ? 'header-main fixed' : 'header-main'} id="header-main">
      <TopNav className='top-nav'>
        <Col className='top-nav-items' md="12" sm="9" xs="10">
          <div className="top-nav-utils">
          {!isMobileMenuVisible && (
            <ul>
              <li className="search"><Search style={{ fill: "#ffffff" }} /></li>
                <li className="signin">
                  <DataLayerLink
                    eventName="ButtonClick"
                    eventKey="buttonName"
                    href="https://quotes.beaglestreet.com/Account/Login?redirectUrl=https://quotes.beaglestreet.com/myaccount/intermediate"
                  >
                    <Icon kind="sign-in" size="15" color="#FFFFFF" /> Sign in
                  </DataLayerLink>
                </li>  
                <li className="retrieve">
                  <DataLayerLink
                    eventName="ButtonClick"
                    eventKey="buttonName"
                    href="https://quotes.beaglestreet.com/RetrieveQuote"
                  >
                    <Icon kind="retrieve-quote" size="15" color="#FFFFFF" /> Retrieve quote
                  </DataLayerLink>
                </li>
            </ul>)}
          </div>
        </Col>
      </TopNav>
      <HeaderContainer className="header-container">
        <Row className="header-row">
          <Col className="beagle-logo" xl="2" lg="1" md="3" sm="3" xs="2">
            <DataLayerLink
              eventName="menuClick"
              eventKey="menuName"
              eventValue="logo"
              href="/"
              ariaLabel="logo-absolute"
            >
              <BeagleHead version="1" />
              <Logo version="1" />
            </DataLayerLink>
          </Col>
          <Col className="nav-col2" xl="10" lg="11" md="9" sm="9" xs="10">
            <HeaderRight className="header-right">
              <MainNav id="main_nav" className="u-clearfix" aria-label="MainNav">
                <div className="utils">
                  <ul>
                    <li className="tel open_times_tooltip"><a href="tel:08000480480" aria-label="Call 0800 048 0480"><Icon kind="phone" size="20" color="#28334A" /><span>{TelephoneNumber}</span></a></li>
                    <li className="getquote">
                      <DataLayerLink
                        eventName="CTAClick"
                        eventKey="CTAName"
                        eventValue={scroll > top ? 'headerCTA 2' : 'headerCTA'}
                        as="a"
                        key="getquote1"
                        data-parent="header"
                        href={quickQuote}
                        kind={scroll > top ? 'purple_solid' : 'purple_rebrand'}
                        checkIndexes={false}
                      >
                        Get a quote
                      </DataLayerLink>
                    </li>
                    <li className="mobile_menu">
                      <button className="mobile-menu-toggle" onClick={(e) => toggleMobileMenu(e)}>
                        {isMobileMenuVisible ? 'Close' : 'Menu'}
                      </button>
                    </li>
                  </ul>
                </div>
              </MainNav>
              <SubNav id="sub-nav" className={scroll > top ? 'fixed animated fadeIn' : 'u-clearfix'} aria-label="SubNav">
                <ul>
                  <span className="beagle-logo-fixed">
                    <DataLayerLink
                      eventName="menuClick"
                      eventKey="menuName"
                      eventValue="logo"
                      href="/"
                      ariaLabel="logo-fixed"
                    >
                      <BeagleHead version="2" />
                      <Logo version= "2"/>
                    </DataLayerLink>
                  </span>
                  {buildMenu(menus)}
                  <li className="nav_utils">
                    <div className="getquote">
                      <DataLayerLink
                        eventName="CTAClick"
                        eventKey="CTAName"
                        eventValue={scroll > top ? 'headerCTA 2' : 'headerCTA'}
                        as="a"
                        key="getquote2"
                        data-parent="header"
                        href={quickQuote}
                        kind={scroll > top ? 'purple_solid' : 'purple_rebrand'}
                        checkIndexes={false}
                      >
                        Get a quote
                      </DataLayerLink>
                    </div>
                    <span className="contact open_times_tooltip">
                      <Icon kind="phone" size="23" color="#28334A" /> <a template="header" onClick={(e) => dataLayerOnClick(e)} href="tel:0800247247">{TelephoneNumber}</a>
                      <OpeningHoursContext.Consumer>
                        {({ openingHoursText }) => (
                          (!isUndefinedOrNull(openingHoursText) ? <span className="open_times_tooltip_text">{openingHoursText}</span> : null)
                        )}
                      </OpeningHoursContext.Consumer>
                    </span>
                  </li>
                </ul>
              </SubNav>
            </HeaderRight>
          </Col>
        </Row>
      </HeaderContainer>
      {!!isMobileMenuVisible && (
        <MobileMenu
          menu={menus.primary}
          campaignCode={campaignCode.campaignCode}
        />
      )}
    </HeaderWrap>
  )
}
export default HeaderV2
