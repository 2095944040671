import styled from 'styled-components'
import { container } from '../../theme'

const Container = styled.div`
  ${container}
`
const SlideItem = styled.div`
  margin: 0 15px;
  @media only screen and (max-width: 480px) {
    margin: 0;
  }
  > div {
    padding: 0;
  }
`

const WrapperStyled = styled.div`
  margin-top: 4rem;
`

export {
  Container,
  SlideItem,
  WrapperStyled,
}
