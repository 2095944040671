import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { cleanHtml, dataLayerPush } from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import {
  Container,
  Heading,
  Content,
  Accordion,
} from './style'

const TextAccordion = (props) => {
  const {
    heading,
    text,
    content,
    anchorid,
  } = props

  const [accordion, setAccordion] = useState(false)

  const toggleAccordion = (e) => {
    e.preventDefault()
    setAccordion(!accordion)
    const { currentTarget: { parentNode, innerText } } = e
    parentNode.classList.toggle('active')
    dataLayerPush({
      event: 'questionClick',
      questionName: `${innerText}`.trim(),
    })
  }

  return (
    <div id={anchorid} className="text_accordion content_block">
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }} md="12" sm="12" xs="12">
            <Heading>{heading}</Heading>
            <Content
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
            />
            {content && (
              <Accordion>
                <ul>
                  {Object.keys(content).map(i => (
                    <li key={i}>
                      <button
                        type="button"
                        onClick={(e) => toggleAccordion(e)}
                        aria-expanded={accordion}
                        aria-controls={`control_${i}`}
                        id={`accordion_${i}`}
                      >
                        <span>{content[i].title}</span>
                      </button>
                      <span
                        className="hidden_content"
                        role="region"
                        id={`control_${i}`}
                        aria-labelledby={`accordion_${i}`}
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{ __html: cleanHtml(content[i].text) }}
                      />
                    </li>
                  ))}
                </ul>
              </Accordion>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

TextAccordion.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  content: PropTypes.object,
  anchorid: PropTypes.string,
}

export default TextAccordion
