import styled from 'styled-components'
import { container } from '../../theme'

const Container = styled.div`
  ${container}
`

const Heading = styled.h2`
 text-align: center;
 color: #28334A;
 font-size: 2em;
 margin: 0 0 2rem 0;
`

const Content = styled.div`
  h2, h3 {
    color: #28334A;
    margin: 0 0 1rem 0;
  }
  p {
    color: #373737;
    margin-top: 0;
    font-size: 1em;
  }
  a {
    color: #9361B0;
    text-decoration: none;
    &:hover {
      color: #000;
    }
  }
  *:last-child {
    margin-bottom: 0;
  }
`

const Accordion = styled.div`
  margin-top: 1rem;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      border-top: #DBDBDB 1px solid;
      &:last-child {
        border-bottom: #DBDBDB 1px solid;
      }
      .hidden_content {
        display: none;
        font-size: .9em;
      }
      button {
        background: none;
        border: none;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        > span {
          width: 100%;
        }

        &:focus {
          outline:0;
        }

        &.hidden_content {
          color: #9361B0!important;
          border-bottom: #DBDBDB 1px solid;
          padding: 0 0 .2rem 0rem!important;
          margin-bottom: 1rem!important;
          text-decoration: none;
          &:hover {
            color: #000!important;
          }
        }
        &:not(.hidden_content) {
          padding: .6rem 0;
          color: #9361B0;
          text-decoration: none;
          &:before {
            background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 294.1L383 167c9.4-9.4 24.6-9.4 33.9 0s9.3 24.6 0 34L273 345c-9.1 9.1-23.7 9.3-33.1.7L95 201.1c-4.7-4.7-7-10.9-7-17s2.3-12.3 7-17c9.4-9.4 24.6-9.4 33.9 0l127.1 127z"/></svg>');
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            content: '';
            color: #373737;
            display: block;
            margin: 0 .5rem 0 0;
            position: relative;
            top: 3px;
          }
          &:hover {
            color: #000;
          }
        }

      }
      &.active {

        button {
          &:not(.hidden_content) {
            &:before {
              background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 217.9L383 345c9.4 9.4 24.6 9.4 33.9 0 9.4-9.4 9.3-24.6 0-34L273 167c-9.1-9.1-23.7-9.3-33.1-.7L95 310.9c-4.7 4.7-7 10.9-7 17s2.3 12.3 7 17c9.4 9.4 24.6 9.4 33.9 0l127.1-127z"/></svg>');
              background-repeat: no-repeat;
            }
          }
          &.hidden_content {
            display: inline-block!important;
            margin-left: 1.2rem;
          }
        }

        .hidden_content {
          display: block;
          margin-bottom: .6rem;
          color: #373737;
          padding-left: 1.2rem;
        }
      }
    }
  }
`

export {
  Container,
  Heading,
  Content,
  Accordion,
}
