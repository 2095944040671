import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import GridItem from './GridItem'
import { isUndefinedOrNull } from '../../utils'
import { ColStyled, RowStyled } from './style'

const IncentiveGrid = ({
  content,
  numberOfItems,
}) => {
  const [listItemColumnSizes, setListItemColumnSizes] = useState({
    lg: 12,
    md: 12,
    sm: 12,
    xs: 12,
  })

  useEffect(() => {
    switch (numberOfItems) {
      case 1:
        setListItemColumnSizes(prevState => ({ ...prevState, lg: 6 }))
        break
      case 2:
        setListItemColumnSizes(prevState => ({ ...prevState, lg: 5 }))
        break
      case 3:
        setListItemColumnSizes(prevState => ({ ...prevState, lg: 4 }))
        break
      case 4:
        setListItemColumnSizes(prevState => ({ ...prevState, lg: 3 }))
        break
      case 5:
        setListItemColumnSizes(prevState => ({ ...prevState, lg: 4 }))
        break
      case 6:
        setListItemColumnSizes(prevState => ({ ...prevState, lg: 4 }))
        break
      default:
        setListItemColumnSizes(prevState => ({ ...prevState, lg: 4 }))
    }
  }, [numberOfItems])

  if(isUndefinedOrNull(content)) return null

  return (
    <>
      <RowStyled>
        {content.map((i, index) => (
          <ColStyled key={index} lg={listItemColumnSizes.lg} md="6" sm="12" xs="12" style={{padding: '0'}} numberOfItems={numberOfItems}>
            <GridItem
              itemCount={numberOfItems}
              icon={i.iconUrl}
              alt={i.iconAltText && i.iconAltText.trim() ? i.iconAltText : ""}
              title={i.title}
              body={i.body}
            />
          </ColStyled>
        ))}
      </RowStyled>
    </>
  )
}

IncentiveGrid.propTypes = {
  content: PropTypes.array.isRequired,
}

export default IncentiveGrid
