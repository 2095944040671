import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { isUndefinedOrNull, isObject } from '../../utils'

// import styles from './style.module.css'
import { ColContainer } from './styles'

const DEVICE_SIZES = ['xl', 'lg', 'md', 'sm', 'xs']

const Col = ({
  xs,
  sm,
  md,
  lg,
  xl,
  children,
  className,
  ...props
}) => {
  const classes = []
  const spans = []
  const sizeObj = {
    xs, sm, md, lg, xl,
  }

  DEVICE_SIZES.forEach(size => {
    const value = sizeObj[size]
    let span
    let offset
    let order

    if (!isUndefinedOrNull(value) && isObject(value)) {
      ({ span = true, offset, order } = value)
    } else {
      span = value
    }

    if (!isUndefinedOrNull(span)) {
      spans.push(
        span === true || size === 'xs' ? `col-${span}` : `col-${size}-${span}`,
      )
    }

    if (!isUndefinedOrNull(offset)) {
      classes.push(
        `offset${size === 'xs' ? '' : `-${size}`}-${offset}`,
      )
    }

    if (!isUndefinedOrNull(order)) {
      classes.push(
        `order${size === 'xs' ? '' : `-${size}`}-${order}`,
      )
    }
  })

  return (
    <ColContainer
      className={classNames('col', className, ...classes, ...spans, '')}
      {...props}
    >
      {children}
    </ColContainer>
  )
}

const colSize = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.number,
  PropTypes.string,
  PropTypes.oneOf(['auto']),
])

const stringOrNumber = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string,
])

const column = PropTypes.oneOfType([
  colSize,
  PropTypes.shape({
    span: colSize,
    order: stringOrNumber,
    offset: stringOrNumber,
  }),
])

Col.propTypes = {
  /**
   * The number of columns to span on extra small devices (<576px)
   *
   * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
   */
  xs: column,
  /**
   * The number of columns to span on small devices (≥576px)
   *
   * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
   */
  sm: column,
  /**
   * The number of columns to span on medium devices (≥768px)
   *
   * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
   */
  md: column,
  /**
   * The number of columns to span on large devices (≥992px)
   *
   * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
   */
  lg: column,
  /**
   * The number of columns to span on extra large devices (≥1200px)
   *
   * @type {(true|"auto"|number|{ span: true|"auto"|number, offset: number, order: number })}
   */
  xl: column,
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
}

export default Col
