/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Col,
  Row,
} from '../Grid'
import Heading from '../Heading'
import { DataLayerLink } from '../DataLayerLink'
import SearchInput from '../SearchInput'

import {
  LearnHeroContainer,
  LearnHeroWrapper,
  Questions,
} from './style'

class LearnHero extends Component {
  generatePopularQuestions = () => {
    const {
      data,
    } = this.props
    return Object.keys(data.acf.learn_popular_questions).map((item) => {
      const {
        post_type,
        post_name,
        post_title,
        ID,
      } = data.acf.learn_popular_questions[item]
      const url = `/${post_type}/${post_name}`
      return (
        <li key={ID}>
          <DataLayerLink
            eventName="textClick"
            eventKey="textName"
            href={url}
            className="flexContainer"
          >
            <span>{post_title}</span>
          </DataLayerLink>
        </li>
      )
    })
  }

  render() {
    const { data, showSearch = true } = this.props
    return (
      <LearnHeroContainer>
        <LearnHeroWrapper>
          <Row>
            <Col xs="12" md="6">
              <div className="content_container">
                <Heading text={data.acf.learn_heading || 'How can we help?'} />
                {showSearch && (
                  <SearchInput
                    action="/search"
                    method="get"
                    id="learn_search"
                    name="s"
                    placeholder="Search e.g. how much cover?"
                    icon="search"
                  >
                    <input type="hidden" name="post_type" value="learn" />
                  </SearchInput>
                )}
                {data.acf.learn_popular_questions && (
                  <Questions>
                    <h2>Popular Questions</h2>
                    <ul>
                      {this.generatePopularQuestions()}
                    </ul>
                  </Questions>
                )}
              </div>
            </Col>
            <Col xs="12" md="6" className="image_container" style={{ backgroundImage: `url(${data.acf.learn_hero_image || ''})` }} />
          </Row>
        </LearnHeroWrapper>
      </LearnHeroContainer>
    )
  }
}

LearnHero.propTypes = {
  data: PropTypes.object,
  showSearch: PropTypes.bool,
}

export default LearnHero
