// Packages
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Body from './Body'
import MainHeading from './MainHeading'
import SubHeading from './SubHeading'
import Grid from './Grid'

const Incentive = ({
  children
}) => <React.Fragment>{children}</React.Fragment>

Incentive.Body = Body
Incentive.MainHeading = MainHeading
Incentive.SubHeading = SubHeading
Incentive.Grid = Grid

Incentive.propTypes = {
  children: PropTypes.object.isRequired,
}

export default Incentive
