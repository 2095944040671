import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import {
  isEmptyObj,
} from '../utils'

import {
  Col,
  Row,
} from '../components/Grid'

import LearnArticle from '../components/LearnArticle'
import LearnArticleSidebar from '../components/LearnArticleSidebar'

import { getlearnAccordion } from '../actions/learn'

class Learn extends Component {
  state = {
    loading: true,
  }

  static getDerivedStateFromProps(props, state) {
    const {
      loadLearnAccordion,
      accordionData,
    } = props
    const { loading } = state

    if (loading) {
      loadLearnAccordion()
    }
    return {
      loading: false,
      accordionData,
    }
  }

  render() {
    const { data, accordionData } = this.props
    return (
      <div className="learn-single backdrop">
        <div className="container">
          <Row>
            <Col lg="4" md="12" sm="12" xs="12" className="sidebar">
              {!isEmptyObj(accordionData.data) && (
                <LearnArticleSidebar data={accordionData} />
              )}
            </Col>
            <Col lg="8" md="12" sm="12" xs="12" className="main_content">
              <LearnArticle data={data} />
            </Col>
          </Row>
        </div>
      </div>
    )
  }
}

Learn.propTypes = {
  data: PropTypes.object,
  accordionData: PropTypes.object,
  loadLearnAccordion: PropTypes.func,
}

const mapStateToProps = state => ({
  accordionData: state.learnAccordion,
})

const mapDispatchToProps = (dispatch) => ({
  loadLearnAccordion: () => dispatch(getlearnAccordion()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Learn)
