import styled from 'styled-components'
// import { query } from '../../theme'

const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 #C9D2DF;
  margin-bottom: 2rem;
`

const CardWrapper = styled.div`
  padding: 1rem;
`
const CardTitle = styled.div`
  .tax {
    font-size: .9em;
    color: #00C2A2;
  }
  h3 {
    margin: .5rem 0;
    line-height: 1.3;
    min-height: 48px;
    a {
      color: #28334A;
      text-decoration: none;
      &:hover {
        color: #000;
      }
    }
  }
`

const CardText = styled.p`
  margin: 0;
  padding: 0;
  font-size: .85em;
  color: #373737;
  line-height: 1.5;
`

const CardImage = styled.div`
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(${props => props.bg});
  width: 100%;
  height: 200px;
  position: relative;
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    text-decoration: none;
  }
`

export {
  Card,
  CardImage,
  CardText,
  CardTitle,
  CardWrapper,
}
