import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  isUndefinedOrNull,
} from '../../utils'

import Img from './style'

const Image = ({
  src,
  dimensions,
  altText,
  responsive,
  srcSet,
  className,
  dataTestId,
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
  })

  if (!loading) return null
  return (
    <Img
      data-testid={dataTestId || null}
      dimensions={dimensions}
      src={src}
      alt={altText}
      responsive={responsive}
      srcSet={!isUndefinedOrNull(srcSet) ? srcSet.join(', ') : null}
      className={className}
    />
  )
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  altText: PropTypes.string.isRequired,
  dimensions: PropTypes.object,
  responsive: PropTypes.bool,
  srcSet: PropTypes.array,
  dataTestId: PropTypes.string,
}

Image.defaultProps = {
  dimensions: {
    width: 'auto',
    height: 'auto',
  },
}

export default Image
