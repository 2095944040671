import axios from 'axios'
import API_MAP from '../services/endpoints'
import OpeningHours from '../components/OpeninigHours'

export const GET_BANKHOLIDAY_REQUEST = 'GET_BANKHOLIDAY_REQUEST'
export const GET_BANKHOLIDAY_SUCCESS = 'GET_BANKHOLIDAY_SUCCESS'
export const GET_BANKHOLIDAY_FAILURE = 'GET_BANKHOLIDAY_FAILURE'

export const getBankHolidays = () => dispatch => {
  dispatch(({ type: GET_BANKHOLIDAY_REQUEST }))
  return axios.get(API_MAP.holidays)
    .then((response) => {
      const { data } = response
      const { events } = data['england-and-wales']
      dispatch({
        type: GET_BANKHOLIDAY_SUCCESS,
        payload: {
          bankHolidays: events,
          openingHoursText: OpeningHours(events),
        },
      })
    }).catch((error) => {
      dispatch({ type: GET_BANKHOLIDAY_FAILURE, error })
    })
}
