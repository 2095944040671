import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`
const Stat = styled.div`
  ${query.combine(['extraSmall', 'small'], `
    margin-bottom: 1rem;
  `)}
  text-align: center;
  span {
    color: rgba(0,194,162,1);
    font-size: 2.1em;
    font-weight: bold;
  }
`
const Text = styled.div`
  color: #28334A;
  font-size: 1.1125em;
`

export {
  Container,
  Stat,
  Text,
}
