import styled from 'styled-components'
import { container, query } from '../../../theme'

const HeaderWrap = styled.header`
  background: #fff;
  width: 100%;
  box-shadow: 0 2px 10px 0 #CED5DF;
  position: relative;
  z-index: 9999;
  &.fixed {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      position: fixed;
      top: 0;
      left: 0;
    `)}
  }
`

const TopNav = styled.div`
height: 27px;
.top-nav-items{
  ${container}
}
position: relative;
width: 100%;
padding:0.2rem;
background: #1D374E;
${query.combine(['extraSmall', 'small', 'medium'], `
display: none;
`)}
text-align: right;
  ${query.combine(['medium'], `
      margin-top: .25rem;
  `)}
  ${query.combine(['extraSmall', 'small'], `
    margin-top: 0;
  `)}
  .top-nav-utils {
    .search {
      button {
        color: #ffffff;
        font-family: Montserrat;
        font-size: 14px;
        line-height: 21px;
        &:focus {
          outline: 1;
        }  
        svg {
          fill: white !important;
          height: auto;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        display: inline-block;
        font-size: 14px;
        margin-left: 1.2rem;
        &.search {
          position: relative;
          > button {
            padding: 0;
            background: transparent;
            border: 0;
            cursor: pointer;
          }
          form {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            .icon {
              margin-top: 2px;
            }
            input {
              border: 0;
              padding: .3rem;
              color: #ffffff;
              &:focus {
                outline: none;
                border-bottom-color: #000;
              }
            }
          }
          .close {
            background: transparent;
            border: 0;
            padding: 0;
            cursor: pointer;
            position: absolute;
            top: 0px;
            right: -10px;
          }
        }
        &.retrieve, &.signin, &.search {
          ${query.combine(['extraSmall', 'small', 'medium'], `
            display: none;
          `)}
          a {
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: #ffffff;
            &:hover {
              color: #ffffff;
              text-decoration:underline;
              text-underline-offset: 2px;
            }
          }
          button {
            &:hover {
              text-decoration:underline;
              text-underline-offset: 2px;
            }
          }
        }
        svg {
          margin-right: 4px;
          fill: #ffffff;
        }
      }
    }
  }
`

const HeaderContainer = styled.div`
  ${query.combine(['extraSmall', 'small', 'medium'], `
    display: flex;
    justify-content: center;
    align-items:center;
  `)}
  ${container}
  height: 72px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  ${query.combine(['extraSmall', 'small', 'medium'], `
    height: 67px;
  `)}
  .header-row{
    ${query.combine(['extraSmall', 'small', 'medium'], `
      width: 100%;
      align-items: center;
  `)}
  }
  .beagle-logo {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      padding: 0px;
    `)}
    display: flex;
    justify-content: flex-start;
    align-items: center;
    a {
      display: flex;
    }
  }
  .nav-col2 {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      padding: 0px;
    `)}
  }
  .beagle-logo-fixed{
    display:none;
  }
  .bslogofull {
    @media (min-width: 0px) and (max-width: 743px) {
      display: none;
    }
    @media (min-width: 743px) and (max-width: 992px) {      
      width: auto;
      height: 45px;    
    }
    @media (min-width: 992px) and (max-width: 1200px) {      
      display: none;
    }
    @media (min-width: 1200px){      
      width: 190px;
      height: 51px;
    }
  }
  #beaglehead-logo,
  .beagleheadHeaderlogo {
    display:none;
    @media (min-width: 0px) and (max-width: 743px) {
      display:block;
      height: 45px;
      width: auto;
    }
    @media (min-width: 743px) and (max-width: 992px) {      
      display: none;
    }
    @media (min-width: 992px) and (max-width: 1200px) {
      display:block;
      height: 51px;
      width: auto;
    }
  }
`

const HeaderRight = styled.div`
  text-align: right;
  ${query.combine(['medium'], `
      margin-top: .25rem;
  `)}
  ${query.combine(['extraSmall', 'small'], `
    margin-top: 0;
  `)}
  .utils {
    .open_times_tooltip {
      position: relative;
      display: none; 
    }
    .open_times_tooltip_text {
      visibility: hidden;
      width: 196px;
      background-color: #fff;
      color: #373737;
      text-align: center;
      border-radius: 5px;
      padding: 15px 0;
      position: absolute;
      z-index: 1;
      top: 150%;
      left: 50%;
      margin-left: calc(-50% - -7px);
      margin-top: 4px;
      font-size: 6px;
      box-shadow: 0 2px 10px 0 #DBE1EB;
    }
    .open_times_tooltip .open_times_tooltip_text::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 0px;
      margin-left: calc(50% - 15px);
      border-width: 15px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }
    .open_times_tooltip:hover .open_times_tooltip_text {
      visibility: visible;
    }
    .search {
      button {
        color: #373737;
        font-family: Montserrat;
        font-size: 14px;
        line-height: 21px;
        &:focus {
          outline: none;
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        .mobile-menu-toggle {
          all: unset;
          cursor: pointer;
        }
        display: inline-block;
        font-size: 14px;
        margin-left: 1.2rem;
        ${query.combine(['extraSmall', 'small'], `
          margin-left: 1rem;
        `)}
        ${query.large`
          font-size: 13px;
        `}
        &:first-child {
          margin-left: 0;
        }
        svg {
          vertical-align: middle;
          margin-top:-4px;
          margin-right: .1rem;
        }
        &.getquote {
          a {
            width: 132px;
            font-size:13px;
          }
          display: none;  
          ${query.combine(['extraSmall', 'small', 'medium'], `
          font-size: 14px;
          display: inline-block;
          `)}
          button, a[type='button'] {
            ${query.combine(['extraSmall', 'small'], `
            font-size: 14px;  
            padding-left: 1rem;
            padding-right: 1rem;
            `)}
            ${query.large`
                font-size: 14px;
            `}
          }
        }
        &.tel {
          visibility: hidden;
          ${query.combine(['extraLarge', 'large'], `
            display: none!important;
            visibility: visible;
          `)}
          ${query.combine(['small', 'medium'], `
            display: inline-block!important;
            visibility: visible;
          `)}
          ${query.combine(['extraSmall'], `
            display: none!important;
            visibility: visible;
          `)}
          ${query.combine(['extraSmall', 'small', 'medium'], `
            display: inline-block!important;
            visibility: visible;
          `)}
          svg {
            ${query.combine(['extraSmall', 'small'], `
              width: 25px;
              height: 25px;
              visibility: visible;
            `)}
          }
          a {
            color: #373737;
            text-decoration: none;
            span {
              font-size: 16px;
              ${query.combine(['extraSmall', 'small'], `
                display: none;
              `)}
            }
            span.open_times_tooltip_text {
              font-size: 14px;
              left: 17%;
              margin-top: 13px;
            }
          }
        }
        &.mobile_menu {
          font-weight: bold;
          display: none;
          border-bottom: #00C2A2 2px solid;
          height: 2em;
          ${query.combine(['extraSmall', 'small', 'medium'], `
            display: inline-block;
          `)}
          a {
            color: #373737;
            text-decoration: none;
          }
        }
        &.search {
          position: relative;
          > button {
            padding: 0;
            background: transparent;
            border: 0;
            cursor: pointer;
          }
          form {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            .icon {
              margin-top: 2px;
            }
            input {
              border: 0;
              padding: .3rem;
              color: #373737;
              &:focus {
                outline: none;
                border-bottom-color: #000;
              }
            }
          }
          .close {
            background: transparent;
            border: 0;
            padding: 0;
            cursor: pointer;
            position: absolute;
            top: 2px;
            right: -10px;
          }
        }
        &.retrieve, &.signin, &.search {
          ${query.combine(['extraSmall', 'small', 'medium'], `
            display: none;
          `)}
          a {
            text-decoration: none;
            color: #373737;
            &:hover {
              color: #000;
            }
          }
        }
      }
    }
  }
`

const MainNav = styled.nav`
  top: 0px;
  @media screen and (max-width: 991px) {
    margin-top: -2px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
  @media screen and (max-width: 575px) {
    margin-top: -4px;
  }
  @media screen and (max-width: 375px) {
    margin-top: 0;
  }
  ${query.combine(['extraSmall', 'small', 'medium'], `
      padding-top: 0;
  `)}
  &.fixed {
    text-align: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    padding: .65rem 0;
    animation-duration: .5s;
    > ul {
      ${container}
      display: inline-block;
      margin-right: 1.6rem;
      ${container}
      > li {
        ${query.large`
          margin-right: 1.2rem;
        `}
        &:nth-child(1) {
          display: none;
        }
        &.beaglehead {
          display: inline-block;
        }
        &.nav_utils {
          text-align: center;
          .quote {
            a {
              display: none;
            }
            button {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  > ul {
    display: inline-block;
    > li {
      display: inline-block;
      margin-right: 1.6rem;
      font-size: 16px;
      ${query.large`
          font-size: 14px;
      `}
      &.active {
        > a {
          border-bottom-color: #00C2A2;
        }
      }
      &:last-child {
        margin-right: 0;
      }
      > a {
        color: #373737;
        text-decoration: none;
        border-bottom: transparent 3px solid;
        padding-bottom: .1rem;
        transition: all 0.2s ease;
        &:hover {
          border-bottom-color: #00C2A2;
          font-weight: 500;
        }
        &.active {
          border-bottom-color: #00C2A2;
        }
      }
      &.beaglehead {
        display: none;
        img {
          width: 35px;
          height: 34px;
          vertical-align: middle;
          display: inline-block;
        }
        > a {
          border: 0!important;
          padding-bottom: 0!important;
        }
      }
      &.nav_utils {
        .contact {
          font-weight: 600;
          font-size: 20px;
          ${query.large`
            font-size: 16px;
            margin-top: 2.5px;
          `}
          a {
            color: #28334A;
            text-decoration: none;
          }
          svg {
            display: none;
            vertical-align: middle;
            margin-top: -2px;
            ${query.large`
              width: 20px;
            `}
          }
        }
        .open_times_tooltip {
          position: relative;
          display: inline-block;
        }
        .open_times_tooltip_text {
          visibility: hidden;
          width: 196px;
          background-color: #fff;
          color: #373737;
          text-align: center;
          border-radius: 5px;
          padding: 10px 0;
          position: absolute;
          z-index: 1;
          top: 150%;
          left: 36%;
          margin-left: calc(-50% - -7px);
          margin-top: 4px;
          font-size: 14px;
          box-shadow: 0 2px 10px 0 #DBE1EB;
          left: 18%;

        }
        .open_times_tooltip .open_times_tooltip_text::after {
          content: "";
          position: absolute;
          bottom: 100%;
          left: 0px;
          margin-left: calc(50% - 15px);
          border-width: 15px;
          border-style: solid;
          border-color: transparent transparent #fff transparent;
        }
        .open_times_tooltip:hover .open_times_tooltip_text {
          visibility: visible;
        }
        .quote {
          display:none;
          margin-left: 1rem;
        }
      }
    }
  }
`

const SubNav = styled(MainNav)` 
  ${query.combine(['extraSmall', 'small', 'medium'], `
    display: none;
  `)}
  &.fixed{
    background: rgb(255, 255, 255);
    box-shadow: rgb(206, 213, 223) 0px 2px 10px 0px;
    .contact {
      margin-top : 2.5px;
    }
    .beagle-logo-fixed {
      position: absolute;
      display: flex;
      padding-top: 4px;
      justify-content: flex-start;
      align-items: center;
      @media (min-width: 0px) and (max-width: 1200px) {
        padding-top: 2px;
      }
      a {
        display: flex;
      }
    }
    ul {
      text-align: right;
      li {
        &.nav-links-list {
          margin-right: 25.6px;
        }
      }
    }
  }
  ul {
    line-height: 1;
    vertical-align: middle;
    li {
      vertical-align: middle;
      .nav-link-list{
        margin-left:32px;
      }
      .getquote > a {
        width: 132px;
        height: 35px;
        border-radius: 37px;
        font-size:13px;
      }
      .contact > a {
        font-size: 16px;
      }
      &.nav_utils {
        text-align: center;
      }
    }
  }
  #beaglehead-logo{
    display: block;
  }
`

export {
  HeaderWrap,
  TopNav,
  HeaderContainer,
  HeaderRight,
  MainNav,
  SubNav,
}
