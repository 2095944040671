const FooterDisclaimerText = { 
  firstLine: 'Beaglestreet.com. All rights reserved. For quotes obtained before 19 September 2023: Beagle Street is a trading name of BGL Direct Life Limited, registered in England & Wales no. 7810405 registered office 16-17 West Street, Brighton BN1 2RL, an appointed representative of BISL Limited, registered in England & Wales no. 3231094, registered office Fusion House, Katharine Way, Bretton, Peterborough, PE3 8BG. BISL Limited, is authorised and regulated by the Financial Conduct Authority.', 
  secondLine: 'For quotes obtained from 19 September 2023: Family Assurance Friendly Society Limited, trading as Beagle Street, is a friendly society registered and incorporated under the Friendly Societies Act 1992, registration number 939F, registered office 16-17 West Street, Brighton, BN1 2RL, is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority.'
}

const TelephoneNumber = '0800 048 0480'

export {
  FooterDisclaimerText,
  TelephoneNumber,
}
