import React from 'react'
import { ConfigContext } from '../../containers/contexts'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import { removeHtmlTagFromString } from '../../utils'

import {
  HeroImgStyled,
  HeroOverlayStyled,
  HeroSectionStyled,
  HeroHeading,
  HeroList,
  HeroOverlayContentStyled,
  HeroParagraphStyled,
} from './style'

const Hero = ({
  images,
  showAffiliateImage,
  affiliateImage,
  affiliateImageAltText,
  headingFirstLine,
  headingSecondLine,
  cssStyles,
  showListItems,
  listItems,
  body,
  showButton,
  buttonText,
  showTallVersion,
}) => {
  return (
    <HeroSectionStyled className={cssStyles.add_margin_bottom ? 'content_block' : ""} showTallVersion={showTallVersion}>
      <HeroOverlayStyled data-testid="heroOverlay" images={images} showTallVersion={showTallVersion} width={cssStyles.overlay_width_desktop}>
        <HeroOverlayContentStyled>
          {showAffiliateImage && affiliateImage && (
           <img src={affiliateImage} alt={affiliateImageAltText} />
          )}               
          <HeroHeading width={cssStyles.heading_width_desktop}>{headingFirstLine}{headingSecondLine && <span>{headingSecondLine}</span>}</HeroHeading>
            {showListItems ? (
              <HeroList>
                {listItems.map(item => {
                  const {
                    list_item_icon,
                    list_item_text,
                } = item
                return <li key={list_item_text}><img src={list_item_icon} alt="" /><span>{list_item_text}</span></li>
                })}
              </HeroList>
              ) : (
              <HeroParagraphStyled text={removeHtmlTagFromString(body, 'p')} />
            )}
          {showButton &&
            <ConfigContext.Consumer>
              {({ general: { quoteUrl } }) => (
                <Button
                  type="button"
                  kind="purple_solid_rebrand"
                  size="large"
                  as="a"
                  href={quoteUrl}
                >
                  {buttonText}
                </Button>
              )}
            </ConfigContext.Consumer>
          }
        </HeroOverlayContentStyled>
      </HeroOverlayStyled>
      <HeroImgStyled data-testid="heroImage" images={images} showTallVersion={showTallVersion} />
    </HeroSectionStyled >
  )
}

Hero.propTypes = {
  images: PropTypes.object,
  showAffiliateImage: PropTypes.bool,
  affiliateImage: PropTypes.string,
  affiliateImageAltText: PropTypes.string,
  headingFirstLine: PropTypes.string,
  headingSecondLine: PropTypes.string,
  showListItems: PropTypes.bool,
  listItem: PropTypes.array,
  text: PropTypes.string,
  showButton: PropTypes.bool,
  buttonText: PropTypes.string,
}

export default Hero