import React from 'react'
import PropTypes from 'prop-types'
import { removeHtmlTagFromString } from '../../utils'

import {
  cleanHtml,
} from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import Image from '../Image'

import {
  Container,
  LR,
  ParagraphStyled,
  EyebrowTextStyled,
  ListStyled,
  MainHeadingL2Styled,
  MainHeadingL3Styled,
  EyebrowH2Styled,
  ListIcon,
} from './style'

const Docs = ({
  background,
  image,
  imageAltText,
  imagepos,
  showtoptext,
  toptext,
  toptextlink,
  mainheading,
  text,
  iconcontent,
  iconPosition,
  anchorid,
  addMarginBottom
}) => {
 
  const pad = background ? '3.5rem 0' : '0'
  const altText = imageAltText?.trim() || ""
  const contentBlock = addMarginBottom ? 'content_block' : ''

  return (
    <div id={anchorid} className={`lr_content ${contentBlock}`} style={{ backgroundColor: background || '#fff', padding: pad }}>
      <Container>
        <LR>
          <Row>
            {imagepos === 'left' && (
              <Col lg="6" sm="12" xs="12" className="image_col left">
                <Image src={image.url} responsive altText={altText} />
              </Col>
            )}
            <Col lg="6" sm="12" xs="12">
              {showtoptext ? (
                  <>
                    <EyebrowH2Styled>
                      <EyebrowTextStyled href={toptextlink}>
                        <span>{toptext}</span>
                      </EyebrowTextStyled>
                    </EyebrowH2Styled>
                    <MainHeadingL3Styled>{mainheading}</MainHeadingL3Styled>
                  </>
                ) : (
                  <MainHeadingL2Styled>{mainheading}</MainHeadingL2Styled>
                )
              }
              <ParagraphStyled text={removeHtmlTagFromString(text, 'p')} />
              {iconcontent && (
                <ListStyled>
                  {Object.keys(iconcontent).map(i => (
                    <li key={i}>
                      <a href={iconcontent[i].cdn_url || iconcontent[i].file} target="_blank">
                        <ListIcon src={iconcontent[i].icon.sizes.large} alt="" position={iconPosition} />
                        <span
                          // eslint-disable-next-line
                          dangerouslySetInnerHTML={{ __html: cleanHtml(removeHtmlTagFromString(iconcontent[i].text, 'p')) }}
                        />
                      </a>
                    </li>
                  ))}
                </ListStyled>
              )}
            </Col>
            {imagepos === 'right' && (
              <Col lg="6" sm="12" xs="12" className="image_col right">
                <Image src={image.url} responsive altText={altText} />
              </Col>
            )}
          </Row>
        </LR>
      </Container>
    </div>
  )
}


Docs.propTypes = {
  background: PropTypes.string,
  anchorid: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.bool,
  ]),
  imageAltText: PropTypes.string,
  imagepos: PropTypes.oneOf(['left', 'right']),
  toptext: PropTypes.string,
  showtoptext: PropTypes.bool,
  toptextlink: PropTypes.string,
  mainheading: PropTypes.string,
  text: PropTypes.string,
  iconcontent: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
    PropTypes.bool,
  ]),
  iconPosition: PropTypes.object,
  addMarginBottom: PropTypes.bool,
}

export default Docs
