import axios from 'axios'

const axiosPost = async (url, req) => {
  try {
    const resp = await axios.post(url, req)
    return resp
  } catch (err) {
    throw Error(err)
  }
}

export default axiosPost
