import { buildQueryStr } from '../utils'

import { CALL_API } from '../middleware/callAPIMiddleware'
import API_MAP from '../services/endpoints'

const GET_POSTS_REQUEST = 'GET_POSTS_REQUEST'
const GET_POSTS_SUCCESS = 'GET_POSTS_SUCCESS'
const GET_POSTS_ERROR = 'GET_POSTS_ERROR'

const MORE_POSTS = 'MORE_POSTS'

export const getPosts = (keyPhrase, obj) => ({
  [CALL_API]: {
    url: `${API_MAP[obj.api]}${obj.url}?${buildQueryStr(obj.params)}`,
    method: 'GET',
    types: [
      GET_POSTS_REQUEST,
      GET_POSTS_SUCCESS,
      GET_POSTS_ERROR,
    ],
    key: keyPhrase,
  },
  type: GET_POSTS_REQUEST,
})

export const morePosts = (obj) => ({
  type: MORE_POSTS,
  obj,
})

export {
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POSTS_ERROR,
  MORE_POSTS,
}
