export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  background: {
    mappedProp: 'background',
  },
  heading: {
    mappedProp: 'heading',
  },
  points: {
    mappedProp: 'list',
  },
  button: {
    mappedProp: 'buttonText',
  },
  button_link: {
    mappedProp: 'buttonLink',
  },
  show_trust_pilot_rating: {
    mappedProp: 'showTrustPilot',
  },
  show_affiliate: {
    mappedProp: 'showAffiliate',
  },
  affiliate_text: {
    mappedProp: 'affiliateText',
  },
  affiliate_image: {
    mappedProp: 'affiliateImage',
  },
  scroll_text: {
    mappedProp: 'scrollText',
  },
  scroll_icon: {
    mappedProp: 'scrollIcon',
  },
  left_image: {
    mappedProp: 'leftImage',
  },
  right_image: {
    mappedProp: 'rightImage',
  },
  mobile_image: {
    mappedProp: 'mobileImage',
  },
}
