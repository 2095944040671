import styled from 'styled-components'
import { query } from '../../theme'

const SearchContainer = styled.div`

  position: relative;
  > button {
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
  }
  form {
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 21px;
    border-bottom: 1px solid #ffffff;
    input {
      height: 18px;
      background: #1D374E ;
      border-bottom: #DBDBDB 1px solid;
      padding: .3rem;
      color: #373737;
      &:focus {
        outline: none;
        border-bottom-color: #000;
      }
    }
    input:-webkit-autofill {
    -webkit-box-shadow:0 0 0 50px #1D374E inset; /* Change the color to your own background color */
    -webkit-text-fill-color: #ffffff;
}
    button {
      background: transparent;
      border: 0;
      padding: 0;
      cursor: pointer;      
    }
  }
  .close {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 2px;
    right: -10px;
  }
  .search {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      display: none;
    `)}
  }
  .searchHidden{
    visibility:hidden; 
  }
  
`

export default SearchContainer
