/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import { callbackFormCopy } from '../../common/const'
import {
  getDates,
  getTimes,
  onlyAllowNumber,
  validatePhone,
  isUndefinedOrNull,
  getNearestHalfHourTimeString,
} from '../../utils'
import CallbackMessage from '../CallbackMessage'
import BenefitsTable from '../BenefitsTable'
import { Row, Col } from '../Grid'
import Form from './Form'
import {
  CallbackFormContainer,
} from './style'
import ReCaptchaContext from '../../containers/contexts/ReCaptchaContext'
import axiosPost from '../../utils/api'

class CallbackForm extends Component {
  state = {
    firstName_Field: '',
    lastName_Field: '',
    phoneNumber_Field: '',
    needHelpWith: '',
    dayForCallback: '',
    preferredTime: '',
    pleaseSpecify_Field: '',
    policyNumber_Field: '',
    showPolicyNumber: false,
    showPleaseSpecify: false,
    submitted: false,
    showErrorMessage: false,
    showSuccessMessage: false,
    isErrorMessage: false,
    submitRedirect: undefined,
    error: false,
    isCaptchaValid: false,
    recaptchaToken: '',
  }

  componentDidMount() {
    if (typeof window === 'object') {
      window.recapchacallback = (response) => {
        if (response !== '') {
          this.setState({
            isCaptchaValid: true,
            recaptchaToken: response,
          })
        }
      }
    }
  }

  errorCallback = () => {
    this.setState({
      submitRedirect: true,
      error: true,
    })
  }

  postRequest = () => {
    const {
      dayForCallback,
      preferredTime,
      recaptchaToken,
      firstName_Field,
      lastName_Field,
      phoneNumber_Field,
      needHelpWith,
      policyNumber_Field,
      pleaseSpecify_Field,
    } = this.state

    // here send correct data depending on bool of ppc or normal callback.
    const { ultraUrl: { config: { ultraUrl } }, ppcBool } = this.props

    const callbackObj = {
      date: dayForCallback,
      time: preferredTime,
      captcha: recaptchaToken,
      FirstName: firstName_Field,
      Surname: lastName_Field,
      TelephoneNumber: phoneNumber_Field,
      Reason: needHelpWith,
      PolicyNumber: policyNumber_Field,
      Comments: pleaseSpecify_Field,
    }

    const dataLayer = typeof window === 'undefined' ? [] : window.dataLayer

    // send request to return correct data for ultra
    axiosPost('/api/callback/send', callbackObj)
      .then(async (res) => {
        const resp = JSON.parse(res.config.data)
        const {
          FirstName,
          Surname,
          TelephoneNumber,
          Reason,
          PolicyNumber,
          Comments,
          date,
          time,
        } = resp

        // convert date from unix timestamp to format needed by ultra
        const convertedDate = new Date(date * 1000)
        const isoString = convertedDate.toISOString().substr(0, 10)

        // substring time to correct format - 00:00
        const timeLength = time.length > 7 ? 5 : 4
        const convertedTime = time.substr(0, timeLength)

        const ultraObj = {
          FirstName,
          Surname,
          TelephoneNumber,
          Reason,
          PolicyNumber, // can be null or empty string if not supplied by user
          Comments,
          Date: isoString,
          TimeSlot: convertedTime,
        }

        // if 1st request successful, send to ultra endpoint (set state based on response to show success/error page).
        await axiosPost(ultraUrl, ultraObj)
          .then(() => {
            this.setState({
              submitRedirect: true,
            })
            if (ppcBool) {
              dataLayer.push({
                event: 'phoneQuote',
                QuoteCallBack: 'CallbackSubmitted',
              })
            }
          })
          .catch(() => {
            this.setState({
              submitRedirect: true,
              error: true,
            })
            if (ppcBool) {
              dataLayer.push({
                event: 'phoneQuote',
                QuoteCallBack: 'CallbackError',
              })
            }
          })
      })
      .catch(() => {
        // set error if first request fails.
        this.setState({
          submitRedirect: true,
          error: true,
        })
      })
  }

  validateCaptchaResponse = () => {
    const isCaptchaResponse = document.querySelector('#g-recaptcha-response')
    if (!isUndefinedOrNull(isCaptchaResponse)) {
      const { value } = isCaptchaResponse

      if (value !== '') {
        this.setState({ isCaptchaValid: true, recaptchaToken: value }, () => {
          this.submitFormData()
        })
      }
    }
  }

  isReCaptchaVisible = () => {
    const { errorCallback } = this
    if (typeof window === 'object') {
      const element = document.querySelector('.g-recaptcha')
      if (!isUndefinedOrNull(element)) {
        const { childNodes: { length } } = element
        if (length === 0) {
          axios.post('/api/error', {
            text: 'Google reCaptcha has not loaded on a page.',
          }).catch((error) => {
            errorCallback(error)
          })
          errorCallback()
        }
      }
    }
  }

  submitFormData = () => {
    const {
      firstName_Field,
      lastName_Field,
      phoneNumber_Field,
      policyNumber_Field,
      needHelpWith,
      dayForCallback,
      preferredTime,
      isCaptchaValid,
    } = this.state
    if (isCaptchaValid) {
      if (this.validatePhoneNumber(phoneNumber_Field)) {
        if (
          (firstName_Field &&
            lastName_Field &&
            phoneNumber_Field &&
            needHelpWith &&
            dayForCallback &&
            preferredTime) !== '') {
          if (needHelpWith === 'Discuss my policy') {
            if (policyNumber_Field !== '') {
              this.postRequest()
            }
          } else {
            this.postRequest()
          }
        }
      }
    }
  }

  handleSubmit = (e) => {
    const { ppcBool } = this.props
    e.preventDefault()

    window.scrollTo(0, 0)

    if (ppcBool) {
      this.setState({
        dayForCallback: Date.parse(new Date()) / 1000,
        preferredTime: getNearestHalfHourTimeString(new Date()),
        needHelpWith: 'Lead Gen',
      })
    }

    this.setState({ submitted: true })
    this.isReCaptchaVisible()
    this.validateCaptchaResponse()
  }

  onChange = (value, name) => {
    this.setState({ [name]: value })

    if (name === 'needHelpWith' && value === 'Discuss my policy') {
      this.setState({ showPolicyNumber: true })
    } else if (name === 'needHelpWith' && value !== 'Discuss my policy') {
      this.setState({ showPolicyNumber: false })
    }

    if (name === 'needHelpWith' && value === 'Other') {
      this.setState({ showPleaseSpecify: true })
    } else if (name === 'needHelpWith' && value !== 'Other') {
      this.setState({ showPleaseSpecify: false })
    }
  }

  showErrorMessage = (field) => {
    const { state, state: { submitted, phoneNumber_Field } } = this

    if (submitted && (field === 'phoneNumber_Field' && !this.validatePhoneNumber(phoneNumber_Field))) {
      return true
    }

    if (submitted && (state[field] === '' || state[field] === 'Please select')) {
      return true
    }

    if (submitted && (state[field] === false)) {
      return true
    }

    return null
  }

  isValidNumber = e => onlyAllowNumber(e)

  validatePhoneNumber = num => validatePhone(num)

  render() {
    const {
      showPolicyNumber,
      showPleaseSpecify,
      submitRedirect,
      error,
      dayForCallback,
    } = this.state

    // destruct error messages from copy
    const {
      errorMessage,
      formText: {
        heading,
        ppcHeading,
        ppcDisclaimer,
        helpWith,
        images,
      },
    } = callbackFormCopy

    const { data: { availableDays } = { availableDays: [] } } = this.props

    const { ppcBool } = this.props

    const {
      showErrorMessage,
      onChange,
      handleSubmit,
      isValidNumber,
    } = this

    let display

    const view = ppcBool ? (
      <Row>
        <Col xs="12" md="6">
          <Form
            onChange={onChange}
            isValidNumber={isValidNumber}
            errorMessage={errorMessage}
            image={images.form}
            showPolicyNumber={showPolicyNumber}
            showPleaseSpecify={showPleaseSpecify}
            getDates={availableDays && getDates(availableDays)}
            getTimes={availableDays && getTimes(availableDays, dayForCallback)}
            showErrorMessage={showErrorMessage}
            helpWith={helpWith}
            handleSubmit={handleSubmit}
            heading={ppcBool ? ppcHeading : heading}
            disclaimer={ppcDisclaimer}
            ppcBool={ppcBool}
          />
        </Col>
        <Col xs="12" md="6">
          <BenefitsTable
            heading="Why choose Beagle Street"
          />
        </Col>
      </Row>
    ) : (
      <Form
        onChange={onChange}
        isValidNumber={isValidNumber}
        errorMessage={errorMessage}
        image={images.form}
        showPolicyNumber={showPolicyNumber}
        showPleaseSpecify={showPleaseSpecify}
        getDates={availableDays && getDates(availableDays)}
        getTimes={availableDays && getTimes(availableDays, dayForCallback)}
        showErrorMessage={showErrorMessage}
        helpWith={helpWith}
        handleSubmit={handleSubmit}
        heading={ppcBool ? ppcHeading : heading}
        disclaimer={ppcDisclaimer}
        ppcBool={ppcBool}
      />
    )

    if (!submitRedirect) {
      display = view
    } else {
      display = <CallbackMessage ppc={ppcBool} error={error} />
    }

    return (
      <ReCaptchaContext.Provider value={{ errorCallback: this.errorCallback, recapchacallback: 'recapchacallback' }}>
        <CallbackFormContainer>
          {display}
        </CallbackFormContainer>
      </ReCaptchaContext.Provider>
    )
  }
}

CallbackForm.propTypes = {
  data: PropTypes.object,
  ultraUrl: PropTypes.object,
  ppcBool: PropTypes.bool,
}

export default CallbackForm
