import styled, { keyframes } from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const Banner = styled.div`
  background: #FFFFFF;
  padding-top: 3rem;
  margin-bottom: .5rem;
`

const UnorderedList = styled.ul`
  padding-left: 0;

  ${query.combine(['small', 'extraSmall'], `
    margin-top: 0;
  `)}

`

const Text = styled.div`
  h1 {
    margin: 0 0 1.5rem 0;
    color: #28334A;
    font-size: 48px;
    height: 58px;
    font-weight: bold;
    line-height: 58px;
  }
  p {
    color: #373737;
    margin: 0 0 1.5rem 0;
    max-width: 312px;
    font-size: 18px;
    line-height: 27px;
  }
`

const progressBarWidth = (barWidth) => keyframes`
  0% { width: 0%; background-color: #00C2A2; }
  100% {width: ${barWidth}%; background-color: #00C2A2;}
`

const ListItem = styled.div`
  flex: 0 1 auto;
  align-self: auto;
  font-size: 18px;
  line-height: 27px;
  ${query.combine(['small', 'extraSmall'], `
    font-size: 14px;
    line-height: 21px;
  `)}
  &:nth-child(1){
    flex: 0 0 80px;
    // overflow: hidden;
    ${query.combine(['small', 'extraSmall'], `
      flex: 0 0 70px;
    `)}
  }
  &:nth-child(2){
    flex: 1 1 auto;
    padding: 0 30px;
    ${query.combine(['small', 'extraSmall'], `
      padding: 0 15px;
    `)}
    div {
      position: relative;
      background-color: #F3F5F8;
      width: 100%;
      display: inline-block;
      vertical-align: middle;
      height: 7px;
      div {
        display: block;
        animation: ${props => progressBarWidth(props.progress)} 3s ease-in-out forwards;
      }
    }
  }
  &:nth-child(3){
    flex: 0 0 40px;
  }
`

const List = styled.li`
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  padding-left: 0px;
  margin-bottom: 10px;
  div {
    padding-left: 0px;
  }
`

export {
  Container,
  Banner,
  Text,
  ListItem,
  List,
  UnorderedList,
}
