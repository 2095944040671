import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '../Button'
import { TrustPilotContext, ConfigContext } from '../../containers/contexts'
import { Trustpilot } from '../Trustpilot'
import Image from '../Image'
import HighlightsList from '../HighlightsList'

import {
  findValue,
  cleanHtml,
  isUndefinedOrNull,
  isEmptyArray,
} from '../../utils'
import {
  HeroContainer,
  HeroWrapper,
  Heading,
  Text,
  SmallTitle,
} from './style'

const HeroImage = ({
  heading,
  subheading,
  text,
  image,
  affiliateImage,
  smallTitle,
  showbutton,
  showTrustpilotRating,
  highlightsList,
  ppc = false,
}) => {
  const affiliateImages = {
    topcashback: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/09/10090525/bs-topcashback.png',
    quidco: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/09/10093031/bs-quidco.png',
  }

  const classes = []
  if (affiliateImage) classes.push('affiliate')
  if (showTrustpilotRating) classes.push('trustpilot')
  if (ppc) classes.push('ppc')

  return (
    <div className="hero content_block">
      <ConfigContext.Consumer>
        {({ general, general: { config } }) => (
          <HeroContainer
            image={typeof image === 'object' ? findValue('url', image) : image}
            className={classes.join(' ')}
            ppc={ppc}
          >
            <HeroWrapper>
              <div className="table_outer">
                <div className="table_inner_mid">
                  {affiliateImage && (
                    <Image src={affiliateImage} altText="" />
                  )}

                  {(!isUndefinedOrNull(config) && {}.hasOwnProperty.call(config.params, 'affiliateimg')) && (
                    <Image src={affiliateImages[config.params.affiliateimg]} altText="" />
                  )}

                  {smallTitle && (
                    <SmallTitle>
                      {smallTitle}
                    </SmallTitle>
                  )}
                  <Heading>
                    {heading}
                    <span> {subheading}</span>
                  </Heading>
                  {text && (
                    <Text
                      // eslint-disable-next-line
                      dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
                    />
                  )}
                  {highlightsList && !isEmptyArray(highlightsList) && (
                    <HighlightsList highlighsListItems={highlightsList} />
                  )}
                  {showbutton && (
                    <Button
                      type="button"
                      kind="purple_solid"
                      size="large"
                      as="a"
                      href={general.quoteUrl}
                    >
                      Get a quote
                    </Button>
                  )}
                  {showTrustpilotRating && (
                    <TrustPilotContext.Consumer>
                      {({ trustpilot }) => {
                        const { score } = trustpilot
                        if (!isUndefinedOrNull(score)) {
                          return <Trustpilot data-testid="trustpilot-component" tpStarsRating={score.stars} reviewStarsWidth="120px" />
                        }

                        return null
                      }}
                    </TrustPilotContext.Consumer>
                  )}
                </div>
              </div>
            </HeroWrapper>
          </HeroContainer>
        )}
      </ConfigContext.Consumer>
    </div>
  )
}

HeroImage.propTypes = {
  ppc: PropTypes.bool,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  showbutton: PropTypes.bool,
  affiliateImage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  smallTitle: PropTypes.string,
  showTrustpilotRating: PropTypes.bool,
  highlightsList: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool,
  ]),
}

export default HeroImage
