import styled, { css } from 'styled-components'
import { TrustPilotReviewStars } from '../TrustPilotReviewStars'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const Banner = styled.div`
  background: ${({ background }) => background || '#F3F5F8' };
  ${props => !props.addMarginBottom && 'margin-bottom: .5rem;'}
  padding: 15px 20px 40px 20px;

  @media only screen and (max-width: 767px) {
    padding-top: 10px;
    padding-bottom: 20px;
  }
`

const BrandImage = styled.img`
  display: block;
  width: auto;
  max-width: 120px;
  height: auto;
  margin: 0;
  margin-left: 6px;

  ${query.combine(['small', 'extraSmall'], `
    text-align: center;
    max-width: 120px;
    width: auto;
    height: auto;
    margin: 0;
    margin-left: 6px;
  `)}
`

const GiftsContainerStyled = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;

    > div {
      max-width: 800px;
      width: 100%;
      margin: 0 auto 0 auto;
      justify-content: space-between;
    }

    @media only screen and (max-width: 767px) {
      background-color: white;
      margin-top: 16px;
      padding: 16px 10px;

      > div {
        justify-content: center;
      }
    }

    ${props => props.showRightImage && css`
      flex-direction: column;

      > div {
        display: flex;
        align-items: center;
      }
    `}

    ${props => !props.showRightImage && css`
      > div {
        display: flex;
        align-items: center;
        max-width: 640px;

        @media only screen and (max-width: 767px) {
          justify-content: flex-start;
          width: auto;
          margin: 0;
        }

        img {
          margin-right: 5px;

          @media only screen and (max-width: 767px) {
            margin-right: 0;
            margin-left: 15px;
          }
        }
      }
    `}

`

const RatingsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  max-width: 750px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (max-width: 767px) {
    max-width: 100%;

    a {
      margin-left: 17px;
      margin-right: 17px;
    }
  }
`

const TextRating = styled.span`
  color: rgba(29, 55, 78, 0.6);
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 20px;
`

const ReviewsSummary = styled.span`
  color: rgba(29, 55, 78, 1);
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 20px;

  @media only screen and (max-width: 767px) {
    display: none;
  }
`

const Defaqto = styled.span`
  display: flex;
  margin-left: auto;

  img {
    max-width: 196px;
    width: auto;
  }

  @media only screen and (max-width: 767px) {
    margin-left: 0;

    img {
      margin-left: 17px;
      margin-left: 0;
      margin-top: 10px;
    }
  }
`

const TrustpilotLink = styled.a`
  display: flex;
  align-items: center;
  margin-left: 15px;

  @media only screen and (max-width: 767px) {
    margin-left: 0;
    margin-top: 10px;
  }
`

const LeftImage = styled.img`
  @media only screen and (max-width: 767px) {
    width: ${props => props.imgWidth > 0 ? props.imgWidth : 'auto'}px;
  }
`

const IncentiveText = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1D374E;
  max-width: 492px;
  display: block;

  @media only screen and (max-width: 767px) {
    font-size: 1rem;
    line-height: 18px;
    max-width: 202px;
    margin-left: 15px;
    margin-right: 15px;
    display: none;
  }
`

const IncentiveTextMobile = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1D374E;
  max-width: 554px;
  display: none;

  @media only screen and (max-width: 767px) {
    font-size: 1rem;
    line-height: 18px;
    padding-top: 0;
    display: block;
    font-weight: 600;
    margin: 0;
    max-width: 320px;

    ${props => props.showRightImage && css`
      margin-bottom: 10px;
    `}
  }
`

const TrustpilotStarRating = styled(TrustPilotReviewStars)`
  max-width: 166px;
  width: 100%;

  @media only screen and (max-width: 767px) {
    max-width: 132px;
  }
`

const RightImage = styled.img`
  @media only screen and (max-width: 767px) {
    width: ${props => props.imgWidth > 0 ? props.imgWidth : 'auto'}px;
  }
`

export {
  Container,
  Banner,
  BrandImage,
  GiftsContainerStyled,
  RatingsWrapper,
  TextRating,
  ReviewsSummary,
  Defaqto,
  TrustpilotLink,
  LeftImage,
  IncentiveText,
  IncentiveTextMobile,
  TrustpilotStarRating,
  RightImage
}
