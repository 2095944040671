import React from 'react'
import PropTypes from 'prop-types'
import { cleanHtml } from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import {
  Text,
  Container,
  SubsCont,
} from './style'

const SubstantiationText = ({
  text = '',
  background = '',
}) =>
  (
    <SubsCont background={background}>
      <Container>
        <Row>
          <Col>
            <Text
              // eslint-disable-next-line
              dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
            />
          </Col>
        </Row>
      </Container>
    </SubsCont>
  )

SubstantiationText.propTypes = {
  text: PropTypes.string,
  background: PropTypes.string,
}

export default SubstantiationText
