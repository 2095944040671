export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  text: {
    mappedProp: 'text',
  },
  button_text: {
    mappedProp: 'buttontext',
  },
  button_url: {
    mappedProp: 'buttonurl',
  },
  button_style: {
    mappedProp: 'buttonstyle',
  },
}
