import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Icon from '../Icons/Icon'

import {
  Col,
  Row,
} from '../Grid'

import {
  DataLayerLink,
} from '../DataLayerLink'
import {
  Wrapper,
  Accordion,
  HeadingWithIcon,
} from './style'

import {
  isUndefinedOrNull,
  dataLayerPush,
} from '../../utils'

const IconsMap = {
  'Life Insurance': 'LifeInsuranceIcon',
  'Critical Illness': 'CriticalIllnessIcon',
  Claims: 'ClaimsIcon',
  'Your Policy': 'YourPolicyIcon',
  'Your Application': 'YourApplicationIcon',
  Aftercare: 'AfterCareIcon',
  Coronavirus: 'CoronavirusIcon',
}

class LearnAccordionGrid extends Component {
  createGrid = () => {
    const { data: { data } } = this.props

    return Object.keys(data).map(i => {
      if (!isUndefinedOrNull(data[i])) {
        return (
          <Col lg="4" md="6" sm="12" xs="12" key={data[i].name} className="gridColSpacing">
            <HeadingWithIcon><Icon id={`${IconsMap[data[i].name]}`} kind="HandsIcon" size="50" /> {data[i].name}</HeadingWithIcon>
            <ul>
              {this.createSubCatList(data[i].children)}
            </ul>
          </Col>
        )
      }
      return null
    })
  }

  createSubCatList = (items) => {
    if (!isUndefinedOrNull(items)) {
      return (
        Object.keys(items).map(i => (
          <li key={items[i].name} className="cat">
            <button type="button" onClick={(e) => this.toggleAccordion(e)}>{items[i].name}</button>
            {!isUndefinedOrNull(items[i].posts) && (
              <ul className="posts">
                {this.createPostList(items[i].posts)}
              </ul>
            )}
          </li>
        ))
      )
    }
    return null
  }

  createPostList = (items) =>
    Object.keys(items).map(i => (
      <li key={items[i].id}>
        <DataLayerLink
          eventName="textClick"
          eventKey="textName"
          href={items[i].url}
        >
          {items[i].name}
        </DataLayerLink>
      </li>
    ))

  toggleAccordion = (e) => {
    const { currentTarget: { parentNode, innerText } } = e
    parentNode.classList.toggle('active')
    dataLayerPush({
      event: 'questionClick',
      questionName: `${innerText}`.trim(),
    })
  }

  render() {
    return (
      <Wrapper>
        <Accordion>
          <Row>
            {this.createGrid()}
          </Row>
        </Accordion>
      </Wrapper>
    )
  }
}

LearnAccordionGrid.propTypes = {
  data: PropTypes.object,
}

export default LearnAccordionGrid
