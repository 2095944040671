import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const Heading = styled.h2`
  color: #28334A;
  margin: 0 0 1rem 0;
  font-size: 2em;
  line-height: 1.2;
  text-align: center;
  span {
    display: block;
    text-transform: uppercase;
    color: #9361B0;
    font-size: .6em;
    margin-bottom: .5rem;
    a {
      color: #9361B0;
      text-decoration: none;
    }
  }
`

const TopText = styled.p`
  color: #373737;
  text-align: center;
  margin-bottom: 3rem;
`

const Grid = styled.div`
  h3 {
    color: #28334A;
    font-size: 1.4em;
    margin: 0 0 1rem 0;
    img {
      vertical-align: middle;
      margin-right: 1rem;
    }
  }
  p {
    margin: 0;
    font-size: 1em;
    a {
      color: #9361B0;
      text-decoration: none;
      &:hover {
        color: #000;
      }
    }
  }
  margin-bottom: 3rem;
  button {
    text-align: center;
    ${query.combine(['extraSmall', 'small'], `
      display: block;
      width: 100%;
    `)}
  }
`

const ItemHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  >div:first-child {
    margin-right: 10px;
  }
`

const ItemFooter = styled.div`
  margin-bottom: 2em;
`

export {
  Container,
  Heading,
  TopText,
  Grid,
  ItemHeader,
  ItemFooter,
}
