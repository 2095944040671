import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import Breadcrumbs from '../../Breadcrumbs'
import { Row, Col } from '../../Grid'
import Icon from '../../Icons/Icon'
import { OpeningHoursContext } from '../../../containers/contexts'
import { FooterDisclaimerText, TelephoneNumber } from '../../../common/text'

import {
  FooterWrap,
  FooterContainer,
  Menus,
  Contact,
  Disclaimer,
  LegalLinks,
  PPCTerms,
} from './style'

import {
  isUndefinedOrNull,
  dataLayerOnClick,
} from '../../../utils'

const ChildMenuItem = ({
  items,
}) =>
  Object.keys(items).map(i =>
    <li key={items[i].ID}><a template="footer" onClick={(e) => dataLayerOnClick(e)} href={items[i].url}>{items[i].title}</a></li>)

class Footer extends Component {
  state = {
    ppc: false,
  }

  componentDidMount() {
    const { page } = this.props
    if (Object.keys(page)[0] === 'get-a-quote') {
      this.setState({
        ppc: true,
      })
    }
  }

  buildMenus() {
    const { menus } = this.props

    const allowMenus = ['footer1', 'footer2', 'footer3', 'footer4']

    return Object.keys(menus)
      .filter(o => allowMenus.includes(o))
      .map(i => {
        if (!isUndefinedOrNull(menus[i])) {
          const key = `footer-menu${i}`
          return (
            <Col md="3" sm="6" xs="12" key={key}>
              <ul>
                <ChildMenuItem items={menus[i]} />
              </ul>
            </Col>
          )
        }
        return null
      })
  }

  render() {
    const { ppc } = this.state
    return (
      <FooterWrap className="footer-main">
        {ppc && (
          <PPCTerms>
            <p>*Minimum premium £10 and gift card value varies depending on monthly premium. You can claim this gift after your 6th monthly payment. <a href="/terms-conditions" target="_blank">Full Terms and Conditions</a></p>
            <p>**Beagle Street is backed by Scottish Friendly Assurance Society who, in 2019, paid 99.5% of all their life insurance claims.</p>
          </PPCTerms>
        )}
        <FooterContainer>
          <Breadcrumbs />
          <Menus id="footer_menus">
            <Row>
              {this.buildMenus()}
            </Row>
          </Menus>

          <Contact id="footer_contact" className="u-clearfix">
            <div className="contact">
              <div className="wrap u-clearfix">
                <Icon kind="phone" size="30" color="#28334A" />
                <OpeningHoursContext.Consumer>
                  {({ openingHoursText }) => (
                    <div className="details">
                      <span>Call <a template="footer" onClick={(e) => dataLayerOnClick(e)} href="tel:08000480480">{TelephoneNumber}</a></span>
                      {
                        (
                          !isUndefinedOrNull(openingHoursText)
                            ? <small>{openingHoursText}</small>
                            : null
                        )
                      }
                    </div>
                  )}
                </OpeningHoursContext.Consumer>
              </div>
            </div>
            <div className="social">
              <a href="https://twitter.com/beaglestreet" target="_blank" rel="noopener noreferrer" aria-label="Beagle Street Twitter"><Icon kind="twitter" size="35" color="#00C2A2" /></a>
              <a href="https://www.facebook.com/beaglestreet" target="_blank" rel="noopener noreferrer" aria-label="Beagle Street Facebook"><Icon kind="facebook" size="35" color="#00C2A2" /></a>
              <a href="http://www.youtube.com/beaglestreet" target="_blank" rel="noopener noreferrer" aria-label="Beagle Street YouTube"><Icon kind="youtube" size="35" color="#00C2A2" /></a>
            </div>
          </Contact>

          <Disclaimer id="footer_disclaimer">
            <p>&copy; {new Date().getFullYear()} {FooterDisclaimerText.firstLine}</p>
            <p>{FooterDisclaimerText.secondLine}</p>
          </Disclaimer>

          <LegalLinks id="legal_links">
            <ul>
              <li><a onClick={(e) => dataLayerOnClick(e)} href="/terms-conditions">Terms &amp; Conditions</a></li>
              <li><a onClick={(e) => dataLayerOnClick(e)} href="/privacy-policy">Privacy &amp; Cookie Policy</a></li>
              <li><a onClick={(e) => dataLayerOnClick(e)} href="https://www.onefamily.com/assets/consumer/downloads/company-information/modern-slavery-statement-2021.pdf" rel="noopener noreferrer" target="_blank">Modern Slavery Statement</a></li>
              <li><a onClick={(e) => dataLayerOnClick(e)} href="https://www.beaglestreet.com/update-marketing-preferences/" rel="noopener noreferrer" target="_blank">Update Marketing Preferences</a></li>
            </ul>
          </LegalLinks>

        </FooterContainer>
      </FooterWrap>
    )
  }
}

Footer.propTypes = {
  menus: PropTypes.object,
  page: PropTypes.object,
}

const mapStateToProps = (state) => ({
  menus: state.api.menus,
  page: state.api.data,
})

export default connect(mapStateToProps, null)(Footer)
