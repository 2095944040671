import styled from 'styled-components'
import { container, query } from '../../theme'
import Text from '../Text'
import { Col } from '../Grid'

const Container = styled.div`
  ${container}
`

const CallUsTextStyled = styled.p`
 
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  color: #1D374E;
  text-align: center;
  margin-left: 50px;
  ${query.combine(['extraSmall', 'small'], `
    display: none;
  `)}
   ${query.combine(['medium'], `
    margin-left: 0;
    margin-top: 0;
  `)}
  a{
    display: block;
    font-size: 25px!important;
    color: #1D374E;
    text-decoration: none;
    font-weight: 600!important;
  }
`

const ListContainerStyled = styled.div`
  align-self: flex-start;  
  background: #FFFFFF;
  border-radius: 8px;
  width: 396px;
  max-width: 100%;
  ${query.combine(['extraSmall', 'small', 'medium'], `
      width: 100%;
   `)}
  box-shadow: 0px 1px 2px -1px rgba(16, 24, 40, 0.1);
  filter: drop-shadow(0px 1px 3px rgba(16, 24, 40, 0.1));
  border-radius: 8px;
  @media only screen and (max-width: 419px){
    max-height: 420px;
  }
`

const ListContainerTitleStyled = styled.h3`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1D374E;
  margin-bottom: 18px;
  margin-top: 18px;
  letter-spacing: -0.02rem;
  padding-left: 7px;
  padding-right: 7px;
`

const ListStyled = styled.ul`
  border-top: 1px solid #D1D5DB;
  display: inline-flex;
  flex-direction: column;
  margin-top: 0;
  padding-top: 10px;
  
  li {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      max-width: 100%;
   `)}
    max-width: 430px;
    display: inline-flex;
    list-style: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02rem;
    color: #1D374E;
    align-items: center;
    &:first-child{
      margin-top: 15px;
    };
    padding-bottom: 20px ;
    padding-left: 20px;
    padding-right: 20px;
    align-items: flex-start;

    img {
      min-width:20px;
      margin-top: ${props => props.iconPosition?.top ? props.iconPosition.top : 2}px;
      margin-right: ${props => props.iconPosition?.right ? `${props.iconPosition.right}px` : 0};
    }
    
    span {
      padding-left: 15px;
    }
  }
  
  margin-left: 10px;
  margin-right: 10px;
  padding-inline-start: 0;
  text-align: left;
`

const ParagraphStyled = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  color: #344B60;
  margin-bottom: 16px;

  ${query.combine(['extraSmall', 'small'], `
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
  `)}
`

const TextStyled = styled(Text)`
 font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #1D374E;
`

const LR = styled.div` 
  h2 {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    letter-spacing: -0.02em;
    color: #1D374E;
    margin-top: 0px;
    margin-bottom: 20px;
    ${query.combine(['extraSmall', 'small'], `
      text-align: center;
      font-size: 30px;  
    `)}
  }

  .image_col {
    display: flex;
    text-align: center;
    justify-content: end;
    ${query.combine(['extraSmall', 'small', 'medium'], `
       justify-content: center;
    `)}
   
  } 
`

const DesktopButtonsStyled = styled.div`
  display: flex;
  align-items: center;
  ${query.combine(['extraSmall', 'small', 'medium'], `
    display: none;
  `)}
  >div{
    isolation: isolate;
  }
  div{
    &:first-child{
      a{
        &:before{
          max-width: 230px!important;
        }
        font-size: 19px!important;
        width: 223px;
        max-width: 223px!important;
      }
    }
  }
  a{
    font-size: 19px;
    font-weight: 700;
    line-height: 27px;
  }
`

const MobileButtonsStyled = styled(Col)`
  justify-content: center;
  margin-top: 31px;
  margin-bottom: 35px;
  display: none;
  
  ${query.combine(['extraSmall', 'small', 'medium'], `
    display: flex;
    flex-direction: column;
    align-items: center;
  `)}
  >div{
    isolation: isolate;
  }
  div{
    &:first-child{
      margin-bottom: 35px;
      a{
        &:before{
          max-width: 230px !important;
        }
        font-size: 19px !important;
        width: 223px;
        max-width: 100%;
      }
    }
    &:nth-child(2){
      display: none;
      ${query.combine(['extraSmall', 'small'], `
        display: block;
      `)}
      a {
        font-size: 14px;
        font-weight: 600;
        line-height: 27px;
        padding: 6px 0;
        width: 214px;
        height: 40px;
        svg {
          margin-left: 5px;
          width: 17px;
          height: 17px;
          top: 3px;
          position: relative;
        }
      }
    }
  } 
`




export {
  Container,
  LR,
  ParagraphStyled,
  TextStyled,
  ListStyled,
  ListContainerStyled,
  ListContainerTitleStyled,
  CallUsTextStyled,
  DesktopButtonsStyled,
  MobileButtonsStyled
}
