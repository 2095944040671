import React from 'react'
import PropTypes from 'prop-types'
import ContentBlock from '../components/utils/ContentBlock'

const Home = ({
  data,
}) => {
  if (data) {
    return (
      <ContentBlock content={data.acf} />
    )
  }
  return null
}

Home.propTypes = {
  data: PropTypes.object,
}

export default Home
