import React from 'react'
import PropTypes from 'prop-types'
import { TrustPilotContext } from '../../containers/contexts'
import { Trustpilot } from '../Trustpilot'
import { Button } from '../Button'
import { Container, AffiliateWrapper, SideImagesWrapper } from './style'
import { cleanHtml, removeHtmlTagFromString } from '../../utils'

const HeroImageV2 = ({
  heroBackground,
  heading,
  text,
  affiliateImage,
  showCTA,
  ctaText,
  ctaURL,
  showTrustpilot,
  leftImage,
  rightImage,
}) => (
  <Container backgroundImage={heroBackground && heroBackground.url} className="HeroImageV2">
    <h1 dangerouslySetInnerHTML={{ __html: cleanHtml(removeHtmlTagFromString(heading, 'p'))}} />
    <AffiliateWrapper withAffiliate={affiliateImage && affiliateImage.url}>
      {affiliateImage && <div />}
      <p dangerouslySetInnerHTML={{ __html: cleanHtml(removeHtmlTagFromString(text, 'p'))}} />
      {affiliateImage && <img src={affiliateImage.url} alt={affiliateImage.title} />}
    </AffiliateWrapper>
    {showCTA && (
      <Button
        type="button"
        kind="purple_solid"
        size="large"
        as="button"
        href={ctaURL}
      >
        {ctaText}
      </Button>
    )}
    {showTrustpilot && (
      <TrustPilotContext.Consumer>
        {({ trustpilot }) => {
          const { score } = trustpilot
          if (score) {
            return <Trustpilot id="heroTP" data-testid="trustpilot-component" tpStarsRating={score.stars} reviewStarsWidth="120px" />
          }
          return null
        }}
      </TrustPilotContext.Consumer>
    )}
    <SideImagesWrapper>
      {leftImage && <img src={leftImage.url} alt={leftImage.title} />}
      {rightImage && <img src={rightImage.url} alt={rightImage.title} />}
    </SideImagesWrapper>
  </Container>
)

HeroImageV2.propTypes = {
  heroBackground: PropTypes.object,
  leftImage: PropTypes.object,
  rightImage: PropTypes.object,
  heading: PropTypes.string,
  affiliateImage: PropTypes.string,
  text: PropTypes.string,
  ctaText: PropTypes.string,
  ctaURL: PropTypes.string,
  showCTA: PropTypes.bool,
  showTrustpilot: PropTypes.bool,
}

export default HeroImageV2
