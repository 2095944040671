import {
  GET_POSTS_REQUEST,
  GET_POSTS_SUCCESS,
  GET_POSTS_ERROR,
} from '../actions/posts'

const posts = (
  state = {
    loading: undefined, // Bool
  },
  action,
) => {
  switch (action.type) {
    case GET_POSTS_REQUEST:
      return { ...state, loading: true }
    case GET_POSTS_SUCCESS:
      return {
        ...state,
        loading: false,
        [action.key]: action.payload,
        headers: action.headers,
      }
    case GET_POSTS_ERROR:
      return { ...state, error: action.payload }
    default:
      return state
  }
}

export default posts
