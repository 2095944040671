import React from 'react'
import PropTypes from 'prop-types'

import Image from '../Image'

const imageUrl = (rating) => {
  const ratings = {
    5: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-5.svg',
    4.5: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-4-5.svg',
    4: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-4.svg',
    3.5: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-3-5.svg',
    3: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-3.svg',
    2.5: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-2-5.svg',
    2: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-2.svg',
    1.5: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-1-5.svg',
    1: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-1.svg',
    0: 'https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-star-0.svg',
  }

  return ratings[rating]
}

const TrustPilotReviewStars = ({ starsRating, width, className, trustScore }) => {
  const stars = starsRating || 0
  return (
    <Image className={`trustPilotReviewStars ${className}`} dimensions={{ width }} src={imageUrl(stars)} altText={`Rated ${trustScore} out of 5 stars on Trustpilot reviews`} />
  )
}

TrustPilotReviewStars.propTypes = {
  starsRating: PropTypes.number,
  width: PropTypes.string,
}

export default TrustPilotReviewStars
