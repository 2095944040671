import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const Heading = styled.h2`
  color: #28334A;
  text-align: center;
  font-size: 2em;
  margin: 0 0 2rem 0;
`

const TabHeaders = styled.div`
  ${query.combine(['extraSmall', 'small', 'medium'], `
    display: none;
  `)}   
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      margin-bottom: .5rem;
      background: #fff;
      border-radius: 8px;
      &.active {
        box-shadow: 0 2px 10px 0 #DBE1EB;
      }
      &:last-child {
        margin-bottom: 0;
      }
      button {
        cursor: pointer;
        border: 0;
        background: transparent;
        color: #28334A;
        font-weight: bold;
        padding: 1rem;
        text-align: left;
        width: 100%;
        font-size: 1.1em;
        &:focus {
          outline: none;
        }
        img, svg {
          vertical-align: middle;
          margin-right: 1rem;
        }
      }
    }
  }
`

const TabContent = styled.div`
  h3 {
    display: none;
    margin: 0;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 #DBE1EB;      
    padding: 1.5rem;    
    ${query.combine(['extraSmall', 'small', 'medium'], `
      display: block;
    `)}
    button {
      cursor: pointer;
      display: block;
      width: 100%;
      border: 0;
      background: transparent;
      color: #28334A;
      font-weight: bold;
      padding: 0;
      font-size: 1.1em;
      text-align: left;
      &:focus {
        outline: none;
      }
      img, svg {
        vertical-align: middle;
        margin-right: 1rem;
      }
    }         
  }
  .tab {
    ${query.combine(['extraSmall', 'small', 'medium'], `
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
      background: #fff;
    `)}  
    &.active {
      ${query.combine(['extraSmall', 'small', 'medium'], `
        border-radius: 8px;
        box-shadow: 0 2px 10px 0 #DBE1EB;
        padding: 1.5rem;
      `)}      
      h3 {
        padding:0;
        box-shadow: 0 0 0 transparent;
        margin-bottom: 1rem;
      }
      .tab_content {
        display: block
      }
    }
    .tab_content {
      display: none;
      color: #373737;
      animation-duration: .5s;
      p {
        font-size: 1em;
        line-height: 1.6;
        margin: 0 0 1.5rem 0;
        &:last-child {
          margin-bottom: 0;
        }
      }
      ul {
        > li {
          color: #373737;
        }
      }
    }  
  }
`

export {
  Container,
  Heading,
  TabHeaders,
  TabContent,
}
