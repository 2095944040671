import styled from 'styled-components'
import { container } from '../../theme'

const Container = styled.div`
  ${container}
`

const SubsCont = styled.div`
  background: ${props => props.background};
`

const Text = styled.div`
  padding: 5px 0;
  width: 100%;
  p {
    font-size: 0.9em;
    text-align: left;
    color: #373737;
    line-height: 20px;
  }
  a {
      &:hover {
          color: #000;
      }
  }
`
export {
  Text,
  Container,
  SubsCont,
}
