import React from 'react'
import PropTypes from 'prop-types'

import {
  isUndefinedOrNull,
} from '../../utils'

import {
  Row,
} from '../Grid'

import GuideCard from '../GuideCard'

import {
  GuidesBG,
  Heading,
  GuidesContainer,
  ViewAll,
} from './style'

const GuidesBlock = ({
  data,
}) => {
  if (data) {
    const posts = data
    return (
      <GuidesBG>
        <GuidesContainer>
          <Heading>Guides</Heading>
          <Row style={{ justifyContent: 'center' }}>
            {Object.keys(posts).map(i => {
              const {
                title,
                excerpt,
                terms,
                thumbs,
                url,
              } = posts[i]
              const post = {
                title,
                excerpt,
                tax: !isUndefinedOrNull(terms[0]) ? terms[0].name : 'Uncategorised',
                thumb: thumbs.small_block,
                url,
              }
              return (
                <GuideCard post={post} key={i} />
              )
            })}
          </Row>
          <ViewAll>
            <a href="/guides">View All</a>
          </ViewAll>
        </GuidesContainer>
      </GuidesBG>
    )
  }
  return null
}

GuideCard.propTypes = {
  post: PropTypes.object,
}

GuidesBlock.propTypes = {
  data: PropTypes.object,
}

export default GuidesBlock
