export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  text: {
    mappedProp: 'text',
  },
  background_colour: {
    mappedProp: 'background',
  },
}
