import styled, { css } from 'styled-components'

import { theme, query } from '../../theme'

const base = css`
  font-family: 'Montserrat', sans-serif;
  cursor: pointer;
  display: inline-block;
  border-top: 0;
  border-left: 0;
  border-right:0;
  appearance: none;
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  text-align: center;
  border-radius: 29px;
  padding: .4rem 1.5rem;
  text-decoration: none;
  transition: all 0.3s ease 0s;

  ${query.combine(['extraSmall', 'small'], `
    margin: auto;
  `)}

  &:active, &:focus, &:hover {
    /* transform: translateY(2px); */
    outline: 0;
  }
`

const kind = (t) => {
  switch (t) {
    case 'purple_ghost':
    default:
      return css`
        background-color: ${theme.white};
        color: ${theme.secondary};
        border: 2px solid ${theme.secondary};
        &:active, &:focus, &:hover {
          background-color: ${theme.white};
        }
      `
    case 'navy_ghost':
      return css`
          background-color: ${theme.white};
          color: ${theme.navy};
          border: 2px solid ${theme.navy};
          &:active, &:focus, &:hover {
            background-color: ${theme.navy};
            color: ${theme.white};
          }
          @media (prefers-reduced-motion) {
            transition: none!important;
          }
        `
    case 'ghost':
      return css`
        background-color: transparent;
        color: ${theme.secondary};
        border: 2px solid ${theme.divider};
        text-transform: lowercase;
        font-weight: lighter;
        &:active, &:focus, &:hover {
          background-color: transparent;
        }
      `
    case 'purple_solid':
      return css`
        background: linear-gradient(180deg, #8F38C3 0%, #7F3DA8 100%);
        box-shadow: 3px 4px 10px 0 rgba(22,22,22,0.37);
        color: ${theme.white};
        border: 2px solid ${theme.secondary};
        &:active, &:focus, &:hover {
          background-color: #733398;
          color: ${theme.white};
        }
      `
    case 'navy_solid':
      return css`
        background-color: ${theme.navy};
        color: ${theme.white};
        border: 2px solid ${theme.navy};
        &:active, &:focus, &:hover {
          background-color: ${theme.white};
          color: ${theme.navy};
        }
      `
    case 'purple_solid_rebrand':
      return css`
        &:before {
          border-radius: 0 96px;
          z-index: -1;
          content: "";
          border-radius: 37px;
          width: calc(100% + 6px);
          height: 100%;
          background-color: orange;
          position: absolute;
          top: -2px;
          left: -3px;
          background: linear-gradient(#B741FF,#8E00E5);
          box-shadow: 0px 10px 15px -3px rgb(0 0 0 / 24%);

          @media only screen and (max-width: 767px) {
            max-width: 241px!important;
          }
        }

        position: relative;
        background: #8E00E6;
        border-radius: 37px;
        color: ${theme.white};
        border: 2px solid linear-gradient(180deg, #000000 0%, #B741FF 100%);
        font-size: 19px!important;

        @media only screen and (max-width: 767px) {
          font-size: 27px!important;
          max-width: 236px!important;
        }

        &:focus {
          outline: 3px solid #D299F5;
          outline-offset: 6px;
        }

        &:focus:not(:focus-visible) {
          outline: none;
        }

        &:focus-visible {
          outline: 3px solid #D299F5;
          outline-offset: 6px;
        }

        &:active, &:hover {
          background: rgb(114, 0, 183);
          color: ${theme.white};

          &:before {
            background: rgb(114, 0, 183);
            box-shadow: 0px 10px 15px -3px rgb(16 24 40 / 10%), 0px 4px 6px -4px rgb(16 24 40 / 10%);
          }
        }

        transition: all 0.5s ease 0s;

        @media (prefers-reduced-motion) {
          transition: none!important;
        }
      `
    case 'navy_ghost_rebrand':
      return css`
          background-color: ${theme.white};
          color: ${theme.navy};
          border: 2px solid ${theme.navy};
          transition: all 0.5s ease 0s;

          &:focus {
            outline: 3px solid #D299F5;
            outline-offset: 2px;
          }

          &:focus:not(:focus-visible) {
            outline: none;
          }

          &:focus-visible {
            outline: 3px solid #D299F5;
            outline-offset: 2px;
          }

          &:active, &:hover {
            background-color: ${theme.navy};
            color: ${theme.white};
          }

          @media (prefers-reduced-motion) {
            transition: none!important;
          }
        `

    case 'purple_rebrand':
      return css`
          background-color: ${theme.white};
          color: ${theme.navy};
          border: 2px solid ${theme.navy};
          transition: all 0.5s ease 0s;
            &:focus {
            outline: 3px solid #D299F5;
            outline-offset: 2px;
          }
          &:focus:not(:focus-visible) {
            outline: none;
          }
              &:focus-visible {
            outline: 3px solid #D299F5;
            outline-offset: 2px;
          }
              &:active, &:focus, &:hover {
            border: 2px solid #733398;
            background-color: #733398;
            color: ${theme.white};
          }
          @media (prefers-reduced-motion) {
            transition: none!important;
          }
        `
  }
}

const size = (s) => {
  switch (s) {
    case 'small':
      return css`
        padding: .4rem 1.5rem;
        font-size: 14px;
        line-height: 18px;
      `
    case 'medium':
    default:
      return css`
        padding: .8rem 1.8rem;
        font-size: 18px;
        line-height: 22px;
      `
    case 'large':
      return css`
        padding: .8rem 2.2rem;
        font-size: 22px;
        line-height: 27px;
        max-width: 212px;
      `
  }
}

const boxShadow = (bs) => (
  css`
    ${bs}
  `
)

const Btn = styled.button`
  ${base}
  ${props => props.kind && kind(props.kind)}
  ${props => props.size && size(props.size)}
  ${props => props.boxShadow && boxShadow(props.boxShadow)}
`

export default Btn
