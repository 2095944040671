import styled from 'styled-components'

import { query } from '../../theme'

const Link = styled.a`
  color: #28334A;
  text-decoration: none;
  font-size: 1.1em;
  display: inline-block;
  margin-bottom: 2rem;
  font-weight: bold;
  ${query.combine(['medium', 'small', 'extraSmall'], `
    margin-bottom: 1.5rem;
  `)}   
  &:before {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M217.9 256L345 129c9.4-9.4 9.4-24.6 0-33.9-9.4-9.4-24.6-9.3-34 0L167 239c-9.1 9.1-9.3 23.7-.7 33.1L310.9 417c4.7 4.7 10.9 7 17 7s12.3-2.3 17-7c9.4-9.4 9.4-24.6 0-33.9L217.9 256z"/></svg>');
    width: 20px;
    height: 20px;
    content: '';
    color: #373737;
    display:inline-block;
    margin: 0 .4rem 0 0;
    position: relative;
    top: 3px;
  }
`
export default Link
