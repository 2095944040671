import React from 'react'

const baseMenus = ['primary', 'footer1', 'footer2', 'footer3', 'footer4'] // these will never change
const routeContexts = ['post_types', 'taxonomies']

const urls = {
  quickQuote: process.env.REACT_APP_QUOTE_URL,
  login: 'https://quotes.beaglestreet.com/Account/Login?redirectUrl=https://quotes.beaglestreet.com/myaccount/intermediate',
}

const pageTitleSuffix = 'Beagle Street'

const IconList = [
  'search',
  'sign-in',
  'retrieve-quote',
  'twitter',
  'facebook',
  'youtube',
  'phone',
  'close',
  'Life Insurance',
  'Claims',
  'Critical Illness',
  'Application',
  'Aftercare',
  'Your Policy',
  'CallbackFail',
  'CallbackSuccess',
  'TickMark',
  'AfterCareIcon',
  'LifeInsuranceIcon',
  'CriticalIllnessIcon',
  'YourPolicyIcon',
  'YourApplicationIcon',
  'ClaimsIcon',
  'CoronavirusIcon',
  'ppcCallbackSuccess',
  'ppcCallbackError',
  'ppcCallbackPhone',
  'ppcCallbackBox',
  'ppcCallbackHands',
  'FixedPolicy',
  'ClaimsHouse',
  'Counselling',
  'WillWriting',
  'PolicyFees',
  'Regulated',
  'CallCentre',
  'HandsIcon'
]

const IconObjects = [
  { key: 'Search', value: 'search' },
  { key: 'SignIn', value: 'sign-in' },
  { key: 'RetrieveQuote', value: 'retrieve-quote' },
  { key: 'Twitter', value: 'twitter' },
  { key: 'Facebook', value: 'facebook' },
  { key: 'Youtube', value: 'youtube' },
  { key: 'Phone', value: 'phone' },
  { key: 'Close', value: 'close' },
  { key: 'LifeInsurance', value: 'Life Insurance' },
  { key: 'Claims', value: 'Claims' },
  { key: 'CriticalIllness', value: 'Critical Illness' },
  { key: 'Application', value: 'Application' },
  { key: 'Aftercare', value: 'Aftercare' },
  { key: 'Your Policy', value: 'Your Policy' },
  { key: 'CallbackSuccess', value: 'CallbackSuccess' },
  { key: 'CallbackFail', value: 'CallbackFail' },
  { key: 'TickMark', value: 'TickMark' },
  { key: 'AfterCareIcon', value: 'AfterCareIcon' },
  { key: 'LifeInsuranceIcon', value: 'LifeInsuranceIcon' },
  { key: 'CriticalIllnessIcon', value: 'CriticalIllnessIcon' },
  { key: 'YourPolicyIcon', value: 'YourPolicyIcon' },
  { key: 'YourApplicationIcon', value: 'YourApplicationIcon' },
  { key: 'ClaimsIcon', value: 'ClaimsIcon' },
  { key: 'CoronavirusIcon', value: 'CoronavirusIcon' },
  { key: 'ppcCallbackSuccess', value: 'ppcCallbackSuccess' },
  { key: 'ppcCallbackError', value: 'ppcCallbackError' },
  { key: 'ppcCallbackPhone', value: 'ppcCallbackPhone' },
  { key: 'ppcCallbackBox', value: 'ppcCallbackBox' },
  { key: 'ppcCallbackHands', value: 'ppcCallbackHands' },
  { key: 'FixedPolicy', value: 'FixedPolicy' },
  { key: 'ClaimsHouse', value: 'ClaimsHouse' },
  { key: 'Counselling', value: 'Counselling' },
  { key: 'WillWriting', value: 'WillWriting' },
  { key: 'PolicyFees', value: 'PolicyFees' },
  { key: 'Regulated', value: 'Regulated' },
  { key: 'CallCentre', value: 'CallCentre' },
  { key: 'HandsIcon', value: 'HandsIcon' },

]

// accept cookie constants
const acceptCookieValues = {
  cookieName: 'acceptCookies',
  cookieExpiryDays: '365',
  cookieValue: '1',
}

// callback form text

const callbackFormCopy = {
  errorMessage: {
    errorfirstName: (<>Please enter<span className="formErrorMessage">&nbsp;First name&nbsp;</span>here</>),
    errorlastName: (<>Please enter<span className="formErrorMessage">&nbsp;Last name&nbsp;</span>here</>),
    errorPhone: (<>Please enter<span className="formErrorMessage">&nbsp;Phone number&nbsp;</span>here</>),
    errorPolicy: (<>Please enter<span className="formErrorMessage">&nbsp;Policy number&nbsp;</span>here</>),
    errorHelpWith: (<>Please make a<span className="formErrorMessage">&nbsp;selection</span></>),
    errorPreferredTime: (<>Please make a<span className="formErrorMessage">&nbsp;selection</span></>),
    errorCallbackDay: (<>Please make a<span className="formErrorMessage">&nbsp;selection</span></>),
    errorReCaptcha: (<>Please complete<span className="formErrorMessage">&nbsp;CAPTCHA&nbsp;</span>to continue</>),
  },
  formText: {
    heading: 'Please fill in your details below and we\'ll call you back',
    ppcHeading: 'Get a Life Insurance Quote today',
    ppcDisclaimer: (<>By clicking “Get a Quote” you agree to Beagle Street using your phone number to contact you about your quote. You can read our terms of use <a href="/terms-conditions" target="_blank">here.</a></>),
    helpWith: [
      { key: 'Discuss my quote', value: 'Discuss my quote' },
      { key: 'Discuss my application', value: 'Discuss my application' },
      { key: 'Discuss my policy', value: 'Discuss my policy' },
      { key: 'Other', value: 'Other' },
    ],
    images: {
      form: 'https://cdn.bgllife.co.uk/beaglestreet/images/Callback_sofa.png',
    },
  },
}

export {
  baseMenus,
  routeContexts,
  pageTitleSuffix,
  IconList,
  IconObjects,
  urls,
  acceptCookieValues,
  callbackFormCopy,
}
