import styled from 'styled-components'
import { query } from '../../theme'

const Container = styled.div`

`

const Cont = styled.div`
  position: relative;
  height: $nav-height;
  display: flex;
  align-items: center;
  @media (pointer: coarse) {
    overflow: hidden;
  }

  ${query.combine(['extraSmall', 'small'], `
      padding-left: 10px;
  `)} 
`

const QuickLink = styled.ul`
  position: relative;
  display: flex;
  padding: 0;
  align-items: center;
  list-style-type: none;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  list-style: none;
  flex: 1 1 0px;
  margin: 0;
  justify-content: center;
  ${query.combine(['extraSmall', 'small'], `
    margin: 0;
    justify-content: flex-start;
  `)}   
  li {
    display: inline-flex; 
    border-radius: 5px;
    align-items: center;
    ${query.combine(['extraSmall', 'small', 'medium'], `
      flex-shrink: 0;
    `)}         
    &:not(:first-child) {
      margin-left: 1em;
    }   
  }   
`

export {
  QuickLink,
  Container,
  Cont,
}
