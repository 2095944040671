export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  heading: {
    mappedProp: 'heading',
  },
  text: {
    mappedProp: 'text',
  },
  content: {
    mappedProp: 'content',
  },
  anchorid: {
    mappedProp: 'anchorid',
  },
}
