import {
  GET_BANKHOLIDAY_REQUEST,
  GET_BANKHOLIDAY_SUCCESS,
  GET_BANKHOLIDAY_FAILURE,
} from '../actions/holidays'

const initialState = {
  isFetching: false,
  holidays: undefined,
}

const holidays = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_BANKHOLIDAY_REQUEST:
      return { ...state, isFetching: true }
    case GET_BANKHOLIDAY_SUCCESS:
      return { ...state, ...action.payload, isFetching: false }
    case GET_BANKHOLIDAY_FAILURE:
      return { ...state, isFetching: false, error: action.payload }
    default:
      return state
  }
}

export default holidays
