export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  show_heading: {
    mappedProp: 'showheading',
  },
  background: {
    mappedProp: 'background',
  },
  heading: {
    mappedProp: 'heading',
  },
  content: {
    mappedProp: 'content',
  },
  anchorid: {
    mappedProp: 'anchorid',
  },
}
