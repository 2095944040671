import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const ImageTextContainer = styled.div`
  .image_col {
    img {
      ${query.combine(['extraSmall', 'small', 'medium'], `
        text-align: center;
        margin: 0 auto;
        display:block;
      `)}    
    }
    &.left {
      ${query.combine(['extraSmall', 'small', 'medium'], `
        order: 2;
      `)}    
    }
    &.right {

    }
  }
`

const Content = styled.div`
  h2, h3 {
    color: #28334A;
    margin: 0 0 1rem 0;
  }
  p {
    color: #373737;
    margin-top: 0;
    font-size: 1em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Heading = styled.h2`
  font-size: 2.2em;
  color: #28334A;
  margin-top: 0;
  margin-bottom: 1rem;
`

export {
  Container,
  ImageTextContainer,
  Content,
  Heading,
}
