import React from 'react'
import PropTypes from 'prop-types'

import { trimText, isEmptyArray } from '../../utils'

import {
  Col,
  Row,
} from '../Grid'

import {
  DataLayerLink,
} from '../DataLayerLink'

import {
  Container,
  Article,
  Featured,
  Image,
  Title,
  Text,
  Smalls,
} from './style'

const FeaturedArticles = ({
  posts,
}) => {
  const { firstPost, otherPosts } = posts

  // TODO: Create fallback image
  const tax = 'Uncategorized'
  return (
    <Container>
      <Featured>
        <Row>
          <Col lg="7" sm="12" xs="12">
            <Article>
              <DataLayerLink
                eventName="textClick"
                eventKey="textName"
                eventValue={firstPost.title}
                href={firstPost.url}
              >
                <Image src={firstPost.thumbs.medium_block || 'https://placehold.co/700x350'} alt={firstPost.title} />
              </DataLayerLink>
              <Title>
                <span className="tax">{Object.prototype.hasOwnProperty.call(firstPost, 'terms') && !isEmptyArray(firstPost.terms[0]) ? firstPost.terms[0].name : tax}</span>
                <h2>
                  <DataLayerLink
                    eventName="textClick"
                    eventKey="textName"
                    eventValue={firstPost.title}
                    href={firstPost.url}
                  >
                    {firstPost.title}
                  </DataLayerLink>
                </h2>
              </Title>
              <Text>{firstPost.excerpt}</Text>
            </Article>
          </Col>
          <Col lg={{ span: 4, offset: 1 }} sm="12" xs="12">
            <Smalls>
              {Object.keys(otherPosts).map(i => (
                <Article key={otherPosts[i].ID} className="small">
                  <Row>
                    <Col lg="12" md="6" sm="6" xs="6">
                      <DataLayerLink
                        eventName="textClick"
                        eventKey="textName"
                        eventValue={otherPosts[i].title}
                        href={otherPosts[i].url}
                      >
                        <Image src={otherPosts[i].thumbs.small_block || 'https://placehold.co/700x250'} alt={otherPosts[i].title} />
                      </DataLayerLink>
                    </Col>
                    <Col lg="12" md="6" sm="6" xs="6">
                      <Title className="smaller">
                        <span className="tax">{Object.prototype.hasOwnProperty.call(otherPosts[i], 'terms') && !isEmptyArray(otherPosts[i].terms[0]) ? otherPosts[i].terms[0].name : tax}
                        </span>
                        <h2>
                          <DataLayerLink
                            eventName="textClick"
                            eventKey="textName"
                            eventValue={otherPosts[i].title}
                            href={otherPosts[i].url}
                          >
                            {otherPosts[i].title}
                          </DataLayerLink>
                        </h2>
                      </Title>
                      <Text className="smaller">{trimText(otherPosts[i].excerpt, 90)}</Text>
                    </Col>
                  </Row>
                </Article>
              ))}
            </Smalls>
          </Col>
        </Row>
      </Featured>
    </Container>
  )
}

FeaturedArticles.propTypes = {
  posts: PropTypes.object,
}

export default FeaturedArticles
