import styled, { css } from 'styled-components'

import { container, query } from '../../theme'

const HeroContainer = styled.div`
  background-color: #DDF0EE;
  width: 100%;
  background-image: url(${props => props.image});
  background-position: 85% bottom;
  background-repeat: no-repeat;
  background-size: auto;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;


  // temporary PPC styles
  ${props => props.ppc && css`
    ${query.combine(['medium'], `
      background-size: auto 15rem!important;
    `)}

    @media only screen and (min-width: 992px) and (max-width: 1200px) {
      background-size: auto 22rem!important;
      background-position: 80% bottom!important ;
    }

    @media only screen and (min-width: 1201px) {
      background-size: auto 26rem!important;
      background-position: 73% bottom!important ;
    }

  `}

  @media only screen and (max-width: 767px) {
    ${props => props.ppc && css`
      background-image: none;

      &.affiliate, &.trustpilot {
        padding-bottom: 30px!important;
        padding-top: 20px!important;
      }

    `}
  }
  // end ppc styles

    &.affiliate {
      @media only screen and (min-width: 300px) and (max-width: 330px){
        .table_outer .table_inner_mid {
            img {
              width: 48%!important;
            }
        }
      }
    }

  ${query.combine(['extraSmall'], `
    &.affiliate {
      .table_outer .table_inner_mid {
        padding-top: 1.5rem;
      }
    }
  `)}

  ${query.combine(['large'], `
    background-size: auto 24rem;
    background-position: 90% bottom;

  `)}

  ${query.combine(['medium'], `
    background-size: auto 17rem;
    background-position: 90% right;

  `)}


  ${query.combine(['extraSmall', 'small'], `
    &.trustpilot {
      .table_outer .table_inner_mid {
        padding-top: 1rem;
      }
    }
    background-size: auto 13.8125rem;
    background-position: bottom center;
    padding-top: 25px;
    padding-bottom: 225px;
    height: auto;
  `)}


  &.affiliate, &.trustpilot {
    /* height: auto; */

    ${query.combine(['large'], `

    `)}
    ${query.combine(['medium'], `

    `)}
    ${query.combine(['extraSmall', 'small'], `
      height: auto;
      padding-bottom: 230px;
    `)}
  }

  &.affiliate.trustpilot {
    background-position: 90% bottom;
    background-size: auto 31rem;
    ${query.combine(['large'], `
      background-size: auto 26rem;
      background-position: 87% bottom;
    `)}
    ${query.combine(['medium'], `
      background-size: auto 19.5rem;
      background-position: 87% bottom;
    `)}
    ${query.combine(['extraSmall', 'small'], `
      background-size: auto 13.8125rem;
      background-position: bottom center;
    `)}
  }

`

const HeroWrapper = styled.div`

  ${container}
  height: 100%;
  ${query.combine(['small', 'extraSmall'], `
    height: auto;
  `)}

  .table_outer {
    max-width: 38%;
    ${query.combine(['medium'], `
      max-width: 49%;
    `)}
    ${query.combine(['extraSmall', 'small'], `
      max-width: none;
    `)}
    .table_inner_mid {
      ${query.combine(['small', 'extraSmall'], `
        vertical-align: top;
        padding-top: 1.5625‬rem;
        text-align: center;
      `)}
    }
  }

`

const Heading = styled.h1`
  margin: 0;
  color: #28334A;
  font-weight: normal;
  font-size: 3em;
  line-height: 1.2;
  ${query.combine(['large'], `
    font-size: 2.5em;
  `)}
  span {
    display: block;
    font-weight: bold;
  }

  ${props => !props.text && css`
    margin-bottom: 10px;
  `}
`

const SmallTitle = styled.strong`
  text-transform: uppercase;
  color: #28334A;
  display: block;
  margin-bottom: 0.5rem;
`

const Text = styled.div`
  p {
    ${query.combine(['large'], `
      font-size: 1em;
    `)}
    ${query.combine(['small', 'extraSmall'], `
      margin-top: 3px;
    `)}
    color: #373737;
  }
  color: #373737;
`

export default HeroWrapper

export {
  HeroContainer,
  HeroWrapper,
  Heading,
  Text,
  SmallTitle,
}
