import React from 'react'
import PropTypes from 'prop-types'

import Incentive from '../Incentive'

import { WrapperStyled, Container } from './style'
import { isUndefinedOrNull, removeHtmlTagFromString } from '../../utils'

const IncentiveWithHeading = ({
  section,
  gridItems
}) => {

  if (isUndefinedOrNull(section) || isUndefinedOrNull(gridItems)) return null

  return (
    <WrapperStyled className="incentive_with_heading content_block">
      <Container>
        <Incentive>
          <Incentive.MainHeading text={section.title} url={section.titleLink} />
          <Incentive.SubHeading text={section.subheading} />
          <Incentive.Body>{removeHtmlTagFromString(section.body, 'p')}</Incentive.Body>
          <div>
            <Incentive.Grid content={gridItems} numberOfItems={gridItems.length} />
          </div>
        </Incentive>
      </Container>
    </WrapperStyled>
  )
}

IncentiveWithHeading.propTypes = {
  numberOfListItems: PropTypes.number,
  icon: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string,
}

export default IncentiveWithHeading
