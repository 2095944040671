import styled from 'styled-components'
import { query } from '../../theme'

const Page404 = styled.div`
  text-align: center;
  margin: 10rem 0;
  padding: 0 1rem;
  ${query.combine(['extraSmall', 'small', 'medium'], ` 
    margin: 5rem 0;    
  `)}    
  h1 {
    margin: 0 0 2rem 0;
    line-height: 1.2;
    font-size: 3em;
    ${query.combine(['extraSmall', 'small', 'medium'], ` 
      font-size: 2em  
    `)}      
    span {
      color: #9361B0;
      display: block;
      font-size: 2em;
    }
  }
  p {
    a {
      color: #9361B0;
    }
  }
`

export default Page404
