import React, { Component } from 'react'
import { Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import GlobalStyle from './theme/_global'
import NotFound from './templates/NotFound'
import Search from './containers/Search'
import Callback from './containers/Callback'
import CallbackLead from './containers/CallbackLead'

import HeaderV2 from './components/layout/HeaderV2'
import Footer from './components/layout/Footer'
import LoadTemplate from './templates/LoadTemplate/index'
import { TrustPilotContext, ConfigContext, OpeningHoursContext } from './containers/contexts'

import {
  getRating,
  getReviews,
} from './actions/trustpilot'
import {
  getBankHolidays,
} from './actions/holidays'
import {
  isUndefinedOrNull,
  isEmptyObj,
  isEmptyArray,
  removeWrappedSlashes,
} from './utils'

class App extends Component {
  state = {
    showFooter: false,
  }

  componentDidMount() {
    const {
      loadRatings,
      loadReviews,
      trustpilot: {
        score,
        reviews,
      },
      holidays,
      loadBankHolidays,
      page,
    } = this.props

    const {
      bankHolidays,
    } = holidays
    // params
    if (isUndefinedOrNull(score) || isEmptyObj(score)) {
      loadRatings()
    }

    if (isEmptyArray(reviews)) {
      loadReviews(1)
    }

    if (isUndefinedOrNull(bankHolidays)) {
      loadBankHolidays()
    }

    setTimeout(() => {
      this.setState({ showFooter: true })
    }, 2000)

    // manually assign hide header to custom pages that don't exist in wordpress and needs to occur before header and page loads.
    if (Object.keys(page)[0] === 'get-a-quote') Object.assign(page[Object.keys(page)[0]], { acf: { hide_header: true } })
  }

  render() {
    const {
      routes: { all },
      trustpilot,
      loadReviews,
      general,
      holidays,
    } = this.props
    const { bankHolidays, openingHoursText } = holidays
    const { showFooter } = this.state

    return (
      <div>
        <ConfigContext.Provider value={{ general }}>
          <OpeningHoursContext.Provider value={{ bankHolidays, openingHoursText }}>
            <HeaderV2 />
            <TrustPilotContext.Provider value={{ trustpilot, loadReviews }}>
              <Switch>
                {!isEmptyArray(all) && (
                  <Route
                    render={() => (
                      <LoadTemplate
                        {...all[0]}
                        key={removeWrappedSlashes(all[0].route)}
                        id={all[0].id}
                        container={all[0].container}
                        slug={removeWrappedSlashes(all[0].route)}
                        type={all[0].post_type}
                        context={all[0].context}
                      />
                    )}
                    {...all[0]}
                    exact
                    key={all[0].route}
                    path={`${decodeURIComponent(all[0].route)}`}
                  />
                )}
                <Route key="search" component={Search} exact path="/search" />
                <Route key="call-back" component={Callback} exact path="/call-back" />
                <Route key="get-a-quote" component={CallbackLead} exact path="/get-a-quote" />
                <Route key="not-found" component={NotFound} />
              </Switch>
            </TrustPilotContext.Provider>
            {showFooter && (<Footer />)}
          </OpeningHoursContext.Provider>
          <GlobalStyle />
        </ConfigContext.Provider>
      </div>
    )
  }
}

App.propTypes = {
  menus: PropTypes.object,
  routes: PropTypes.object,
  page: PropTypes.object,
  loadRatings: PropTypes.func,
  loadReviews: PropTypes.func,
  trustpilot: PropTypes.object,
  general: PropTypes.object,
  holidays: PropTypes.object,
  loadBankHolidays: PropTypes.func,
}

const mapStateToProps = (state) => ({
  menus: state.menus,
  routes: state.api.routes,
  trustpilot: state.trustpilot,
  general: state.api.general,
  holidays: state.holidays,
  page: state.api.data,
})

const mapDispatchToProps = (dispatch) => ({
  loadRatings: () => dispatch(getRating()),
  loadReviews: (pageNumber) => dispatch(getReviews(pageNumber)),
  loadBankHolidays: () => dispatch(getBankHolidays()),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
