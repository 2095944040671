import styled from 'styled-components'

const MessageContainer = styled.div`
  text-align: center;
  max-width: 600px;
  padding: 60px 0;
  margin: 0 auto;
`

const MessageHeading = styled.h2`
  margin-top: 0;
  font-family: 'Montserrat';
  font-weight: bold;
  font-size: 1.8666666666666667em;
  color: #28334A;
  line-height: 34px;
  text-align: center;
`

const MessageBody = styled.p`
  font-size: 1.5333333333333334em;
  line-height: 28px;
  text-align: center;
  color: #000;
  a {
    color: #8132AB;
    text-decoration: none;
  }

`

const MessageFooter = styled.p`
  margin-top: 37px;
  font-size: 1.1em;
  line-height: 19px;
  text-align: center;
`

const BenefitsContainer = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

const BenefitWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  > div {
    &:first-child {
      padding-top: 10px;
      width: 20%;
    }
  }
`

const BenefitsContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  h5 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    color: #28334A;
  }

  p {
    margin-top: 5px;
    width: 85%;
    line-height: 22px;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }
`

export {
  MessageContainer,
  MessageHeading,
  MessageBody,
  MessageFooter,
  BenefitsContainer,
  BenefitWrapper,
  BenefitsContentWrapper,
}
