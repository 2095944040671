import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import SearchList from './style'

import { cleanHtml, dataLayerPush, isUndefinedOrNull } from '../../utils'

const SearchResults = ({
  data,
}) => {
  useEffect(() => {
    if (!isUndefinedOrNull(data)) {
      if (!isUndefinedOrNull(data[0])) {
        const { error } = data[0]
        if (error) {
          const searchTerm = document.querySelector('#search_bar').getAttribute('placeholder')
          dataLayerPush({
            event: 'searchNotFound',
            searchKeyword: `${searchTerm}`.trim(),
          })
        }
      }
    }
  }, [])

  return (
    <SearchList>
      {Object.keys(data).map(i => (
        <li key={data[i].ID}>
          <a href={data[i].url}>{data[i].title}</a>
          <span
            // eslint-disable-next-line
            dangerouslySetInnerHTML={{ __html: cleanHtml(data[i].excerpt) }}
          />
        </li>
      ))}
    </SearchList>
  )
}

SearchResults.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}

export default SearchResults
