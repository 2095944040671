import styled from 'styled-components'
import { query } from '../../theme'

const Header = styled.h1`
  color: #28334A;
  font-family: 'Montserrat', sans-serif;
  font-size: 2.8em;
  line-height: 58px;
  font-weight: 700;
  margin: ${props => props.margin || '2rem 0 1.5rem 0'};
  text-align: ${props => props.pos || 'left'};
  ${query.combine(['large'], `
    font-size: 2.4em;
    line-height: 52px;
  `)}

  ${query.combine(['medium'], `
    font-size: 2.1em;
    line-height: 48px;
  `)}

  ${query.combine(['extraSmall', 'small'], `
    font-size: 1.9em;
    line-height: 39px;
    text-align: center;
  `)}
  span {
    font-weight: 400;
  }
`

export default Header
