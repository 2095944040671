import styled from 'styled-components'
import { container, query } from '../../theme'

const Container = styled.div`
  ${container}
`

const Banner = styled.div`
  background: #F3F5F8;
  padding-top: 3rem;
  margin-bottom: .5rem;  
`

const Text = styled.div`
  h1 {
    margin: 0 0 1.5rem 0;
    color: #28334A;
    font-size: 48px;
    font-weight: bold;
    line-height: 58px;

    ${query.combine(['large'], `
      margin-bottom: 10px;
      line-height: 40px;
      font-size: 32px;
    `)}

    ${query.combine(['medium'], `
      margin-bottom: 10px;
      line-height: 40px;
      font-size: 28px;
    `)}

    ${query.combine(['small', 'extraSmall'], `
      font-size: 28px;
      line-height: 44px;
      text-align: center;
      margin-bottom: 0;
    `)}
  }
  p {
    color: #373737;
    margin: 0 0 1.5rem 0;
    max-width: 312px;
    font-size: 18px;
    line-height: 27px;

    ${query.combine(['small', 'extraSmall'], `
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      margin: 0 auto;
      padding-bottom: 10px;
    `)}

    ${query.combine(['medium'], `
      margin-bottom: 10px;
      line-height: 22px;
    `)}
  }
`

const HeroImage = styled.span`
  img{
    vertical-align: bottom;
    ${query.combine(['small', 'extraSmall'], `
    display: none;
  `)}
  }
`

const TrustPilotStarsWrapper = styled.span`
  display: inline-block;

  ${query.combine(['small', 'extraSmall'], `
    margin: 0 auto; 
    display: block;
    text-align: center; 
    margin-bottom: 48px;
  `)}
  
`

const BrandImage = styled.img`
  display: block;
  width: 150px;
  height: 29px;
  
  ${query.combine(['small', 'extraSmall'], `
    text-align: center;
    margin: 0 auto;
    margin-bottom: 10px;
    width: 120px;
    height: 23.2px;

    + img {
      margin-bottom: 40px;
      margin-left: auto;
    }
  `)}

  + img {
    width: 95px;
    margin-left: calc((150px - 95px) / 2);

    ${query.combine(['small', 'extraSmall'], `
      margin-left: auto;
  `)}
  }
`

export {
  Container,
  Banner,
  Text,
  BrandImage,
  HeroImage,
  TrustPilotStarsWrapper,
}
