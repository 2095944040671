import styled from 'styled-components'

import Text from '../Text'
import {
  Col,
  Row,
} from '../Grid'
import RowList from '../RowList'

import { container } from '../../theme'

const Container = styled.div`
  ${container}
`

const HeadingTwoStyled = styled.h2`
  line-height: 1.2;
  font-size: 1.1rem;
  margin: 0 0 8px 0;
  text-align: left;

  @media screen and (max-width: 991px) {
    font-size: 0.9rem;
    margin-top: 21px;
    margin-bottom: 8px;
  }

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 1.1rem;
    line-height: 120%;
    text-align: left;
    text-transform: uppercase;
    color: #9361b0;
    text-decoration: none;

    @media screen and (max-width: 991px) {
      margin-left: 7px;
      font-size: 0.9rem;
    }
  }
`

const HeadingThreeStyled = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 120%;
  color: #28334a;
  margin: 0 0 24px 0;
  text-align: left;

  @media screen and (max-width: 991px) {
    font-size: 1.8rem;
    margin-bottom: 24px;
    margin-left: 7px;
  }
`

const Paragraph1Styled = styled(Text)`
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 30px;
  color: #373737;
  margin: 0;
  text-align: left;

  @media screen and (max-width: 991px) {
    font-size: 1rem;
    line-height: 1.5;
    margin-left: 7px;
    text-align: left;
  }
`

const RowStyled = styled(Row)`
  flex-direction: row-reverse;
`

const ImageColStyled = styled(Col)`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0!important;
    justify-content: flex-start;

    img {
      width: 100%;
      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
    }
`
const ContentColStyled = styled(Col)`
    padding: 0!important;

    @media screen and (max-width: 767px) {
      padding: 0 15px!important;
    }

    > * {
      margin-left: 50px;

      @media screen and (max-width: 767px) {
        margin-left: 0;
      }
    }
`

const ContentWrapper = styled.div`
  margin-top: 45px;
  margin-bottom: 45px;
  margin-right: 22px;
  text-align: center;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin: 20px 22px 30px 0;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  a {
    &[type="button"] {
      font-style: normal;

      @media screen and (max-width: 767px) {
        width: 100%;
        max-width: 100%;
      }
      @media screen and (max-width: 329px) {
        font-size: 1rem;
        max-width: 100%;
      }
    }
  }
`

const RowListStyled = styled(RowList)`
  margin-top: 16px;
  margin-bottom: 16px;

  @media screen and (max-width: 991px) {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 767px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  li {
    @media screen and (max-width: 991px) {
      margin-bottom: 16px;
    }
  }
`

export {
  Container,
  HeadingTwoStyled,
  HeadingThreeStyled,
  Paragraph1Styled,
  RowStyled,
  ImageColStyled,
  ContentColStyled,
  ContentWrapper,
  RowListStyled,
}
