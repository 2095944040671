import {
  GET_REVIEW_REQUEST,
  GET_REVIEW_SUCCESS,
  GET_REVIEW_FAILURE,
} from '../actions/trustpilot'

const initialState = {
  isFetching: false,
  score: undefined,
  numberOfReviews: undefined,
  starsText: undefined,
  reviews: [],
}

const term = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case GET_REVIEW_REQUEST:
      return { ...state, isFetching: true }
    case GET_REVIEW_SUCCESS:
      return { ...state, ...action.payload, isFetching: false }
    case GET_REVIEW_FAILURE:
      return { ...state, isFetching: false, error: action.payload }
    default:
      return state
  }
}

export default term
