import React from 'react'
import PropTypes from 'prop-types'
import ContentBlock from '../components/utils/ContentBlock'

const Page = ({
  data,
}) => {
  if (data) {
    return (
      <div className="page-margined">
        <ContentBlock content={data.acf} />
      </div>
    )
  }
  return null
}

Page.propTypes = {
  data: PropTypes.object,
}

export default Page
