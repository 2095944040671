import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Label, InputField, ErrorMessage } from './style'

const Input = ({
  identifier,
  inputType,
  labelText,
  onInputChange,
  errorMessage,
  showError,
  ...props
}) => {
  const [inputValue, setInputValue] = useState('')

  const handleChange = (e) => {
    setInputValue(e.target.value)
    onInputChange(e.target.value, e.target.name)
  }

  return (
    <div>
      <Label htmlFor={identifier}>
        {labelText}
        <InputField
          tabIndex="0"
          type={inputType}
          name={`${identifier}_Field`}
          id={identifier}
          onChange={(e) => handleChange(e)}
          value={inputValue}
          className={showError ? 'errorInput' : ''}
          {...props}
        />
        {showError && (
          <ErrorMessage><img src="https://cdn.bgllife.co.uk/beaglestreet/icons/ic-baseline-error.svg" alt="Error Message" />{errorMessage}</ErrorMessage>
        )}
      </Label>
    </div>
  )
}

Input.propTypes = {
  identifier: PropTypes.string,
  inputType: PropTypes.string,
  labelText: PropTypes.string,
  onInputChange: PropTypes.func,
  errorMessage: PropTypes.string,
  showError: PropTypes.bool,
}

Input.defaultProps = {
  inputType: 'text',
}

export default Input
