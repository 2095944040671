import React from 'react'
import PropTypes from 'prop-types'

import Link from './style'

const BackLink = ({
  url,
  text,
  ...props
}) => <Link href={url} {...props}>{text}</Link>

BackLink.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
}

export default BackLink
