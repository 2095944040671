import React, { useEffect, useContext, useState } from 'react'
import PropTypes from 'prop-types'
// import { Link } from 'react-router-dom'
import { ParentComponentContext } from '../../containers/contexts'
import { anchorClick, isUndefinedOrNull, dataLayerPush } from '../../utils'

import Btn from './style'

const Button = ({
  kind,
  as,
  children,
  to,
  href,
  type,
  size,
  location,
  onClick = undefined,
  id,
  ariaLabel,
  ...props
}) => {
  const button = React.createRef()
  const [btnTemplate, setBtnTemplate] = useState(null)
  const [sendRequest, setSendRequest] = useState(false)
  const value = useContext(ParentComponentContext)

  const ctaObj = (key) => {
    if (!key) return false
    const messages = {
      HeroImage: 'heroCTA',
      CTABanner: 'BannerCTA',
      header: 'headerCTA',
    }
    const validkey = !isUndefinedOrNull(messages[key])
    return (validkey ? messages[key] : `${key}CTA`)
  }

  const btnTypeChecker = (temp) => {
    const isACTAButton = Boolean(button.current.innerText.toLowerCase() === 'get a quote')
    const btns = [...document.querySelectorAll('[type=button]')]
      .filter(el => el.getAttribute('data-parent') === temp)
      .filter(btn => btn.innerText === button.current.innerText)

    const message = isACTAButton ? ctaObj(temp) : button.current.innerText
    const eventName = isACTAButton ? 'CTAName' : 'buttonName'
    const btnIndex = btns.indexOf(button.current) > 0 ? btns.indexOf(button.current) + 1 : ''
    dataLayerPush({
      event: isACTAButton ? 'CTAClick' : 'ButtonClick',
      [eventName]: `${message} ${btnIndex}`.trim(),
    })
  }

  useEffect(() => {
    if (!isUndefinedOrNull(value)) {
      const { parent } = value
      setBtnTemplate(parent)
    }
  }, [btnTemplate])

  useEffect(() => {
    if (!isUndefinedOrNull(value) && sendRequest) {
      btnTypeChecker(button.current.getAttribute('data-parent'))
      setSendRequest(false)
    }
  }, [sendRequest])

  if (as === 'link') {
    return (
      <Btn
        kind={kind}
        as={as}
        to={to}
        ref={button}
        data-parent={btnTemplate}
        href={href}
        type={type}
        size={size}
        {...props}
        onClick={() => {
          setSendRequest(true)
          if (!isUndefinedOrNull(onClick)) {
            onClick()
          }
        }}
      >
        {children}
      </Btn>
    )
  }
  if (as === 'a' && location === 'anchor') {
    return (
      <Btn
        kind={kind}
        as={as}
        to={to}
        ref={button}
        data-parent={btnTemplate}
        href={href}
        type={type}
        size={size}
        location={location}
        onClick={(e) => {
          setSendRequest(true)
          anchorClick(e, location)
        }}
      >
        {children}
      </Btn>
    )
  }
  if (as === 'button') {
    return (
      <Btn
        kind={kind}
        as={as}
        to={to}
        href={href}
        type={type}
        size={size}
        data-parent={btnTemplate}
        onClick={() => {
          setSendRequest(true)
          if (!isUndefinedOrNull(onClick)) {
            onClick()
          }
        }}
      >
        {children}
      </Btn>
    )
  }
  return (
    <Btn
      id={id}
      kind={kind}
      as={as}
      to={to}
      ref={button}
      data-parent={btnTemplate}
      href={href}
      type={type}
      size={size}
      onClick={() => {
        setSendRequest(true)
        if (!isUndefinedOrNull(onClick)) {
          onClick()
        }
      }}
      aria-label={ariaLabel || null}
    >
      {children}
    </Btn>
  )
}

Button.propTypes = {
  to: PropTypes.string,
  location: PropTypes.string,
  href: PropTypes.string,
  kind: PropTypes.string,
  size: PropTypes.string,
  as: PropTypes.oneOf(['div', 'a', 'link', 'button']),
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  children: PropTypes.node,
  onClick: PropTypes.func,
  props: PropTypes.object,
  id: PropTypes.string,
  ariaLabel: PropTypes.string,
}

Button.defaultProps = {
  type: 'button',
}

export default Button
