import React, { useState } from 'react'
import { TrustPilotContext } from '../../containers/contexts'
import { isUndefinedOrNull, getPercentage } from '../../utils'
import {
  Col,
  Row,
} from '../Grid'

import {
  Container,
  Banner,
  ListItem,
  List,
  UnorderedList,
} from './style'

const TrustPilotReview = () => {
  const [reviewStats, setReviewStats] = useState([])
  const [isLoading, setIsLoading] = useState([])

  const getStats = (id) => getPercentage(reviewStats[id], reviewStats.total)

  const buildStats = (id) => {
    const stats = {
      Excellent: getStats('fiveStars'),
      Great: getStats('fourStars'),
      Average: getStats('threeStars'),
      Poor: getStats('twoStars'),
      Bad: getStats('oneStar'),
    }

    return stats[id]
  }

  return (
    <TrustPilotContext.Consumer>
      {({ trustpilot: { numberOfReviews } }) => !isUndefinedOrNull(numberOfReviews) && (
        <Banner>
          <Container>
            <Row>
              <Col lg={{ span: '8', offset: '2' }} md="12" sm="12" xs="12">
                <UnorderedList style={{ margin: '0', width: '100%' }}>
                  {['Excellent', 'Great', 'Average', 'Poor', 'Bad'].map((i) => {
                    setIsLoading(true)
                    setReviewStats(numberOfReviews)
                    const progress = buildStats(i)
                    setIsLoading(false)
                    return !isLoading && (<List data-testid="review-stats" key={i}><ListItem>{i}</ListItem> <ListItem progress={progress}><div><div /></div></ListItem> <ListItem>{`${progress}%`}</ListItem></List>)
                  })}
                </UnorderedList>
              </Col>
            </Row>
          </Container>
        </Banner>
      )}
    </TrustPilotContext.Consumer>
  )
}

export default TrustPilotReview
