import styled from 'styled-components'
import Text from '../Text'
import { container } from '../../theme'

const HeroImgStyled = styled.div`
  width: 100%;
  background-size: cover;
  background-image: ${props => props.images?.large_desktop && `url(${props.images.large_desktop})`};
  background-position: center right;
  min-height: ${props => props.showTallVersion ? '900px' : '440px'};
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  @media only screen and (max-width: 2559px) {
    background-image: ${props => props.images?.regular_desktop && `url(${props.images.regular_desktop})`};
    min-height: ${props => props.showTallVersion ? '734px' : '440px'};
  }

  @media only screen and (max-width: 1920px) {
    background-image: ${props => props.images?.regular_desktop && `url(${props.images.regular_desktop})`};
    min-height: ${props => props.showTallVersion ? '525px' : '440px'};
  }

  @media only screen and (max-width: 767px) {
    margin-top: -180px;
    background-image: ${props => props.images?.mobile && `url(${props.images.mobile})`};
    background-position: bottom;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    margin: 0px;
    top: -60px;
  }
`

const HeroOverlayStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  justify-content: flex-end;
  width: 100%;
  display: flex;
  position: relative;
  min-height: ${props => props.showTallVersion ? '900px' : '440px'};

  @media only screen and (max-width: 2559px) {
    min-height: ${props => props.showTallVersion ? '734px' : '440px'};
  }

  @media only screen and (max-width: 1920px) {
    min-height: ${props => props.showTallVersion ? '525px' : '440px'};
  }

  &:before {
    content: "";
    position: absolute;
    background-image: ${props => props.images?.desktop_overlay && `url(${props.images.desktop_overlay})`};
    width: ${props => props.width || 55}%;
    height: 100%;
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    left: 0;
    top: 0;
  }

  @media only screen and (max-width: 767px) {
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 0;
    height: auto;
    min-height: 389px;

    &:before {
      background-image: ${props => props.images?.mobile_overlay && `url(${props.images.mobile_overlay})`};
      background-position: bottom;
      z-index: -1;
      height: 100%;
      width: 100%;
      /* top: -100px; */
    }
  }
`
const HeroSectionStyled = styled.section`
  position: relative;

  @media only screen and (max-width: 2559px) {
    min-height: ${props => props.showTallVersion ? '734px' : '440px'};
  }

  @media only screen and (max-width: 1920px) {
    min-height: ${props => props.showTallVersion ? '525px' : '440px'};
  }

  @media only screen and (max-width: 767px) {
    overflow: hidden;
    height: calc(100vw + 140px);
  }

  @media only screen and (max-width: 530px) {
    overflow: hidden;
    height: calc(100vw + 160px);
  }

  @media only screen and (max-width: 415px) {
    height: calc(100vw + 200px);
  }
`

const HeroHeading = styled.h1`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 2.75rem;
  line-height: 48px;
  letter-spacing: -0.02em;
  color: #FFF;
  margin-top: 0;
  max-width: ${props => props.width || 50}%;
  margin-bottom: 0;

  span {
    display: block;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 1.8125rem;
    line-height: 28px;
    margin-top: 0;
    margin-bottom: 0;
  }

  @media only screen and (max-width: 767px) {
    margin: 0;
    font-weight: 800;
    font-size: 2rem;
    line-height: 40px;
    max-width: 100%;
  }

  @media only screen and (max-width: 413px) {
    margin: 0;
    font-weight: 800;
    font-size: 1.5625rem;
    line-height: 1.5;
    text-align: center;
  }
`

const HeroList = styled.ul`
  margin-top: 14px;
  padding-left: 0;
  margin-bottom: 34px;

  @media only screen and (max-width: 1024px) {
    margin-bottom: 26px;
  }

  @media only screen and (max-width: 767px) {
    display: inline-block;
    margin-top: 24px;
    margin-bottom: 40px;
  }

  @media only screen and (max-width: 413px) {
    margin: 20px auto 31px auto;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 1.1875rem;
    line-height: 27px;
    color: #FFF;
    list-style: none;
    margin-bottom: 14px;

    &:last-child {
      margin-bottom: 0;
    }

    img {
      margin-right: 10px;
      width: 16px;
      height: 16px;

      @media only screen and (max-width: 767px) {
        width: 18px;
        height: 18px;
        align-self: flex-start;
        margin-top: 3px;
      }
    }

    @media only screen and (max-width: 1199px) {
      &:last-child {
        max-width: 360px;
      }
    }

    @media only screen and (max-width: 1024px) {
      font-weight: 400;
      font-size: 1rem;
      line-height: 27px;
      margin-bottom: 10px;
      &:last-child {
        max-width: 100%;
      }
    }

    @media only screen and (max-width: 991px) {
      &:last-child {
        max-width: 320px;
      }
    }

    @media only screen and (max-width: 767px) {
      justify-content: flex-start;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5;
      margin-bottom: 11px;
      text-align: left;
      &:last-child {
        max-width: 100%;
      }
    }

    @media only screen and (max-width: 413px) {
      justify-content: flex-start;
      text-align: left;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

const HeroOverlayContentStyled = styled.div`
  ${container}
  position: relative;
  max-width: 570px;
  margin: 0 auto;
  max-width: 1140px;
  width: 100%;
  padding: 40px 16px;
  z-index: 1;
  align-self: center;

  a {
    margin-left: 3px;
  }

  @media only screen and (max-width: 767px) {
    text-align: center;
    margin: 0 auto;
    padding-left: 16px;
    padding-right: 16px;
  }

  @media only screen and (max-width: 415px) {
    text-align: center;
    margin: 0 auto;
  }
`

const HeroParagraphStyled = styled(Text)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 1.1875rem;
  line-height: 27px;
  color: #FFF;
  margin-bottom: 34px;
  margin-top: 24px;
  max-width: 48%;
  width: 100%;

  /* Extract to a func as it is the same as list item */
  @media only screen and (max-width: 1024px) {
    font-size: 1rem;
  }

  @media only screen and (max-width: 767px) {
    justify-content: center;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 40px;
    max-width: 100%;
  }
`

export {
  HeroImgStyled,
  HeroOverlayStyled,
  HeroSectionStyled,
  HeroHeading,
  HeroList,
  HeroOverlayContentStyled,
  HeroParagraphStyled,
}
