import styled, { css } from 'styled-components'
import Text from '../Text'
import Image from '../Image'

const ParagraphStyled = styled(Text)`
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: #373737;
  margin: 10px 0 0 0;
  @media only screen and (max-width: 767px) {
    margin-top: 0;
    text-align: left;
  }
`

const ImageStyled = styled(Image)`
  max-width: 42px;
  height: auto;
  @media only screen and (max-width: 767px) {
    max-width: 42px;
    margin-right: 16px;
    align-self: flex-start;
  }
`

const kind = (t) => {
  switch (t) {
    case 'vertical':
      return css`
          display: block;
          padding-left: 0;
          padding-right: 0;
          margin-top: 0;
          margin-bottom: 0;

          @media screen and (max-width: 767px) {
            margin-top: 23px;
            margin-bottom: 45px;
            padding-left: 0;
            padding-right: 0;
            margin-left: -3px;
            max-width: 490px;
          }

          li {
            display: flex;
            max-width: 100%;
            margin-bottom: 32px;
            margin-right: 0;

            @media screen and (max-width: 767px) {
              max-width: 490px;
              margin-bottom: 20px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            img {
              width: 37px;
              height: 37px;

              @media screen and (max-width: 767px) {
                margin-right: 0;
              }
            }

            p {
              margin-top: 0;
              margin-left: 13px;
              text-align: left;
              color: #373737;

              @media screen and (max-width: 767px) {
                margin-left: 11px;
                font-style: normal;
                line-height: 25px;
              }
            }
          }
        `
    case 'default':
    default:
      return css`
        display: flex;
      `
  }
}

const UlStyled = styled.ul`
  justify-content: center;
  max-width: 1120px;
  margin: 40px auto;
  padding-right: 40px;
  padding-left: 40px;

  @media screen and (min-width: 992px) and (max-width: 1199px) {
    max-width: 960px;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    max-width: 720px;
  }

  @media only screen and (max-width: 767px) {
    display: block;
    max-width: 420px;
    padding-left: 16px;
    padding-right: 16px;
  }

  li {
    list-style: none;
    text-align: center;
    max-width: 284px;
    margin-right: 97px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (min-width: 768px) and (max-width: 991px) {
      margin-right: 57px;
    }

    @media only screen and (max-width: 767px) {
      max-width: 100%;
      display: flex;
      text-align: left;
      margin-bottom: 26px;
      margin-right: 0;
    }
  }

  ${props => kind(props.kind)}
`

export {
  UlStyled,
  ParagraphStyled,
  ImageStyled,
}
