import { CALL_API } from '../middleware/callAPIMiddleware'
// import API_MAP from '../services/endpoints'

const GET_CALLBACK_DATA_REQUEST = 'GET_CALLBACK_DATA_REQUEST'
const GET_CALLBACK_DATA_SUCCESS = 'GET_CALLBACK_DATA_SUCCESS'
const GET_CALLBACK_DATA_ERROR = 'GET_CALLBACK_DATA_ERROR'

// const { getCallback } = API_MAP

export const getCallbackData = () => ({
  [CALL_API]: {
    url: 'http://localhost:5002/wp-json/bsapi/callback/days',
    method: 'GET',
    types: [GET_CALLBACK_DATA_REQUEST, GET_CALLBACK_DATA_SUCCESS, GET_CALLBACK_DATA_ERROR],
  },
  type: GET_CALLBACK_DATA_REQUEST,
})

export {
  GET_CALLBACK_DATA_REQUEST,
  GET_CALLBACK_DATA_SUCCESS,
  GET_CALLBACK_DATA_ERROR,
}
