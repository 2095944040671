import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { matchPath, withRouter } from 'react-router-dom'

import {
  isUndefinedOrNull,
  dataLayerPush,
} from '../../utils'

import {
  LearnSidebar,
  LearnHide,
  Tree,
  NavSection,
} from './style'

import BackLink from '../BackLink'
import {
  DataLayerLink,
} from '../DataLayerLink'

class LearnArticleSidebar extends Component {
  state = {
    showSearchResults: false,
  }

  learnArticleSidebarLinks = React.createRef()

  componentDidMount() {
    this.checkActive()
  }

  componentDidUpdate() {
    this.checkActive()
  }

  setLearnArticleSidebarLinks = element => {
    this.learnArticleSidebarLinks = element
  }

  handleSearch = (e) => {
    e.preventDefault()
    this.getSearchResults()
    this.setState({
      showSearchResults: true,
    })
  }

  hideSearch = () => {
    this.setState({
      showSearchResults: false,
    })
    document.getElementById('sidebar_search').value = ''
  }

  // getSearchResults = (e) => {
  //   // const { loadPosts, data: { data } } = this.props

  //   // const { name, value } = e.target

  //   // new search functionality for day 2
  //   // const search = data.find(i => {

  //   // })

  //   // this.setState({
  //   //   showSearchResults: true,
  //   // })

  //   return null
  // }

  createTree = () => {
    const { data: { data } } = this.props
    if (!isUndefinedOrNull(data)) {
      return Object.keys(data).map(i => {
        if (!isUndefinedOrNull(data[i])) {
          return (
            <NavSection key={data[i].name} className="top_level">
              <button className="topLevelBtn" type="button" onClick={(e) => this.toggler(e)}>{data[i].name}</button>
              <ul className="top">
                {this.createSubCatList(data[i].children)}
              </ul>
            </NavSection>
          )
        }
        return null
      })
    }
    return null
  }

  createSubCatList = (items) => {
    if (!isUndefinedOrNull(items)) {
      return (
        Object.keys(items).map(i => (
          <li key={items[i].name} className="cat">
            <button className="subCatBtn" type="button" onClick={(e) => this.toggler(e)}>{items[i].name}</button>
            <ul className="posts">
              {this.createPostList(items[i].posts)}
            </ul>
          </li>
        ))
      )
    }
    return null
  }

  createPostList = (items) => Object.keys(items).map(i => (
    <li key={items[i].id}>
      <DataLayerLink
        ref={items[i].url}
        eventName="textClick"
        eventKey="textName"
        href={items[i].url}
        className="cat_link"
      >
        {items[i].name}
      </DataLayerLink>
    </li>
  ))

  toggler = (e) => {
    const { currentTarget: { parentNode, innerText } } = e
    parentNode.classList.toggle('active')
    dataLayerPush({
      event: 'ButtonClick',
      buttonName: `${innerText}`.trim(),
    })
  }

  checkActive = () => {
    const { location } = this.props

    this.learnArticleSidebarLinks.querySelectorAll('.cat_link').forEach(item => {
      const href = item.getAttribute('href')
      const submenu = item.parentNode.parentNode.parentNode
      const top = submenu.parentNode.parentNode
      if (matchPath(location.pathname, { path: href })) {
        submenu.classList.add('active')
        top.classList.add('active')
        item.classList.add('active')
      }
      return null
    })
  }

  render() {
    const { showSearchResults } = this.state
    // const { posts } = this.props

    return (
      <LearnSidebar>
        <BackLink url="/learn" text="FAQs" title="Back to the Learn section" />
        <LearnHide>
          {/* <Search aria-hidden="true">
            <form>
              <Icon kind="search" size="17" color="#28334A" className="search_icon" />
              <input
                type="text"
                name="s"
                onBlur={(e) => this.getSearchResults(e)} id="sidebar_search" placeholder="Search"
              />
              {showSearchResults && (
                <button type="button" onClick={() => this.hideSearch()} className="close">
                  <Icon kind="close" size="24" color="#28334A" />
                </button>
              )}
            </form>
            {showSearchResults && (
              <SearchResults>
                {!isEmptyObj(posts.searchResults) && (
                  <ul>
                    {Object.keys(posts.searchResults).map(i => (
                      <li key={posts.searchResults[i].ID}>
                        {posts.searchResults[i].error && (
                          <strong>No results found</strong>
                        )}
                        {!posts.searchResults[i].error && (
                          <a href={posts.searchResults[i].url}>{posts.searchResults[i].title}</a>
                        )}
                      </li>
                    ))}
                  </ul>
                )}
              </SearchResults>
            )}
          </Search> */}
          {!showSearchResults && (
            <Tree aria-hidden="true" ref={this.setLearnArticleSidebarLinks}>
              {this.createTree()}
            </Tree>
          )}
        </LearnHide>
      </LearnSidebar>
    )
  }
}

LearnArticleSidebar.propTypes = {
  data: PropTypes.object,
  location: PropTypes.any,
  // posts: PropTypes.object,
  // loadPosts: PropTypes.func,
}

const mapStateToProps = state => ({
  posts: state.posts,
})

// const mapDispatchToProps = (dispatch) => ({
//   // loadPosts: (key, obj) => dispatch(getPosts(key, obj)),
// })

export default withRouter(connect(mapStateToProps, null)(LearnArticleSidebar))
