export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  background: {
    mappedProp: 'background',
  },
  show_top_heading: {
    mappedProp: 'showTopHeading',
  },
  top_heading: {
    mappedProp: 'topHeading',
  },
  top_heading_url: {
    mappedProp: 'topHeadingUrl',
  },
  heading: {
    mappedProp: 'heading',
  },
  top_text: {
    mappedProp: 'topText',
  },
  icon_content: {
    mappedProp: 'iconContent',
  },
  icon_position: {
    mappedProp: 'iconPosition',
  },
  show_margin_bottom: {
    mappedProp: 'showMarginBottom',
  }
}
