import styled from 'styled-components'

import { container, query } from '../../theme'

const LearnHeroContainer = styled.div`
  background-color: #F3F5F8;
  width: 100%;
  .image_container {
    background-position: bottom right;
    background-repeat: no-repeat;
    background-size: 92%;
    ${query.combine(['extraSmall', 'small'], `
      display:none;
    `)}
  }
  .content_container {
    padding-bottom: 2rem;
  }
`

const LearnHeroWrapper = styled.div`

  ${container}

  img {
    width: 100%;
  }
`

const Questions = styled.div`
  h2 {
    color: #28334A;
    font-weight: bold;
    font-size: 1em;
    margin: 0 0 .5rem 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    > li {
      border-top: #DBDBDB 1px solid;
      padding: .75rem 0;
      &:last-child {
        border-bottom: #DBDBDB 1px solid;
      }
      a {
        font-size: 1em;
        color: #373737;
        text-decoration: none;
        span {
          width: 100%;
        }
        &:hover {
          color: #000;
        }
        &:after {
          background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M294.1 256L167 129c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.3 34 0L345 239c9.1 9.1 9.3 23.7.7 33.1L201.1 417c-4.7 4.7-10.9 7-17 7s-12.3-2.3-17-7c-9.4-9.4-9.4-24.6 0-33.9l127-127.1z"/></svg>');
          background-repeat: no-repeat;
          width: 15px;
          height: 15px;
          content: '';
          color: #373737;
          position: relative;
          float: right;
          top: 4px;
          margin: 0 0 .5rem 0;
        }
      }
    }
  }
`

export {
  LearnHeroContainer,
  LearnHeroWrapper,
  Questions,
}
