import React from 'react'
import PropTypes from 'prop-types'
import { reviewCreatedAt, truncateStr, isEmptyObj } from '../../utils'
import { ReviewListStyle } from './styles'
import Stars from '../TrustPilotReviewStars'

const ReviewItem = ({
  review,
}) => {
  if (isEmptyObj(review)) return null

  const {
    title, text, id, consumer: { displayName }, createdAt, stars,
  } = review

  return (
    <ReviewListStyle>
      <h3 data-testid="review-title" key={`${review}_title`}><a href={`https://uk.trustpilot.com/reviews/${id}`} target="_blank" rel="noopener noreferrer">{title}</a></h3>
      <p data-testid="review-body" key={`${review}_text`}>{truncateStr(text, 140)}</p>

      <Stars data-testid="review-star-rating" width="120px" starsRating={stars} key={`${review}_stars`} />

      <p data-testid="review-author" className="author" key={`${review}_tpReviews`}><b>{displayName.toLowerCase()}</b></p>
      <p data-testid="review-author-from" className="author">Beagle Street customer</p>
      <p data-testid="review-date" className="reviewDate">{reviewCreatedAt(createdAt)}</p>
    </ReviewListStyle>
  )
}

ReviewItem.propTypes = {
  review: PropTypes.object,
}

export default ReviewItem
