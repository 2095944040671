import React from 'react'
import PropTypes from 'prop-types'

import { Fieldset, RadioItem } from './style'

const Radio = ({
  identifier,
  buttons,
  title,
}) => (
  <div>
    <Fieldset>
      <legend>{title}</legend>
      {buttons.map(button => (
        <RadioItem key={button.label}>
          <label htmlFor={button.label.replace(' ', '_')}>
            <input
              type="radio"
              name={identifier}
              id={button.label.replace(' ', '_')}
              value={button.value}
            />
            <span />
            {button.label}
          </label>
        </RadioItem>
      ))}
    </Fieldset>
  </div>
)

Radio.propTypes = {
  identifier: PropTypes.string,
  title: PropTypes.string,
  buttons: PropTypes.array,
}

Radio.defaultProps = {
  buttons: [],
}

export default Radio
