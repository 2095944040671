import React from 'react'
import PropTypes from 'prop-types'
// import { isUndefinedOrNull, isEmptyObj } from '../../utils'

import { TrustPilotReviewStars } from '../TrustPilotReviewStars'

import {
  BrandImage,
  TrustPilotStarsWrapper,
  TrustPilotWrapper,
} from './style'

const Trustpilot = ({ tpStarsRating = '', reviewStarsWidth, trustScore }) => (
  <TrustPilotWrapper data-testid="trustpilot" className="trustPilotStars">
    <TrustPilotStarsWrapper>
      <a href="https://uk.trustpilot.com/review/beaglestreet.com" target="_blank" rel="noopener noreferrer">
        <TrustPilotReviewStars data-testid="tp-stars-rating" starsRating={tpStarsRating} trustScore={trustScore} width={reviewStarsWidth} />
        <BrandImage data-testid="tp-brand-image" style={{ width: '80px', margin: '0 auto' }} src="https://cdn.bgllife.co.uk/beaglestreet/trustpilot/trustpilot-brand-image.svg" alt="Trust Pilot" />
      </a>
    </TrustPilotStarsWrapper>
  </TrustPilotWrapper>
)

Trustpilot.propTypes = {
  tpStarsRating: PropTypes.number,
  reviewStarsWidth: PropTypes.string,
}

export default Trustpilot
