export default {
  acf_fc_layout: {
    mappedProp: '',
  },
  images: {
    mappedProp: 'images',
  },
  show_affiliate_image: {
    mappedProp: 'showAffiliateImage',
  },
  affiliate_image: {
    mappedProp: 'affiliateImage',
  },
  affiliate_image_alt_text: {
    mappedProp: 'affiliateImageAltText',
  },
  heading_first_line: {
    mappedProp: 'headingFirstLine',
  },
  heading_second_line: {
    mappedProp: 'headingSecondLine',
  },
  css_styles: {
    mappedProp: 'cssStyles',
  },
  show_list_items: {
    mappedProp: 'showListItems',
  },
  list_items: {
    mappedProp: 'listItems',
  },
  body: {
    mappedProp: 'body',
  },
  show_button: {
    mappedProp: 'showButton',
  },
  button_text: {
    mappedProp: 'buttonText'
  },
  show_tall_version: {
    mappedProp: 'showTallVersion'
  }
}
