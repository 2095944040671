import React, { useState, useEffect } from 'react'
import { isEmptyString } from '../../utils'
import PropTypes from 'prop-types'
import { TrustPilotContext } from '../../containers/contexts'
import { Button } from '../Button'
import { Trustpilot } from '../Trustpilot'
import {
  HeroWrapper,
  ImageWrapper,
  HeroHeading,
  ListWrapper,
  ListItem,
  AffiliateWrapperMobile,
  AffiliateWrapperDesktop,
  ArrowWrapper,
  MobileImageWrapper,
} from './style'
import { cleanHtml, removeHtmlTagFromString } from '../../utils'

const FullWidthHeroImage = ({
  heading,
  list,
  background,
  buttonText,
  buttonLink,
  showTrustPilot,
  showAffiliate,
  affiliateText,
  affiliateImage,
  scrollText,
  scrollIcon,
  leftImage,
  rightImage,
  mobileImage,
}) => {
  const [headerHeight, setHeaderHeight] = useState(0)
  const [scrollHeight, setScrollHeight] = useState(0)

  useEffect(() => {
    // grab header and it's height.
    const header = document.getElementById('header-main')
    if (header) setHeaderHeight(header.getBoundingClientRect().height)

    // get this component height and - 80 for scroll height distance.
    const hero = document.getElementById('hero3')
    if (hero) setScrollHeight(hero.getBoundingClientRect().height - 80)
  }, [])

  const handleScroll = () => {
    if (typeof window !== 'undefined') window.scroll({
      top: scrollHeight,
      left: 0,
      behavior: 'smooth'
    })
  }

  const { alt: leftAlt, url: leftUrl } = leftImage
  const { alt: rightAlt, url: rightUrl } = rightImage
  const { alt: mobileAlt, url: mobileUrl } = mobileImage
  const { alt: affiliateAlt, url: affiliateUrl } = affiliateImage

  return (
    <HeroWrapper data-testid="hero3" id="hero3" background={background} headerHeight={headerHeight}>
      <ImageWrapper id="heroImages">
        <img src={leftUrl} alt={!isEmptyString(leftAlt) ? leftAlt : 'left hero'} />
        <img src={rightUrl} alt={!isEmptyString(rightAlt) ? rightAlt : 'right hero'} />
      </ImageWrapper>
      <HeroHeading dangerouslySetInnerHTML={{ __html: cleanHtml(removeHtmlTagFromString(heading, 'p')) }} />
      <ListWrapper>
        {list.map((i, index) => {
          return (
            <ListItem key={`list_${index}`}>
              <img src={i.icon} alt="tick icon" />
              <p>{i.text}</p>
            </ListItem>
          )
        })}
      </ListWrapper>
      {showAffiliate && (
        <AffiliateWrapperMobile id="heroAffiliateMobile">
          <p dangerouslySetInnerHTML={{ __html: cleanHtml(removeHtmlTagFromString(affiliateText, 'p')) }} />
          <img src={affiliateUrl} alt={!isEmptyString(affiliateAlt) ? affiliateAlt : 'affiliate gift card'} />
        </AffiliateWrapperMobile>
      )}
      <div id="heroLink">
        <Button
          type="button"
          kind="purple_solid"
          size="large"
          as="a"
          href={buttonLink}
        >
          {buttonText}
        </Button>
        {showTrustPilot && (
          <TrustPilotContext.Consumer>
            {({ trustpilot }) => {
              const { score } = trustpilot
              if (score) {
                return <Trustpilot id="heroTP" tpStarsRating={score.stars} reviewStarsWidth="120px" />
              }
              return null
            }}
          </TrustPilotContext.Consumer>
        )}
      </div>
      {showAffiliate && (
        <AffiliateWrapperDesktop id="heroAffiliateDesktop">
          <p dangerouslySetInnerHTML={{ __html: cleanHtml(removeHtmlTagFromString(affiliateText, 'p')) }} />
          <img src={affiliateUrl} alt={!isEmptyString(affiliateAlt) ? affiliateAlt : 'affiliate gift card'} />
        </AffiliateWrapperDesktop>
      )}
      <ArrowWrapper id="heroScroll">
        <p dangerouslySetInnerHTML={{ __html: cleanHtml(removeHtmlTagFromString(scrollText, 'p')) }} />
        <button onClick={() => handleScroll()}>
          <img src={scrollIcon} alt="scroll" />
        </button>
      </ArrowWrapper>
      <MobileImageWrapper>
        <img src={mobileUrl} alt={!isEmptyString(mobileAlt) ? mobileAlt : 'hero mobile'} />
      </MobileImageWrapper>
    </HeroWrapper>
  )
}

FullWidthHeroImage.propTypes = {
  heading: PropTypes.string,
  list: PropTypes.array,
  background: PropTypes.string,
  buttonText: PropTypes.string,
  buttonLink: PropTypes.string,
  showTrustPilot: PropTypes.bool,
  showAffiliate: PropTypes.bool,
  affiliateText: PropTypes.string,
  affiliateImage: PropTypes.string,
  scrollText: PropTypes.string,
  scrollIcon: PropTypes.string,
  leftImage: PropTypes.object,
  rightImage: PropTypes.object,
  mobileImage: PropTypes.object,
}

export default FullWidthHeroImage