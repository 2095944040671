import {
  applyMiddleware,
  createStore,
  combineReducers,
  compose,
} from 'redux'
import thunkMiddleware from 'redux-thunk'

import api from './reducers/api'
import learnAccordion from './reducers/learn'
import posts from './reducers/posts'
import analytics from './reducers/analytics'
import trustpilot from './reducers/trustpilot'
import callback from './reducers/callback'
import holidays from './reducers/holidays'

const reducers = combineReducers({
  api,
  learnAccordion,
  posts,
  analytics,
  trustpilot,
  callback,
  holidays,
})

/* eslint no-underscore-dangle: ["error", { "allow": ["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] }] */
const composeEnhancers = ((typeof window !== 'undefined') && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

/*
 * Store middleware
 */
const createStoreWithMiddleware = composeEnhancers(
  applyMiddleware(thunkMiddleware),
)(createStore)

export default function configureStore(initialState = {}) {
  return createStoreWithMiddleware(reducers, initialState)
}
