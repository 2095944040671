import React from 'react'
import PropTypes from 'prop-types'
import CallBenefits from './CallBenefits'
import GuidesBlock from '../GuidesBlock'
import Icon from '../Icons/Icon'

import {
  Row,
  Col,
} from '../Grid'

import {
  MessageContainer,
  MessageHeading,
  MessageBody,
  MessageFooter,
} from './style'

const data = {
  0: {
    ID: 633,
    title: 'Family life insurance',
    url: '/guides/family-life-insurance/',
    excerpt: 'The payout from Life Insurance could help to cover some or all of your financial obligations if you were to suddenly pass away.',
    type: 'guides',
    thumb: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/21151359/family.jpg',
    thumbs: {
      small_block: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/21151359/family-480x260.jpg',
      medium_block: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/21151359/family.jpg',
    },
    terms: {
      0: {
        term_id: 27,
        name: 'Life insurance for you',
        url: '/guide-categories/life-insurance-for-you/',
      },
    },
  },
  1: {
    ID: 637,
    title: 'Life insurance for new parents',
    url: '/guides/life-insurance-for-new-parents/',
    excerpt: 'Protecting your family is important, which is why many of us take out Life Insurance for the first time when we become parents.',
    type: 'guides',
    thumb: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/21151520/Life-Insurance-for-Parents.jpg',
    thumbs: {
      small_block: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/21151520/Life-Insurance-for-Parents.jpg',
      medium_block: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/21151520/Life-Insurance-for-Parents.jpg',
    },
    terms: {
      1: {
        term_id: 27,
        name: 'Life insurance for you',
        url: '/guide-categories/life-insurance-for-you/',
      },
    },
  },
  2: {
    ID: 1109,
    title: 'Mortgage guide',
    url: '/?post_type=guides&p=1109/',
    excerpt: 'At Beagle Street, we offer Life Insurance products to suit different circumstances. We help a lot of people take their first steps towards security and peace of mind during big life changes, like owning their first home.',
    type: 'guides',
    thumb: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/05134111/new-homeowners.jpg',
    thumbs: {
      small_block: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/05134111/new-homeowners-700x260.jpg',
      medium_block: 'https://cdn.bgllife.co.uk/wp-content/uploads/2019/08/05134111/new-homeowners-700x350.jpg',
    },
    terms: {
      2: {
        term_id: 27,
        name: 'Life insurance for you',
        url: '/guide-categories/life-insurance-for-you/',
      },
    },
  },
}

const CallbackMessage = ({
  error,
  ppc,
}) => {
  let successIcon = ''
  let errorIcon = ''

  if (ppc) {
    if (error) errorIcon = 'ppcCallbackError'
    else successIcon = 'ppcCallbackSuccess'
  } else if (!ppc) {
    if (error) errorIcon = 'CallbackFail'
    else successIcon = 'CallbackSuccess'
  }

  const successMessage = ppc ? 'One of our expert team will call you shortly to help understand your life insurance needs.' : 'A Beagle Street representative will be in touch at your requested time.'
  const errorHeading = ppc ? 'Something went wrong... Please try again later.' : 'There was an error trying to send your message. Please try again later.'
  const errorPhone = ppc ? '0800 0480 490' : '0800 0480 480'
  const returnLinkText = ppc ? 'Get a quote' : 'homepage'
  const returnLink = ppc ? '/get-a-quote' : '/'

  return (
    <>
      <MessageContainer>
        <Row>
          <Col md={{ span: 10, offset: 1 }} xs="12">
            {error ? (<Icon kind={errorIcon} size="150" />) : (<Icon kind={successIcon} size="150" />)}
            <MessageHeading>
              {error && errorHeading}
              {!error && ('Thank you for your request')}
            </MessageHeading>
            {error && (
              <MessageBody>
                Alternatively you can <br />
                Email us: <a href="mailto:enquiries@beaglestreet.com">enquiries@beaglestreet.com</a> <br />
                Phone: <a href={`tel:${errorPhone}`}>{errorPhone}</a>
              </MessageBody>
            )}
            {!error && (
              <MessageBody>
                {successMessage}
              </MessageBody>
            )}
            <MessageFooter>
              {!error && !ppc && <>Return back to <a href={returnLink}>{returnLinkText}</a></>}
              {error && ppc && <>Return back to <a href={returnLink}>{returnLinkText}</a></>}
            </MessageFooter>
          </Col>
        </Row>
      </MessageContainer>
      {!ppc && <GuidesBlock data={data} />}
      {ppc && !error && (
        <CallBenefits />
      )}
    </>
  )
}

CallbackMessage.propTypes = {
  error: PropTypes.bool,
  ppc: PropTypes.bool, // whether it's normal callback or ppc form.
}

export default CallbackMessage
