import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../Button'

import {
  cleanHtml,
} from '../../utils'

import {
  Container,
  Banner,
  Text,
} from './style'

const CTABanner = ({
  text,
  buttontext,
  buttonurl,
  buttonstyle,
}) => (
  <Banner>
    <Container>
      <Text
        // eslint-disable-next-line
        dangerouslySetInnerHTML={{ __html: cleanHtml(text) }}
      />
      <Button as="a" href={buttonurl} kind={buttonstyle} size="large">{buttontext}</Button>
    </Container>
  </Banner>
)

CTABanner.propTypes = {
  text: PropTypes.string,
  buttontext: PropTypes.string,
  buttonurl: PropTypes.string,
  buttonstyle: PropTypes.string,
}

export default CTABanner
